import React, { useState, useEffect, useContext } from 'react';
import { FiltersContext } from '../../contexts/FiltersContext';
import { ComponentFiltersContext } from '../../contexts/ComponentFiltersContext';
import { useStatsApi } from '../../hooks/useApi';
import { TableComponent } from '../table/TableComponent';
import config from '../../config.json';
import { camelCaseToTitleCase } from '../../utilities';
import { Filters, USER_DATA_SEARCH_ENDPOINT, IS_ACTIVE_FIELD_NAME } from '../../constants';
import UserDataTableComponentEditorSchema from './UserDataTableEditorSchema.json';
import { UserDataContext, UserDataActions } from '../../contexts/UserDataContext';
import { Table } from 'antd';

const ToggleContentComponent = ({ name, children }) => {
    const [show, setShow] = useState(false);

    const handleClickToggle = () => {
        setShow(!show);
    };

    return (
        <div>
            <button className={'text-link ' + (show ? 'mb-2' : '')} onClick={handleClickToggle}>
                {show ? 'Hide' : 'Show'} {name}
            </button>
            {show ? children : null}
        </div>
    );
};

const UserDataTableComponent = ({ endpoint, params, modifiedFilters = {} }) => {
    const [{ filterSelections }] = useContext(FiltersContext);
    const [componentFilterState, setFilterState] = useContext(ComponentFiltersContext);
    const [columns, setColumns] = useState([]);
    const [extractedData, setExtractedData] = useState(undefined);
    const [{ data, total, loading: dataLoading, query: forceCSVQueryParams, pagination }, dispatch] = useContext(
        UserDataContext
    );

    const filterState = Object.fromEntries(
        Object.entries(componentFilterState).filter(([key]) => [Filters.GENERIC_TABLE_SEARCH].includes(key))
    );

    const [columnsData, columnsLoading] = useStatsApi(params.columnsEndpoint);

    const tableParams = {
        exportFileName: 'User Data',
        exportAsCsv: true,
        showFilterDetails: false,
        searchOff: true,
        bordered: false,
        showTotal: true,
        paginationEnabled: true,
        defaultPageSize: 50,
        backendPaginated: true,
        paginationTotal: total || 0,
        scrollYWidth: 496,
        scrollXWidth: 'max-content',
        columns
    };

    const componentPagination = componentFilterState[Filters.PAGINATION];
    useEffect(() => {
        if (componentPagination) {
            const { page, page_size } = componentPagination;
            dispatch({
                type: UserDataActions.PAGINATION,
                payload: { page, pageSize: page_size }
            });
        }
    }, [componentPagination, dispatch]);

    useEffect(() => {
        if (pagination.forceResetPage) {
            setFilterState((currentState) => ({
                ...currentState,
                [Filters.PAGINATION]: {
                    page: 0,
                    page_size: pagination.pageSize
                }
            }));

            dispatch({
                type: UserDataActions.PAGINATION,
                payload: { page: 0, pageSize: pagination.pageSize }
            });
        }
    }, [pagination, dispatch]);

    const componentSorting = componentFilterState[Filters.SORTING];
    useEffect(() => {
        if (componentSorting) {
            const { field, order } = componentSorting;
            dispatch({
                type: UserDataActions.SORTING,
                payload: { field, order }
            });
        }
    }, [componentSorting, dispatch]);

    useEffect(() => {
        if (data && columnsData) {
            const columns = columnsData
                .filter(({ exclude }) => exclude !== true)
                .sort((a, b) => b.order - a.order)
                .map(({ attribute_name, display_name, searchFilterQueryParam, renderer, fixed }, index) => {
                    return {
                        title: display_name,
                        sorter: true,
                        dataIndex: attribute_name,
                        searchFilterQueryParam,
                        renderer,
                        width: 150,
                        fixed: index === 0 ? true : fixed,
                        render: (_, data) => {
                            const attributeData = data[attribute_name];
                            if (Array.isArray(attributeData)) {
                                if (attributeData.length > 0) {
                                    const [attributeDataItem] = attributeData;
                                    const onHeaderCell = () => ({
                                        className: 'bg-gray-100 rounded-none shadow-none whitespace-nowrap'
                                    });
                                    const onRow = () => ({ className: 'whitespace-nowrap' });
                                    const idRegex = /id/i;
                                    const columns = Object.keys(attributeDataItem)
                                        .filter((key) => !idRegex.test(key))
                                        .map((key) => ({
                                            key,
                                            dataIndex: key,
                                            title: camelCaseToTitleCase(key),
                                            onHeaderCell
                                        }));

                                    return (
                                        <ToggleContentComponent name={display_name}>
                                            <Table
                                                size="small"
                                                className="mb-min9"
                                                onRow={onRow}
                                                columns={columns}
                                                dataSource={attributeData}
                                                pagination={false}
                                            />
                                        </ToggleContentComponent>
                                    );
                                } else {
                                    return <>No {display_name.toLowerCase()}</>;
                                }
                            } else {
                                return attributeData;
                            }
                        }
                    };
                });
            setColumns(columns);

            setExtractedData(
                data.map(({ attributes: { standard_attributes }, bms_person_id, bms_agency_id }) => {
                    let attributes = Object.entries(standard_attributes).reduce((object, [key, attribute]) => {
                        if (key === IS_ACTIVE_FIELD_NAME) {
                            object[key] = attribute ? 'No' : 'Yes';
                        } else {
                            if (typeof attribute === 'boolean') {
                                object[key] = attribute ? 'Yes' : 'No';
                            } else if (attribute === 'true' || attribute === 'false') {
                                object[key] = attribute === 'true' ? 'Yes' : 'No';
                            } else {
                                object[key] = attribute;
                            }
                        }
                        return object;
                    }, {});

                    return { ...attributes, bms_person_id, bms_agency_id, bms_url: config.bmsUrl };
                })
            );
        }
    }, [columnsData, data]);

    return (
        <TableComponent
            endpoint={{ url: USER_DATA_SEARCH_ENDPOINT, queryParams: forceCSVQueryParams }}
            data={extractedData}
            params={tableParams}
            loading={columnsLoading || dataLoading}
            modifiedFilters={filterState}
        />
    );
};

export { UserDataTableComponent, UserDataTableComponentEditorSchema };
