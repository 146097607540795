import { linksPermissions, linkPermissionExclusions, linkOrdering, statsLinksPermissions } from './PermisionsMeta';
import { links } from './LinksMarkup';
import { statsLinks } from './LinksMarkup';

export const resolve = (permissions, locationPathname) => {
    const allowedLinkNames = [];
    for (let linkName in linksPermissions) {
        if (
            linksPermissions[linkName][0] === 'everybody' ||
            permissions.some((permission) => linksPermissions[linkName].includes(permission))
        ) {
            allowedLinkNames.push(linkName);
        }
    }
    allowedLinkNames.sort(function (a, b) {
        return linkOrdering.indexOf(a) - linkOrdering.indexOf(b);
    });

    const allowedLinks = allowedLinkNames
        .filter((linkName) => {
            return (
                !linkPermissionExclusions[linkName] ||
                !linkPermissionExclusions[linkName].find((value) => {
                    return allowedLinkNames.includes(value);
                })
            );
        })
        .map((linkName) => {
            if (links[linkName] instanceof Function) {
                return links[linkName](locationPathname);
            } else {
                return links[linkName];
            }
        });

    return allowedLinks;
};

export const resolveForStats = (permissions) => {
    const allowedLinks = [];
    for (let linkName in statsLinksPermissions) {
        if (permissions.some((permission) => statsLinksPermissions[linkName].includes(permission))) {
            allowedLinks.push(statsLinks[linkName]);
        }
    }

    const sorted = [
        allowedLinks.find((x) => x.key === 'accessStatsReports'),
        allowedLinks.find((x) => x.key === 'accessStatsDesigner'),
        allowedLinks.find((x) => x.key === 'accessStatsAdmin')
    ];
    return sorted;
};
