import { useContext, useMemo } from 'react';
import EditorContext from '../../contexts/EditorContext';
import { MAX_COL_SPAN } from '../../constants';
import { validateComponentJson } from '../../editor/utilities';

const ComponentEditorOverlay = (props) => {
    const editor = useContext(EditorContext);
    const { componentKey, component } = props;
    const { componentParams, showCodeEditorIcon, onCodeSelected, errors } = useMemo(() => {
        if (editor) {
            const componentParams = { ...props, key: componentKey };
            if (props.colSpan === MAX_COL_SPAN) {
                delete componentParams.colSpan;
            }
            delete componentParams.componentKey;
            const { showCodeEditorIcon, onCodeSelected } = editor;
            const errors = validateComponentJson(componentParams);
            return { componentParams, showCodeEditorIcon, onCodeSelected, errors };
        }
        return {};
    }, [props, componentKey, editor]);
    return (
        <>
            {!!editor && !!showCodeEditorIcon && (
                <div
                    className="z-10 absolute flex items-center space-x-1 right-0 cursor-pointer p-1"
                    onClick={() => onCodeSelected(componentParams)}>
                    <svg
                        className="h-3 w-3 text-accent-text hover:text-accent justify-center"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                        />
                    </svg>
                    <span className={errors.length ? 'text-red-500' : 'text-gray-500'} style={{ fontSize: 10 }}>
                        {component}{' '}
                        {!!errors.length && (
                            <span>
                                {' '}
                                - <strong>{errors.length}</strong> error(s) found
                            </span>
                        )}
                    </span>
                </div>
            )}
        </>
    );
};

export default ComponentEditorOverlay;
