import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../states/LoadingSpinner';
import { numberFormat, replaceTemplateStrings } from '../../utilities';
import LinkGridNavigationCardEditorSchema from './LinkGridNavigationCardEditorSchema.json';
import EmptyState from '../states/EmptyState';
import GridWrapper from '../generic/GridWrapper';
import GridSpanWrapper from '../generic/GridSpanWrapper';
import GridContext from '../../contexts/GridContext';

function LinkGridNavigationCard({ params, loading, data, pdfDelegate, colSpan,  }) {
    const {
        links = [
        ],
        linkSpan = 6,
        linkHeight = 24,
        linkHasBorder = false,
        alignItems = 'center' // "center", "start", or "end"
    } = params;
    const [rowSpan] = useContext(GridContext);
    const MAX_COL_SPAN = 12
    return (
        <GridWrapper isSubcomponent={false}>
            {links.map(({title, url}) => {
                return <div
                className={`relative ${
                    linkSpan === MAX_COL_SPAN ? 'col-span-full' : `md:col-span-${linkSpan} sm:col-span-1`
                } ${rowSpan ? `row-span-${rowSpan}` : ''}`}><div className="p-2">
                    <LinkWrapper
                        to={url}
                        className={`${linkHasBorder ? `border border-main-accent ` : ``} rounded-xs-sm h-${linkHeight} flex flex-col justify-center items-${alignItems}`}>
                        <h2 className={'text-xs text-link text-center'}>
                            {title}
                        </h2>
                    </LinkWrapper>
                </div></div>
            })}
        </GridWrapper>
    );
};

const LinkWrapper = ({ disableHyperlink, children, ...rest }) =>
    disableHyperlink ? <div {...rest}>{children}</div> : <Link {...rest}>{children}</Link>;

export { LinkGridNavigationCard, LinkGridNavigationCardEditorSchema };
