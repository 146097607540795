import { Select, Input } from 'antd';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { useContext, useEffect, useState } from 'react';
import { Filters } from '../../../constants';
import { constructOfficerDetailString } from '../../../utilities';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
const { Option } = Select;
var _ = require('lodash');

const OfficerAutoCompleteFilter = ({ title }) => {
    const [queryPerson, setQueryPerson] = useQueryParam('person', NumberParam);
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);
    const [officerOptions, setOfficerOptions] = useState([]);
    const [selectedKey, setSelectedKey] = useState(null);
    const [showAll, setShowAll] = useState(true);
    const [isHovering, setIsHovering] = useState(false);
    const { person_id } = filterSelections[Filters.OFFICER] || {};
    const officerOptionsRaw = filterOptions[Filters.OFFICER];

    useEffect(() => {
        const newOptions = officerOptionsRaw.map((option, index) => ({
            label: constructOfficerDetailString(option),
            value: option.person_id,
            key: option.person_id
        }));
        setOfficerOptions([]);
        setOfficerOptions(newOptions);
    }, [officerOptionsRaw]);

    useEffect(() => {
        setSelectedKey(person_id);
    }, [person_id, officerOptions]);

    const onOfficerChange = (value, option) => {
        if (option && Object.keys(option).length) {
            const match = _.find(officerOptionsRaw, (raw) => {
                return raw.person_id == option.value;
            });
            if (match) {
                setQueryPerson(match.person_id);
            }
        }
    };

    const filterFunction = (inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">{!title ? 'Officer' : title}</h3>
            <Select
                id="officer"
                showSearch
                placeholder="Select an officer"
                aria-label="Select an officer"
                style={{ width: 225 }}
                onSelect={onOfficerChange}
                dropdownMatchSelectWidth={500}
                value={selectedKey}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {officerOptions.map((option, index) => {
                    return (
                        <Option aria-label={option.label} key={option.key} value={option.value}>
                            {option.label}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

const DropdownSvg = ({ isHovering }) =>
    !isHovering ? (
        <svg
            className="h-2.5 w-2.5 text-inactive-tab"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
    ) : (
        ''
    );

export default OfficerAutoCompleteFilter;
