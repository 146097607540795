import { AutoComplete, Input } from 'antd';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { useContext, useEffect, useState } from 'react';
import { Filters } from '../../../constants';
import { constructOrganizationDetailString } from '../../../utilities';
import { useStatsApi } from '../../../hooks/useApi';

const AssignmentAutoCompleteFilter = ({ title }) => {
    const [{ filterSelections }, dispatch] = useContext(FiltersContext);
    const [value, setValue] = useState('All');
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [showAll, setShowAll] = useState(true);
    const [isHovering, setIsHovering] = useState(false);
    const [data] = useStatsApi('/report/mv_stop/officer_assigned_organizations', {
        [Filters.TIME_PERIOD]: filterSelections[Filters.TIME_PERIOD],
        [Filters.EMPLOYEE]: filterSelections[Filters.EMPLOYEE]
    });

    const dispatchToReducer = (type, filterId, options) =>
        dispatch({
            type,
            payload: { filterId, value: options }
        });

    useEffect(() => {
        if (data) {
            const assignmentFiltersSorted = data.sort((a, b) =>
                a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
            );
            const preselectedOrganization = assignmentFiltersSorted.length
                ? {
                      assignment_id: data.map((assignment) => assignment.id).join(),
                      name: 'All'
                  }
                : {};
            const newOptions = assignmentFiltersSorted.map(({ id, name, code }) => ({
                value: constructOrganizationDetailString({ agency_organization_code: code, name }),
                assignment_id: id
            }));
            setOrganizationOptions([
                { ...preselectedOrganization, value: preselectedOrganization.name },
                ...newOptions
            ]);
            dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.ASSIGNMENT, {
                selections: preselectedOrganization,
                options: assignmentFiltersSorted
            });
            setValue(preselectedOrganization.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onOrganizationChange = (name, organizationObject) => {
        setShowAll(true);
        if (name && Object.keys(organizationObject).length) {
            dispatch({
                type: FiltersActions.SET_FILTER_STATE,
                payload: {
                    filterId: Filters.ASSIGNMENT,
                    value: {
                        assignment_id: organizationObject.assignment_id,
                        name
                    }
                }
            });
            setValue(name);
        } else {
            setValue('');
        }
    };

    const filterFunction = (inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">{!title ? 'Assignment' : title}</h3>
            <AutoComplete
                defaultValue={value}
                allowClear={true}
                disabled={organizationOptions.length <= 2}
                onChange={onOrganizationChange}
                style={{ width: 225 }}
                onClick={() => setShowAll(true)}
                onSelect={onOrganizationChange}
                onSearch={() => setShowAll(false)}
                onMouseOver={() => setIsHovering(true)}
                onMouseOut={() => setIsHovering(false)}
                dropdownMatchSelectWidth={500}
                options={organizationOptions}
                filterOption={!showAll && filterFunction}>
                <Input suffix={<DropdownSvg isHovering={isHovering} />} placeholder="Type organization or unit" />
            </AutoComplete>
        </div>
    );
};

const DropdownSvg = ({ isHovering }) =>
    !isHovering ? (
        <svg
            className="h-2.5 w-2.5 text-inactive-tab"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
    ) : (
        ''
    );

export default AssignmentAutoCompleteFilter;
