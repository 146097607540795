import classes from './CSSClasses.module.css';
import StyledLink from '../components/links/StyledLink';
import IconLookup from '../utilities/icon-lookup';
import { COLOR_FUNCTIONS } from '../constants/index';
import { Visualizations } from '../utilities/visualization-lookup';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { replaceTemplateStrings } from '../utilities/index';
import { Tooltip } from 'antd';
import CourseOfActionButtonComponent from '../components/generic/CourseOfActionButtonComponent';

export const getRenderer = (text, row, renderer) => {
    const { type, payload, gridCols, colorFunction, colorInputField, tooltipField, tooltipPlacement } = renderer;

    let components = [];
    let innerComps = [];
    const colorScheme = (
        COLOR_FUNCTIONS[colorFunction] ||
        (() => {
            return undefined;
        })
    )(row[colorInputField]);
    switch (type) {
        case 'dynamic':
            if (payload) {
                payload.reverse();
            }
            innerComps = [];
            (payload || []).forEach((dynCmp, index) => {
                const { staticParams, dynamicParams, component, colSpan } = dynCmp;
                let computedParams = {
                    ...staticParams
                };
                Object.keys(dynamicParams).map((key) => {
                    if (typeof dynamicParams[key] === 'object') {
                        computedParams[key] = row;
                    } else {
                        computedParams[key] = row[dynamicParams[key]];
                    }
                });
                const Component = Visualizations[component];
                innerComps.push(
                    <div className={'col-span-' + (colSpan || 1)} key={`dynamic-${index}`}>
                        <Component params={computedParams} data={row} colSpan={colSpan || 1} />
                    </div>
                );
            });
            components = components.concat(innerComps);
            break;
        case 'external_link':
            (payload || []).forEach((link, index) => {
                const { url, text } = link;
                const textComponent = text ? (
                    <span key={`external-link-${index}`}>{replaceTemplateStrings(text, row)}</span>
                ) : null;
                const replaced = replaceTemplateStrings(url, row, false, {}, true);
                const href = encodeURI(replaced);
                if (replaced) {
                    components.push(
                        <a
                            key={`external-link-${index}`}
                            className="text-link mx-1 flex flex-row items-center space-x-1"
                            target="_blank"
                            rel="noreferrer"
                            href={href}>
                            {textComponent}
                        </a>
                    );
                } else {
                    components.push(textComponent);
                }
            });
            break;
        case 'external_link_easy':
            (payload || []).forEach((link, index) => {
                const { textField, urlField } = link;
                const textComponent = (
                    <span key={`external-link-easy-${index}`}>
                        {replaceTemplateStrings('${' + textField + '}', row)}
                    </span>
                );
                const encodedURI = encodeURI(replaceTemplateStrings('${' + urlField + '}', row));
                if (encodedURI) {
                    components.push(
                        <a
                            key={`external-link-easy-${index}`}
                            className="text-link flex flex-row items-center space-x-1"
                            target="_blank"
                            rel="noreferrer"
                            href={encodedURI}>
                            {textComponent}
                        </a>
                    );
                } else {
                    components.push(textComponent);
                }
            });
            break;
        case 'course_of_action_link':
            const { person_aggregate_risk_id } = row;
            // todo: add a check for the person_aggregate_risk_id and how to handle if it's not there.
            components.push(
                <CourseOfActionButtonComponent
                    key={person_aggregate_risk_id}
                    personAggregateRiskId={person_aggregate_risk_id}
                />
            );
            break;
        case 'link':
            (payload || []).forEach((link, index) => {
                const { url, text, icon } = link;
                const IconComponent = icon ? IconLookup[icon] : null;
                const replacedText = replaceTemplateStrings(text, row);
                const textComponent = text ? <span>{replacedText}</span> : null;
                const dest = encodeURI(replaceTemplateStrings(url, row, false));
                components.push(
                    dest && dest.length > 0 ? (
                        <StyledLink
                            underline={false}
                            key={`link-${index}`}
                            className="flex flex-row items-center space-x-1"
                            to={dest}>
                            {!!IconComponent && <IconComponent style={{ fontSize: 14 }} />}
                            {textComponent}
                        </StyledLink>
                    ) : (
                        replacedText
                    )
                );
            });
            break;
        case 'text_copy':
            let txt2copy = '';
            payload.forEach((load, index) => {
                const { text } = load;
                let textComponent = null;
                if (text) {
                    const str = replaceTemplateStrings(text, row);
                    txt2copy += str;
                    textComponent = <span key={`text-copy-${index}`}>{str}</span>;
                }
                components.push(textComponent);
            });
            components.push(
                <CopyToClipboard className={classes.CopyIcon} key="clipboardCopy" text={txt2copy}>
                    <CopyOutlined />
                </CopyToClipboard>
            );
            break;
        case 'text':
            (payload || []).forEach((load, index) => {
                const { text } = load;
                const textComponent = text ? (
                    <span key={`text-${index}`}>{replaceTemplateStrings(text, row)}</span>
                ) : null;
                components.push(textComponent);
            });
            break;
        case 'longText':
            const { max = 16 } = payload;
            const txt = '' + text;
            if (txt.length > max) {
                components.push(<span title={txt}>{txt.substr(0, max)}...</span>);
            } else {
                components.push(<span>{txt}</span>);
            }
            break;
        default:
            console.error('Render type does not exist for column: ', type);
    }

    const props = {
        style: {
            borderLeft: colorScheme && colorScheme['primary'] && 'solid thick ' + colorScheme['primary']
        }
    };

    let children = components;

    if (gridCols) {
        children = <div className={'grid gap-1 items-center auto-cols-fr grid-cols-' + gridCols}>{children}</div>;
    } else {
        children = <div className="flex flex-1 flex-row items-center">{children}</div>;
    }

    if (tooltipField && row[tooltipField]) {
        children = (
            <Tooltip title={row[tooltipField]} placement={tooltipPlacement || 'topLeft'}>
                {children}
            </Tooltip>
        );
    }

    return {
        props,
        children
    };
};
