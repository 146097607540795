import { useEffect, useContext, useState, useMemo } from 'react';
import { FiltersActions, FiltersContext } from '../contexts/FiltersContext';
import pdfStyling from '../utilities/pdf-styling.json';

const usePdfDelegate = (active, onSubtreeComplete, onSubtreeError) => {
    const [{ pdfDownload }, dispatch] = useContext(FiltersContext);
    const [pdfComplete, setPdfComplete] = useState(false);
    const [pdfError, setPdfError] = useState(false);
    const [pdfFilterText, setPdfFilterText] = useState(false);
    const [pdfSubtreeComplete, setPdfSubtreeComplete] = useState(false);
    const [pdfSubtreeError, setPdfSubtreeError] = useState(false);
    const [overrideTitlePageElement, setOverrideTitlePageElement] = useState(false);
    const [pdfTitle, setPdfTitle] = useState(false);
    const [internalCallbackFired, setInternalCallbackFired] = useState(false);

    const getTitlePageOverride = (key) => {
        return pdfDownload.titlePageOverrides[key];
    };

    useEffect(() => {
        if (!active) {
            setPdfFilterText(null);
            setPdfTitle(null);
            setPdfSubtreeComplete(false);
            setPdfSubtreeError(null);
            setInternalCallbackFired(false);
        }
    }, [active]);

    useEffect(() => {
        if (overrideTitlePageElement) {
            dispatch({
                type: FiltersActions.OVERRIDE_PDF_TITLE_PAGE_ELEMENT,
                payload: {
                    value: {
                        overrideTitlePageElement
                    }
                }
            });
        }
    }, [overrideTitlePageElement]);

    useEffect(() => {
        if (pdfTitle) {
            dispatch({
                type: FiltersActions.SET_PDF_TITLE,
                payload: {
                    value: {
                        title: pdfTitle
                    }
                }
            });
        }
    }, [pdfTitle]);

    useEffect(() => {
        if (active && !internalCallbackFired) {
            if (pdfSubtreeComplete && onSubtreeComplete) {
                onSubtreeComplete(pdfSubtreeComplete);
                setInternalCallbackFired(true);
            }
            if (pdfSubtreeError && onSubtreeError) {
                onSubtreeError(pdfSubtreeError);
                setInternalCallbackFired(true);
            }
        }
    }, [internalCallbackFired, active, pdfSubtreeComplete, pdfSubtreeError]);

    useEffect(() => {
        if (pdfComplete) {
            dispatch({
                type: FiltersActions.COMPLETE_PDF_DOWNLOAD,
                payload: {
                    value: {
                        newPdf: {
                            content: pdfComplete,
                            styles: pdfStyling,
                            header: function (currentPage, pageCount) {
                                return currentPage != 1 && !!pdfFilterText
                                    ? { text: pdfFilterText, alignment: 'center', margin: [0, 10, 0, 0], fontSize: 9 }
                                    : '';
                            },
                            footer: function (currentPage, pageCount) {
                                return { text: currentPage.toString() + ' of ' + pageCount, alignment: 'center' };
                            }
                        }
                    }
                }
            });
            setPdfComplete(false);
        }
        if (pdfError) {
            dispatch({
                type: FiltersActions.ERROR_PDF_DOWNLOAD
            });
            setPdfError(false);
        }
    }, [pdfComplete, pdfError]);

    const delegate = useMemo(() => {
        return {
            pdfSubtreeComplete,
            pdfSubtreeError,
            setPdfSubtreeComplete,
            setPdfSubtreeError,
            setOverrideTitlePageElement,
            setPdfTitle,
            setPdfFilterText,
            setPdfComplete,
            setPdfError,
            getTitlePageOverride
        };
    }, [pdfSubtreeComplete, pdfSubtreeError, setPdfSubtreeComplete, setPdfSubtreeError]);
    return active && delegate;
};

export { usePdfDelegate };
