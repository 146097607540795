import { useState, useEffect } from 'react';

const AdHocFiltersAppliedComponent = ({ selected, filters }) => {
    const [filtersApplied, setFiltersApplied] = useState([]);

    useEffect(() => {
        if (selected && filters) {
            setFiltersApplied(
                filters.reduce((array, { name, param }) => {
                    const hasSelections = Object.values(selected[param] || {}).reduce((a, v) => a || v, false);
                    if (hasSelections) {
                        return array.concat(name);
                    } else {
                        return array;
                    }
                }, [])
            );
        }
    }, [selected, filters]);

    return filtersApplied.length ? (
        <div className="bg-blue-100 border-blue-200 border-2 rounded-sm py-2 px-4 text-sm my-1 flex-grow">
            <strong>Filters applied:</strong> {filtersApplied.join(', ')}
        </div>
    ) : null;
};

export default AdHocFiltersAppliedComponent;
