import { ALL_FIELDS, AUTHENTICATION_KEY, Filters, RELEVANT_FILTER_KEYS } from '../constants';
import OrganizationAutoCompleteFilter from '../components/layout/page-filters/OrganizationAutoCompleteFilter';
import OfficerAutoCompleteFilter from '../components/layout/page-filters/OfficerAutoCompleteFilter';
import PolicyAlertFilter from '../components/layout/page-filters/PolicyAlertFilter';
import TimePeriodFilter from '../components/layout/page-filters/TimePeriodFilter';
import IncludeChildOrganizationsFilter from '../components/layout/page-filters/IncludeChildOrganizationsFilter';
import DemographicsFilter from '../components/layout/page-filters/DemographicsFilter';
import { mock, statsApiUrl, bmsUrl } from '../config.json';
import PeerAndDemographicFilter from '../components/layout/page-filters/PeerAndDemographicFilter';
import PeerFilter from '../components/layout/page-filters/PeerFilter';
import AssignmentAutoCompleteFilter from '../components/layout/page-filters/AssignmentAutoCompleteFilter';
import AdHocApisFilter from '../components/layout/page-filters/AdHocApisFilter';
import InvestigatorFilter from '../components/layout/page-filters/InvestigatorFilter';
import FeatureFilter from '../components/layout/page-filters/FeatureFilter';
import UserDataSectionFilter from '../components/layout/page-filters/UserDataSectionFilter';

var _ = require('lodash');

export const getTokenFromURL = (hash) => {
    if (hash) {
        const decodedHash = decodeURI(hash);
        const hashParams = new URLSearchParams(decodedHash.replace(/^#/, ''));
        const token = hashParams.get('access_token');
        return JSON.parse(token);
    }
    return null;
};

export const convertBMSPersonId = (bmsPersonId, convertRedirectPath) => {
    const searchParams = new URLSearchParams();
    searchParams.set('convert_ids', 'bms_person_id=' + bmsPersonId + '=person&bms_organization_id=0=organization');
    searchParams.set('convert_redirect_path', convertRedirectPath);
    return '/?' + searchParams.toString();
};

export const generateCrumbs = (routes, props) =>
    routes
        .filter(({ path }) => props.match.path.includes(path))
        .map(({ path, ...rest }) => ({
            path: Object.keys(props.match.params).length
                ? Object.keys(props.match.params).reduce(
                      (path, param) => path.replace(`:${param}`, props.match.params[param]),
                      path
                  )
                : path,
            ...rest
        }))
        .sort((a, b) => a.path.length - b.path.length);

export const stripTrailingSlash = (str) => {
    return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const withCaseScheme = (string = '', scheme = 'none') => {
    if (scheme === 'none') {
        return string;
    }
    if (scheme === 'startCaseWords') {
        return _.map(_.map(_.words(string), _.toLower), _.startCase).join(' ');
    }
    if (_[scheme]) {
        return _[scheme](string);
    }
    return 'INVALID CASE SCHEME';
};

export const uppercaseWordsInSentence = (string = '') => {
    return string.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const limitStringLength = (string = '', maxLength) => {
    return string.length >= maxLength ? `${string.substring(0, maxLength - 1)}…` : string;
};

export const splitByChars = (string = '', chars = '') => {
    chars = chars.split('');
    return _.reduce(
        chars,
        (result, char) => {
            let splitResult = result.split(char);
            let flatArray = _.flattenDeep(splitResult);
            result = '';
            for (let i = 0; i < flatArray.length; i++) {
                const separator = i === 0 ? '' : char;
                const startSeparator = !!separator && !result.endsWith(' ') ? ' ' : '';
                const endSeparator = !!separator && !flatArray[i].startsWith(' ') ? ' ' : '';
                result = result + startSeparator + separator + endSeparator + flatArray[i];
            }
            return result;
        },
        string
    );
};

const splitWord = (word = '', maxLength = 10) => {
    let list = [];
    if (word.length > maxLength) {
        list.push(word.substring(0, maxLength));
        list = list.concat(splitWord(word.substring(maxLength), maxLength));
    } else {
        list.push(word);
    }
    return list;
};

export const splitWords = (string = '', maxLength = 10) => {
    const words = _.map(string.split(' '), (word) => {
        return splitWord(word, maxLength);
    });
    return _.flattenDeep(words).join(' ');
};

export const getFilterComponent = (filterName) => {
    switch (filterName) {
        case Filters.ORGANIZATION:
            return OrganizationAutoCompleteFilter;
        case Filters.OFFICER:
            return OfficerAutoCompleteFilter;
        case Filters.POLICY_ALERT:
            return PolicyAlertFilter;
        case Filters.USER_DATA_SECTION:
            return UserDataSectionFilter;
        case Filters.ASSIGNMENT:
            return AssignmentAutoCompleteFilter;
        case Filters.TIME_PERIOD:
            return TimePeriodFilter;
        case Filters.CHILD_ORGANIZATIONS:
            return IncludeChildOrganizationsFilter;
        case Filters.DEMOGRAPHIC:
            return DemographicsFilter;
        case Filters.PEER_AND_ALL_DEMOGRAPHICS:
            return (props) => <PeerAndDemographicFilter allDemographics={true} {...props} />;
        case Filters.PEER_AND_DEMOGRAPHIC_TYPE:
            return (props) => <PeerAndDemographicFilter allDemographics={false} {...props} />;
        case Filters.PEER:
            return PeerFilter;
        case Filters.ADHOC:
            return AdHocApisFilter;
        case Filters.INVESTIGATOR:
            return InvestigatorFilter;
        case Filters.FEATURE:
            return FeatureFilter;
        default:
            return () => <span />;
    }
};

export const constructOrganizationDetailString = ({ name, org_level, agency_organization_code }) =>
    `${name}${org_level ? ` - ${org_level}` : ''}${agency_organization_code ? ` (${agency_organization_code})` : ''}`;

export const constructOfficerDetailString = ({
    first_name,
    last_name,
    badge_number,
    organization_id,
    organization_name,
    title
}) => {
    return `${last_name}, ${first_name} (${title} #${badge_number})`;
};

export const constructRelevantQueryParamsFromFilters = (filterSelectionsObject) => {
    const queryParams = {};
    const keys = Object.keys(filterSelectionsObject);
    keys.forEach((filterKey) => {
        const relevantFields = RELEVANT_FILTER_KEYS[filterKey];
        const filterSelectionObject = filterSelectionsObject[filterKey];
        if (relevantFields && filterSelectionObject) {
            const keys = Object.keys(filterSelectionObject);
            keys.filter((key) => relevantFields === ALL_FIELDS || relevantFields.includes(key)).forEach((key) => {
                const value = filterSelectionObject[key];
                if (value) {
                    queryParams[key] = value;
                }
            });
        }
    });
    return queryParams;
};

export const returnSortedData = (sortByObject, data = []) => {
    try {
        if (!sortByObject) {
            return data;
        }
        const { field, fieldType, order } = sortByObject;
        const sortMethodNumberDesc = (a, b) => b[field] - a[field];
        const sortMethodNumberAsc = (a, b) => a[field] - b[field];
        const sortMethodStringAsc = (a, b) => a[field].localeCompare(b[field]);
        const sortMethodStringDesc = (a, b) => b[field].localeCompare(a[field]);

        if (fieldType === 'string') {
            return data.sort(order === 'ascending' ? sortMethodStringAsc : sortMethodStringDesc);
        } else if (fieldType === 'number') {
            return data.sort(order === 'ascending' ? sortMethodNumberAsc : sortMethodNumberDesc);
        } else {
            return data;
        }
    } catch (e) {
        console.error(`Error sorting data: `, e);
        return data;
    }
};

export const returnGroupedData = (groupBys = [], data = [], filterSelections) => {
    try {
        if (!groupBys.length) {
            return data;
        }
        const group = groupBys.filter((group) => determineElementVisibility(group.visible, filterSelections))[0];
        if (group && data.length) {
            const { field, groupAs, sumFields = [] } = group;
            if (groupAs) {
                const groupedData = data.reduce((value, curr) => {
                    const newReturn = { ...value, [field]: groupAs };
                    sumFields.forEach((gf) => {
                        newReturn[gf.field] = (
                            parseFloat(newReturn[gf.field] || 0) + parseFloat(curr[gf.field] || 0)
                        ).toFixed(gf.decimals || 0);
                    });
                    return newReturn;
                });
                return [groupedData];
            }
            return data;
        } else {
            return data;
        }
    } catch (e) {
        console.error(`Error sorting data: `, e);
        return data;
    }
};

export const transformDataIndexField = (list, dataIndex) => {
    const stringToFloat = (string) => parseFloat(string);
    if (!dataIndex) {
        return list;
    }
    return list.map((item) => {
        try {
            if (typeof item[dataIndex] === 'string') {
                return {
                    ...item,
                    [dataIndex]: stringToFloat(item[dataIndex])
                };
            } else {
                return item;
            }
        } catch (e) {
            console.error(`Error transforming ${dataIndex} field: `, e);
            return item;
        }
    });
};

export const fetchStatsApi = async (endpoint, filterObject, method = 'GET', data, contentType = 'application/json') => {
    const url = typeof endpoint === 'object' ? endpoint.url : endpoint;
    const q = typeof endpoint === 'object' ? endpoint.queryParams || {} : {};
    const headers = {
        Authorization: `Bearer ${localStorage.getItem(AUTHENTICATION_KEY)}`,
        'Content-Type': contentType
    };
    const queryParams = new URLSearchParams({
        ...(mock && { mock: 'mock' }),
        ...constructRelevantQueryParamsFromFilters(filterObject),
        ...q
    });
    return await fetch(`${statsApiUrl}/${url}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`, {
        headers,
        method,
        ...(data && { body: JSON.stringify(data) })
    });
};

export const formatDemographicOptions = (dimensions = {}, includeChildren = true) => {
    const options = [];
    Object.keys(dimensions).forEach((dimension) => {
        options.push({
            label: dimension,
            value: dimension,
            ...(includeChildren && {
                children:
                    dimensions[dimension] &&
                    dimensions[dimension].map((item) => ({
                        value: item,
                        label: item
                    }))
            })
        });
    });
    return options;
};

export const numberFormat = (number, options = undefined) => {
    try {
        return new Intl.NumberFormat(undefined, options).format(number);
    } catch {
        console.error('Error parsing number ', number);
        return number;
    }
};

export const makeCSV = (items, headers, title) => {
    const replacer = (key, value) =>
        value === null ? '' : value === 'string' ? `"${('' + value).replace(/"/g, '\\"')}"` : value;
    const header = headers.map((f) => f.label).map((f) => `"${f.replace(/"/g, '\\"')}"`);
    const headerFields = headers.map((col) => col.field);
    const formattedTitle = title
        ? `"${title.replace(/"/g, '\\"')}"` +
          Array(header.length - 1)
              .fill(',')
              .join('')
        : '';
    return [
        ...(formattedTitle ? [formattedTitle] : []),
        header.join(','),
        ...items.map((row) => headerFields.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');
};

const runLogicalOperators = (fieldValue, operator, val) => {
    if (operator === '==') {
        return fieldValue === val;
    }
    if (operator === '!=') {
        return fieldValue !== val;
    }
    return false;
};

export const determineElementVisibility = (listOfVisibilityConditions = [], filterSelections) => {
    if (typeof listOfVisibilityConditions === 'boolean') {
        return listOfVisibilityConditions;
    }
    return !listOfVisibilityConditions
        .map(({ type, condition }) => {
            if (type === 'filter') {
                const [filter, field, operator, value] = condition;
                const filterValues = filterSelections[filter] || {};
                const fieldValue = filterValues.hasOwnProperty(field) ? filterValues[field] : null;
                if (value && Array.isArray(value)) {
                    if (operator === '!=') {
                        const filteredValues = value.filter((itemValue) =>
                            runLogicalOperators(fieldValue, operator, itemValue)
                        ).length;
                        return filteredValues === value.length;
                    }
                    return !!value.filter((itemValue) => runLogicalOperators(fieldValue, operator, itemValue)).length;
                } else {
                    return runLogicalOperators(fieldValue, operator, value);
                }
            }
            return false;
        })
        .filter((value) => !value).length;
};

export const replaceTemplateStrings = (
    string,
    object,
    useNumberFormat = true,
    numberFormatOptions = {},
    requireAll = false
) => {
    const newObject = _.mapValues(object, (value, key) => {
        if (useNumberFormat && typeof value === 'number') {
            return numberFormat(value, numberFormatOptions);
        }
        return value;
    });
    try {
        return string.replace(/\${(.*?)}/g, (x, g) => {
            const replacement = g.split('.').reduce((obj, f) => obj[f], newObject);
            if (requireAll && !replacement) {
                throw 'required field not found';
            }
            return replacement === undefined || replacement === null ? '' : replacement;
        });
    } catch (ex) {
        return null;
    }
};

export const getEnvironmentVariable = (name, defaultValue = '') => {
    const VAR_NAME = String(name).toUpperCase();
    const variableValue = process.env[VAR_NAME] || process.env[`REACT_APP_${VAR_NAME}`];

    if (variableValue !== undefined) {
        return variableValue;
    }

    return defaultValue;
};

const getLmsUrl = (path = '') => {
    const kmiUrl = getEnvironmentVariable('kmi_host') || 'https://lms.benchmarkanalytics.com';
    const normalizedBaseUrl = kmiUrl.endsWith('/') ? kmiUrl.substr(0, kmiUrl.length - 1) : kmiUrl;
    const normalizedPath = path.startsWith('/') ? path.substr(1, path.length) : path;
    return [normalizedBaseUrl, normalizedPath].join('/');
};

export const openUrlInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const openIzendaReport = (reportUrl) => {
    const url = `${bmsUrl}/${reportUrl}`;
    openUrlInNewTab(url);
};

export const camelize = (string) => {
    return string
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
};

export const snakeCaseToTitleCase = (slug) =>
    slug
        .split('_')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(' ');

export const camelCaseToTitleCase = (string) => {
    const splitString = string.replace(/([A-Z])/g, ' $1');
    return splitString.charAt(0).toUpperCase() + splitString.slice(1);
};

export default getLmsUrl;
