import GridWrapper from '../generic/GridWrapper';
import { ChildrenWrapper } from '../generic/ComponentInterpreter';
import CardComponentEditorSchema from './CardComponentEditorSchema.json';
import CardComponentParams from './CardComponentParams.json';
import { usePdfDelegate } from '../../hooks/usePdfDelegate';

const CardComponent = ({ params, children, pdfDelegate }) => {
    const { className, pdfPageBreak = false } = params;

    const subtreeDelegate = usePdfDelegate(
        pdfDelegate,
        (subtreeResult) => {
            if (subtreeResult && subtreeResult.length > 0 && pdfPageBreak) {
                const result =
                    pdfPageBreak == 'after'
                        ? subtreeResult.concat([{ text: '', pageBreak: 'after' }])
                        : [{ text: '', pageBreak: 'after' }].concat(subtreeResult);
                pdfDelegate?.setPdfChildCompleteFlag(result);
            } else {
                pdfDelegate?.setPdfChildCompleteFlag(subtreeResult);
            }
        },
        (subtreeError) => {
            pdfDelegate?.setPdfChildErrorFlag(subtreeError);
        }
    );

    return (
        <div className={`bg-white p-4 ${className ? className : ''}`}>
            <GridWrapper isSubcomponent={true}>
                <ChildrenWrapper childComponents={children} pdfDelegate={subtreeDelegate} />
            </GridWrapper>
        </div>
    );
};

export { CardComponentParams, CardComponent, CardComponentEditorSchema };
