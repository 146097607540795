import { Link } from 'react-router-dom';
import React from 'react';
import '../../../assets/styles/navMenu.css';
import { ChildrenWrapper } from '../../generic/ComponentInterpreter';

const PageTabs = ({ navData, onClickTab, selectedTab, openedTab }) => {
    const rightSideComponentOptions = {
        component: 'ExternalLinkDropdown',
        endpoint: {
            url: 'dashboard/izenda_reports'
        },
        params: {
            title: 'Analytic Reports'
        },
        visible: true,
        colSpan: 1,
        order: 10,
        agencyId: 0,
        parentPage: 29,
        componentId: 254,
        parentComponent: 253,
        key: 10009,
        componentKey: 10009,
        includeComponentFiltersProvider: true
    };

    return (
        <div className="navbar bg-navbar flex flex-row border-hr" style={{ minHeight: 36 }}>
            {Object.keys(navData)
                .sort((key1, key2) => {
                    return navData[key1].order - navData[key2].order;
                })
                .map((path) => {
                    return (
                        <div className="dropdown" key={path}>
                            <div
                                className="dropdown-root text-center mx-4"
                                key={path}
                                onClick={() => {
                                    onClickTab(path);
                                }}>
                                <Link to={`#`}>
                                    <div className={`${path === selectedTab ? 'bg-accent' : 'bg-inactive-tab'} h-1`} />
                                    <h2
                                        className={`${
                                            path === selectedTab ?  'tab-item-selected' : 'tab-item'
                                        } py-2 `}>
                                        {navData[path].shortTitle || navData[path].title}
                                    </h2>
                                    <div
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                        {openedTab === path && <div className="arrow-up" />}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    );
                })}
            <div className="flex flex-row dropdown flex-grow flex-row-reverse">
                <ChildrenWrapper childComponents={[rightSideComponentOptions]} />
            </div>
        </div>
    );
};

export default PageTabs;
