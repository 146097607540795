import { Spin } from 'antd';

const LoadingState = ({ text }) => (
    <div className="bg-main min-w-screen w-full h-full flex flex-row items-center justify-center">
        <div className="text-center">
            <Spin size="large" />
            <h1 className="font-medium text-xl opacity-80 mt-4">{text}</h1>
        </div>
    </div>
);

export default LoadingState;
