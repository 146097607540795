import { useMemo, useContext, useState, createRef } from 'react';
import { Checkbox } from 'antd';
import * as moment from 'moment';
import { Filters } from '../../../constants';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';

const IncludeChildOrganizationsFilter = ({ title }) => {
    const [{ filterSelections }, dispatch] = useContext(FiltersContext);
    const { include_child_organizations } = filterSelections[Filters.CHILD_ORGANIZATIONS];
    const [value, setValue] = useState(include_child_organizations);
    const dispatchNewSelection = (include) => {
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: {
                filterId: Filters.CHILD_ORGANIZATIONS,
                value: { include_child_organizations: include === true ? true : "false" }
            }
        });
    };

    const onIncludeChange = (e) => {
        setValue(e.target.checked);
        dispatchNewSelection(e.target.checked);
    };

    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">
                {!title ? 'Include Child Organizations' : title}
            </h3>
            <div className="flex flex-row items-center">
                <div className="mr-1">
                    <Checkbox checked={(value === "false") ? false : value} disabled={false} onChange={onIncludeChange}>
                        {/* Include Child Organizations */}
                    </Checkbox>
                </div>
            </div>
        </div>
    );
};

export default IncludeChildOrganizationsFilter;
