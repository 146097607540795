import { useState, useMemo, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageSpecificFilters from '../../../contexts/PageSpecificFilters';
import PageCrumbs from './PageCrumbs';
import PageTabs from './PageTabs';
import HoverMenu from './HoverMenu';
import PageFilters from '../page-filters/PageFilters';
import {
    ADHOC_MODULE_NAME,
    Filters,
    USER_DATA_MODULE_NAME,
    FIRST_SIGN_OFFICER_ADVOCACY_SUITE_PATH
} from '../../../constants/index';
import PageLayout from '../../generic/PageLayout';
import StatusComponent from '../../status/StatusComponent';
import DownloadPDFFilter from '../page-filters/DownloadPDFFilter';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import AdHocFilterComponent from '../../spatial/AdHocFilterComponent';
import UserDataFilterComponent from '../../spatial/UserDataFilterComponent';
import { AdHocContextProvider } from '../../../contexts/AdHocContext';
import { UserDataContextProvider } from '../../../contexts/UserDataContext';
import { ComponentFiltersProvider } from '../../../contexts/ComponentFiltersContext';
import { useLocation } from 'react-router';

const pruneNavData = (node) => {
    return Object.keys(node).reduce((accumulator, childKey) => {
        if (!node[childKey].found) {
            return accumulator;
        }
        let newNode = {
            ...node[childKey],
            children: pruneNavData(node[childKey].children)
        };
        let newAccumulator = {
            ...accumulator
        };
        newAccumulator[childKey] = newNode;
        return newAccumulator;
    }, {});
};

const ConfigurationRouteMapper = ({ pages }) => {
    const [openedTab, setOpenedTab] = useState(undefined);
    const [rightSideColapsed, setRightSideColapsed] = useState(false);

    const location = useLocation();

    const closeTab = useMemo(() => {
        return () => {
            setOpenedTab(null);
        };
    }, []);

    // This is a hack to avoid glitchiness
    // source: https://blog.logrocket.com/building-a-custom-dropdown-menu-component-for-react-e94f02ced4a1/
    useEffect(() => {
        setTimeout(() => {
            if (openedTab) {
                window.addEventListener('click', closeTab);
            } else {
                window.removeEventListener('click', closeTab);
            }
        }, 0);
    }, [openedTab, closeTab]);

    const segments = location.pathname.split('/').slice(1);
    const selectedTab = segments.length && segments[0];

    const onTabSelected = (tab) => {
        if (openedTab === tab) {
            closeTab();
        } else {
            window.removeEventListener('click', closeTab);
            setOpenedTab(tab);
        }
    };

    const toggleRightSide = () => {
        setRightSideColapsed(!rightSideColapsed);
    };

    const calculatedNavData = useMemo(() => {
        return pages.reduce((accumulator, { path, title, order }, index) => {
            const [pagePath, parentPath] = path.split(':');
            let pathSegments = pagePath.split('/').filter((segment) => {
                return segment !== '';
            });
            let node = JSON.parse(JSON.stringify(accumulator));
            let [longTitle, shortTitle, description] = title.split('|');
            const root = node;
            pathSegments.forEach((segment, index) => {
                if (index + 1 === pathSegments.length) {
                    if (node[segment]) {
                        node[segment] = {
                            ...node[segment],
                            title: longTitle,
                            pagePath,
                            parentPath,
                            shortTitle: shortTitle || longTitle,
                            description: description || '',
                            order: order || 0,
                            found: true
                        };
                    } else {
                        node[segment] = {
                            title: longTitle,
                            pagePath,
                            parentPath,
                            shortTitle: shortTitle || longTitle,
                            description: description || '',
                            children: {},
                            order: order || 0,
                            found: true
                        };
                    }
                } else {
                    if (!node[segment]) {
                        node[segment] = {
                            title: '',
                            pagePath,
                            parentPath,
                            description: '',
                            children: {},
                            order: 0,
                            found: false
                        };
                    }
                    node = node[segment].children;
                }
            });
            return root;
        }, {});
    }, [pages]);

    const prunedNavData = useMemo(() => {
        return pruneNavData(calculatedNavData);
    }, [calculatedNavData]);

    const filteredNavData = useMemo(() => {
        return Object.keys(prunedNavData).reduce((acc, key) => {
            if (key !== FIRST_SIGN_OFFICER_ADVOCACY_SUITE_PATH) {
                acc[key] = prunedNavData[key];
            }
            return acc;
        }, {});
    }, [prunedNavData]);

    const defaultPath = useMemo(() => {
        const navDataKeys = Object.keys(prunedNavData);
        return (
            '/' +
            navDataKeys.reduce((accumulator, topLevelPath) => {
                return prunedNavData[accumulator].order < prunedNavData[topLevelPath].order
                    ? accumulator
                    : topLevelPath;
            }, navDataKeys.length && navDataKeys[0])
        );
    }, [prunedNavData]);

    const mappedPages = pages.map(({ module: mod, path, title, children, showCrumbs, filters, endpoints }, key) => {
        const rightSideChildren = children.filter((ch) => !!ch?.params?.rightSideBar);
        const mainChildren = children.filter((ch) => !ch?.params?.rightSideBar);
        const rightSideFilterOn = rightSideChildren.length > 0;

        const splitTitle = !!title ? title.split('|')[0] : '';

        const pathSuffix =
            filters
                .filter((filter) => {
                    return filter.startsWith(':');
                })
                .reduce((pathSuffix, filter, idx) => {
                    const split = filter.split(':');
                    const newSuffix = [].concat(pathSuffix);
                    newSuffix[parseInt(split[1])] = `/:${split[2]}`;
                    return newSuffix;
                }, [])
                .join('') || '';

        const [pagePath, parentPath] = path.split(':');

        const nonPathFilters = filters.filter((filter) => {
            return !filter.startsWith(':');
        });

        return (
            <Route
                exact
                path={[pagePath + pathSuffix, pagePath]}
                key={key}
                render={(props) => {
                    let cmp = (
                        <div className="flex flex-1 flex-row overflow-y-auto ">
                            <div className="flex-1 flex flex-col">
                                <div className="lg:h-20 flex flex-col lg:flex-row lg:items-center bg-main-accent">
                                    <PageFilters filters={nonPathFilters} />
                                    {nonPathFilters.includes(Filters.DOWNLOAD_PDF) && <DownloadPDFFilter />}
                                </div>
                                <main className="focus:outline-none bg-main flex-grow" tabIndex="0">
                                    <PageLayout
                                        title={splitTitle}
                                        config={{ children: mainChildren, endpoints }}
                                        filters={nonPathFilters}
                                    />
                                </main>
                            </div>
                            {rightSideFilterOn ? (
                                <div
                                    className="bg-white relative"
                                    style={{
                                        transition: 'width 1s',
                                        color: '#555',
                                        width: (rightSideColapsed ? 60 : 300) + 'px',
                                        textAlign: rightSideColapsed ? 'center' : 'left'
                                    }}>
                                    <span
                                        className="absolute"
                                        style={{
                                            width: '25px',
                                            color: '#3772FF',
                                            left: rightSideColapsed ? 15 : 20,
                                            top: 9,
                                            transition: 'left 1s'
                                        }}
                                        onClick={toggleRightSide}>
                                        {rightSideColapsed ? (
                                            <DoubleLeftOutlined size={10} style={{ cursor: 'pointer' }} />
                                        ) : (
                                            <DoubleRightOutlined size={10} style={{ cursor: 'pointer' }} />
                                        )}
                                    </span>
                                    <div className={rightSideColapsed ? 'hide-and-show-a' : 'hide-and-show-b'}>
                                        {mod === ADHOC_MODULE_NAME ? (
                                            <AdHocFilterComponent
                                                colapsed={rightSideColapsed}
                                                params={rightSideChildren[0].params}
                                            />
                                        ) : null}
                                        {mod === USER_DATA_MODULE_NAME ? (
                                            <UserDataFilterComponent
                                                colapsed={rightSideColapsed}
                                                params={rightSideChildren[0].params}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    );

                    if (nonPathFilters?.includes('userdata')) {
                        cmp = (
                            <ComponentFiltersProvider>
                                <UserDataContextProvider>{cmp}</UserDataContextProvider>
                            </ComponentFiltersProvider>
                        );
                    }

                    const decipherNavData = () => {
                        return location.pathname.includes(FIRST_SIGN_OFFICER_ADVOCACY_SUITE_PATH)
                            ? [prunedNavData.first_sign_officer_advocacy_suite]
                            : filteredNavData;
                    };

                    return (
                        <PageSpecificFilters.Provider value={nonPathFilters}>
                            <header className="flex flex-col shadow z-10" style={{ minHeight: 72 }}>
                                <PageTabs
                                    navData={decipherNavData()}
                                    onClickTab={onTabSelected}
                                    selectedTab={selectedTab}
                                    openedTab={openedTab}
                                />
                                {openedTab && (
                                    <HoverMenu path={openedTab} module={prunedNavData[openedTab]}></HoverMenu>
                                )}
                                <PageCrumbs navData={prunedNavData} />
                            </header>
                            {cmp}
                        </PageSpecificFilters.Provider>
                    );
                }}
            />
        );
    });

    return (
        <ComponentFiltersProvider>
            <AdHocContextProvider>
                <Switch>
                    {mappedPages}
                    <Route exact path="/status">
                        <StatusComponent />
                    </Route>

                    <Route exact path="/first_sign_suite">
                        <h1 className="text-4xl text-center">First Sign Suite</h1>
                    </Route>
                    <Route path="/">
                        <Redirect to={defaultPath} />
                    </Route>
                </Switch>
            </AdHocContextProvider>
        </ComponentFiltersProvider>
    );
};

export default ConfigurationRouteMapper;
