import _ from 'lodash';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { EditText } from 'react-edit-text';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { useEffect, useState, useContext } from 'react';
import { Modal, Select, Button } from 'antd';
import Draggable from 'react-draggable';
import { FiltersProvider } from '../../contexts/FiltersContext';
import { FiltersContext } from '../../contexts/FiltersContext';
import PageSpecificFilters from '../../contexts/PageSpecificFilters';
import PageLayout from '../../components/generic/PageLayout';
import { editorSchemaForComponentType } from '../utilities';
import { useStatsApi, useStatsPostApi } from '../../hooks/useApi';
import {
    PAGE_EDITOR_PARAMS,
    MODULE_EDITOR_PARAMS,
    BASE_API_COMPONENT_EDITOR_PARAMS,
    UNKNOWN_COMPONENT_EDITOR_PARAMS
} from '../constants';
import { ConfigContext } from 'antd/lib/config-provider';
const Option = Select.Option;

const arrayRenderer = ({ value, schemaField, component, allPages, onModify, apiResult }) => {
    const arrayComponent = _.get(component, schemaField.path);
    const handleAdd = () => {
        onModify(_.cloneDeep(arrayComponent || []).concat({}));
    };
    const handleRemove = (index) => {
        let newArray = _.cloneDeep(arrayComponent);
        newArray.splice(index, 1);
        onModify(newArray);
    };
    return (
        <div style={{ flex: 1, border: 1 }}>
            {_.map(arrayComponent, (nestedComponent, index) => {
                return (
                    <div style={{ border: '1px solid black' }}>
                        <Button
                            key="remove"
                            onClick={() => {
                                handleRemove(index);
                            }}>
                            Remove
                        </Button>
                        <table style={{ width: '100%' }}>
                            {_.map(schemaField.schema, (schemaField) => {
                                return FieldRenderer({
                                    schemaField,
                                    component: nestedComponent,
                                    onModify: (value) => {
                                        const setPath = '[' + index + '].' + schemaField.path;
                                        onModify(_.set(_.cloneDeep(arrayComponent), setPath, value));
                                    },
                                    apiResult,
                                    allPages
                                });
                            })}
                        </table>
                    </div>
                );
            })}
            <Button key="add" onClick={handleAdd}>
                Add
            </Button>
        </div>
    );
};

const jsonRender = ({ value, onModify }) => {
    return <Editor htmlElementProps={{ style: { flex: 1 } }} mode="code" value={value} onChange={onModify} />;
};

const filterRender = ({ value, onModify }) => {
    const filters = ['organization', 'timePeriod', 'child_organizations', 'user_data_section', 'demographic', 'officer', 'download_pdf', 'policy_alert'];
    return (
        <div>
            {_.map(filters, (filter) => {
                return (
                    <div>
                        <input
                            checked={value.includes(filter)}
                            id={filter + '_filter_checkbox'}
                            type="checkbox"
                            onChange={() => {
                                onModify(
                                    _.filter(filters, (testFilter) => {
                                        return testFilter == filter
                                            ? !value.includes(testFilter)
                                            : value.includes(testFilter);
                                    })
                                );
                            }}
                        />
                        <label for={filter + '_filter_checkbox'}> {filter}</label>
                        <br></br>
                    </div>
                );
            })}
        </div>
    );
};

const singleSelectRender = ({ value, onModify, schemaField }) => {
    return (
        <Select
            defaultValue={value}
            showClear
            style={{ width: 150 }}
            onChange={(value) => {
                onModify(value);
            }}>
            {_.map(schemaField.singleSelectOptions, (option) => {
                return <Option value={option}>{option}</Option>;
            })}
        </Select>
    );
};

const moduleRender = ({ value, onModify, schemaField, apiResult, allPages }) => {
    const modules = _.filter(allPages, (page) => {
        return page.module;
    });
    return (
        <Select
            defaultValue={value}
            showClear
            style={{ width: 150 }}
            onChange={(value) => {
                onModify(value);
            }}>
            {_.map(modules, (module) => {
                return <Option value={module.module}>{module.module}</Option>;
            })}
        </Select>
    );
};

const apiResultKeyRender = ({ value, onModify, schemaField, apiResult }) => {
    const { apiResultPath } = schemaField;
    const { statsApiLoading, statsApiError, statsApiData } = apiResult;
    if (statsApiLoading) {
        return <span className="font-bold flex-1">Loading</span>;
    } else if (statsApiError) {
        return <span className="font-bold flex-1">Error loading API</span>;
    } else if (statsApiData) {
        return (
            <div>
                <Select
                    defaultValue={value}
                    showClear
                    style={{ width: 150 }}
                    onChange={(value) => {
                        onModify(value);
                    }}>
                    {_.map(Object.keys(_.get(statsApiData, apiResultPath, statsApiData)), (key) => {
                        return <Option value={key}>{key}</Option>;
                    })}
                </Select>
                <EditText className="flex-1" value={value || ''} placeholder={'<empty>'} onChange={onModify} />;
            </div>
        );
    } else {
        return <span className="font-bold flex-1">Unknown issue</span>;
    }
};

const booleanRender = ({ value, onModify }) => {
    return (
        <input
            checked={value}
            type="checkbox"
            onChange={() => {
                onModify(!value);
            }}
        />
    );
};

const numberRender = ({ value, onModify }) => {
    return <EditText className="flex-1" type="number" value={value || 0} onChange={onModify} />;
    // return (<span className="font-bold flex-1">{value}</span>);
};

const stringRender = ({ value, onModify }) => {
    return <EditText className="flex-1" value={value || ''} placeholder={'<empty>'} onChange={onModify} />;
    // return (<span className="font-bold flex-1">{value}</span>);
};

const typeRenderers = {
    array: arrayRenderer,
    string: stringRender,
    number: numberRender,
    boolean: booleanRender,
    json: jsonRender,
    apiResultKey: apiResultKeyRender,
    module: moduleRender,
    filter: filterRender,
    singleSelect: singleSelectRender
};

const FieldRenderer = ({ schemaField, component, allPages, onModify, apiResult }) => {
    const typeRenderer = typeRenderers[schemaField['type']];
    const path = schemaField['path'];
    let value = _.get(component, path);
    if (value === undefined) {
        value = schemaField.defaultValue;
    }
    let editField = undefined;
    if (typeRenderer) {
        editField = typeRenderer({ value, schemaField, component, allPages, onModify, apiResult });
    } else {
        console.log('no type renderer found!');
    }
    return (
        <tr>
            <td>
                <span className="font-bold flex-1">{schemaField.readableName}</span>
            </td>
            <td>{editField}</td>
        </tr>
    );
};

const EditComponentModal = ({
    setEditComponentModalOpen,
    editComponentModalOpen,
    setEditComponent,
    pushEdit,
    popEdit,
    pokeEdit,
    component,
    allPages
}) => {
    const [dragDisabled, setDragDisabled] = useState(true);
    const [draggleRef, setDraggleRef] = useState(React.createRef());
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const [{ filterSelections }] = useContext(FiltersContext);
    const [statsApiEndpoint, setStatsApiEndpoint] = useState({});
    const [statsApiData, setStatsApiData] = useState({});
    const [statsApiLoading, setStatsApiLoading] = useState(false);
    const [statsApiError, setStatsApiError] = useState(null);
    const [modifyPage, setModifyPage] = useState(null);
    const [modifyComponent, setModifyComponent] = useState(null);
    const [rawModeEnabled, setRawModeEnabled] = useState(false);
    const componentType = component.component;
    const editorSchema =
        (!rawModeEnabled && editorSchemaForComponentType(componentType)) ||
        (!!componentType ? UNKNOWN_COMPONENT_EDITOR_PARAMS : PAGE_EDITOR_PARAMS);

    const [apiData, apiLoading, apiError] = useStatsApi(component.endpoint?.url, filterSelections);
    const apiResult = { statsApiLoading, statsApiError, statsApiData };
    useEffect(() => {
        setStatsApiEndpoint(component.endpoint?.url);
        setStatsApiLoading(apiLoading);
        setStatsApiData(apiData);
        setStatsApiError(apiError);
    }, [component.endpoint?.url, apiData, apiLoading, apiError]);

    useEffect(() => {}, [apiData, apiLoading, apiError]);

    useEffect(() => {}, [statsApiData, statsApiLoading, statsApiError]);

    const onModify = (component, path) => (newValue) => {
        let modifiedComponent = _.set(_.cloneDeep(component), path, newValue);
        setEditComponent(modifiedComponent);
        pokeEdit({
            cmp: modifiedComponent
        });
    };

    const handleRaw = () => {
        setRawModeEnabled(!rawModeEnabled);
    };
    const handleCancel = () => {
        setEditComponentModalOpen(false);
        popEdit();
    };
    const handleSave = () => {
        setEditComponentModalOpen(false);
    };
    const handleCommit = () => {
        if (component.pageId) {
            setModifyPage(component);
        } else {
            setModifyComponent(component);
        }
    };
    const handleDelete = () => {
        pokeEdit({
            op: component.pageId ? 'deletePage' : 'deleteComponent',
            cmp: component
        });
        setEditComponentModalOpen(false);
    };
    const onStartDrag = (event, uiData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        setBounds({
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y)
        });
    };

    let loading = false;

    return (
        <Modal
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (dragDisabled) {
                            setDragDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDragDisabled(true);
                    }}
                    // fix eslintjsx-a11y/mouse-events-have-key-events
                    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                    onFocus={() => {}}
                    onBlur={() => {}}
                    // end
                >
                    Edit {componentType}
                </div>
            }
            width="50%"
            height="80%"
            mask={true}
            bodyStyle={{ padding: 8, overflow: 'auto', height: '100%' }}
            destroyOnClose={true}
            closable={true}
            modalRender={(modal) => (
                <Draggable disabled={dragDisabled} bounds={bounds} onStart={onStartDrag}>
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
            onOk={handleCommit}
            onCancel={handleCancel}
            footer={[
                <Button key="raw" onClick={handleRaw}>
                    {rawModeEnabled ? 'Disable Raw Mode' : 'Enable Raw Mode'}
                </Button>,
                <Button key="delete" type="primary" loading={loading} onClick={handleDelete}>
                    Delete
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" loading={loading} onClick={handleSave}>
                    Save
                </Button>
            ]}
            visible={editComponentModalOpen}>
            <table style={{ width: '100%' }} className="flex-1">
                {editorSchema.map((schemaField) => {
                    return FieldRenderer({
                        schemaField,
                        component,
                        onModify: onModify(component, schemaField.path),
                        apiResult,
                        allPages
                    });
                })}
            </table>
        </Modal>
    );
};

export default EditComponentModal;
