import { Select, Input } from 'antd';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { useContext, useEffect, useState } from 'react';
import { Filters } from '../../../constants';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
const { Option } = Select;
var _ = require('lodash');

const PolicyAlertFilter = ({ title }) => {
    const [policyAlert, setPolicyAlert] = useQueryParam('policy_alert', NumberParam);
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);
    const [policyAlertOptions, setPolicyAlertOptions] = useState([]);
    const [selectedKey, setSelectedKey] = useState(null);
    const [showAll, setShowAll] = useState(true);
    const [isHovering, setIsHovering] = useState(false);
    const { policy_alert } = filterSelections[Filters.POLICY_ALERT] || {};
    const policyOptionsRaw = filterOptions[Filters.POLICY_ALERT];

    useEffect(() => {
        const newOptions = policyOptionsRaw.map((option, index) => ({
            label: `${option.policy_name} - ${option.start_date_id}`,
            value: option.person_risk_id,
            key: option.person_risk_id
        }));
        setPolicyAlertOptions([]);
        setPolicyAlertOptions(newOptions);
    }, [policyOptionsRaw]);

    useEffect(() => {
        setSelectedKey(policy_alert);
    }, [policy_alert, policyAlertOptions]);

    const onPolicyChange = (value, option) => {
        if (option && Object.keys(option).length) {
            const match = _.find(policyOptionsRaw, (raw) => {
                return raw.person_risk_id == option.value;
            });
            if (match) {
                setPolicyAlert(match.person_risk_id);
            }
        }
    };

    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">{!title ? 'Policy Alert' : title}</h3>
            <Select
                id="policy-alert"
                showSearch
                placeholder="Select a Policy Alert"
                style={{ width: 225 }}
                onSelect={onPolicyChange}
                dropdownMatchSelectWidth={500}
                value={selectedKey}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {policyAlertOptions.map((option, index) => {
                    return (
                        <Option key={option.key} value={option.value}>
                            {option.label}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

const DropdownSvg = ({ isHovering }) =>
    !isHovering ? (
        <svg
            className="h-2.5 w-2.5 text-inactive-tab"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
    ) : (
        ''
    );

export default PolicyAlertFilter;
