import { useContext, useMemo, useState, useEffect } from 'react';
import { Filters } from '../../../constants';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { Button, Cascader, Checkbox, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { formatDemographicOptions } from '../../../utilities';
import { ComponentFiltersContext } from '../../../contexts/ComponentFiltersContext';

const PeerAndDemographicFilter = ({ title, allDemographics, useComponentState = false }) => {
    const [{ filterSelections, filterOptions }, dispatchFilters] = useContext(FiltersContext);
    const [, dispatchComponentFilters] = useContext(ComponentFiltersContext) || [];
    const { type, dimension } = filterSelections[Filters.DEMOGRAPHIC];
    const dimensions = filterOptions[Filters.DEMOGRAPHIC];
    const [value, setValue] = useState([type, dimension]);
    const [displayOptions, setDisplayOptions] = useState({
        peerEnabled: false,
        demographicEnabled: false
    });

    const filterId = allDemographics ? Filters.PEER_AND_ALL_DEMOGRAPHICS : Filters.PEER_AND_DEMOGRAPHIC_TYPE;
    const filterValue = {
        peerEnabled: displayOptions.peerEnabled,
        demographicEnabled: displayOptions.demographicEnabled
    };

    useEffect(() => {
        dispatch({ type: FiltersActions.SET_FILTER_STATE, payload: { filterId, value: filterValue } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setValue([type, dimension]);
    }, [type, dimension]);

    const dispatch = ({ type, payload }) => {
        if (useComponentState) {
            dispatchComponentFilters((currentState) => ({
                ...currentState,
                [payload.filterId]: payload.value
            }));
        } else {
            dispatchFilters({ type, payload });
        }
    };

    const dispatchNewSelection = (value, list) => {
        if (value.length) {
            const type = list[0].value;
            const dimension = list[1] ? list[1].value : '';
            dispatch({
                type: FiltersActions.SET_FILTER_STATE,
                payload: {
                    filterId,
                    value: { type, dimension, ...displayOptions }
                }
            });
            setValue([type, dimension]);
        } else {
            setValue(null);
        }
    };

    const onCheckboxClick = (checkboxType) => {
        const filterValueCopy = { ...filterValue };
        if (checkboxType === 'demographicEnabled') {
            setValue(['All', [{ label: 'All', value: 'All' }]]);
            filterValueCopy.demographicEnabled = !filterValueCopy.demographicEnabled;
        } else if (checkboxType === 'peerEnabled') {
            filterValueCopy.peerEnabled = !filterValueCopy.peerEnabled;
            if (filterValueCopy.demographicEnabled) {
                filterValueCopy.type = value[0];
                filterValueCopy.dimension = value[1];
            }
        }
        dispatch({ type: FiltersActions.SET_FILTER_STATE, payload: { filterId, value: filterValueCopy } });
        setDisplayOptions((state) => ({
            ...state,
            [checkboxType]: !state[checkboxType]
        }));
    };

    const options = useMemo(
        () => formatDemographicOptions(dimensions, allDemographics),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dimensions]
    );
    const toggledOptions = useMemo(
        () =>
            Object.keys(displayOptions)
                .map((key) =>
                    displayOptions[key] ? (key === 'peerEnabled' ? 'Peer Comparison' : 'Demographic') : null
                )
                .filter((item) => !!item),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [displayOptions]
    );

    return (
        <div className="flex items-center space-x-2">
            <div>
                <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">
                    {!title ? 'Analysis' : title}
                </h3>
                <Dropdown
                    overlay={
                        <Menu>
                            <div className="z-10 hover:bg-gray-100 cursor-pointer">
                                <Checkbox
                                    className="text-xs py-1.5 px-2 z-1 w-full"
                                    checked={displayOptions.demographicEnabled}
                                    onClick={() => onCheckboxClick('demographicEnabled')}>
                                    Demographic
                                </Checkbox>
                            </div>
                            <div className="z-10 hover:bg-gray-100 cursor-pointer">
                                <Checkbox
                                    className="text-xs py-1.5 px-2 z-1 w-full"
                                    checked={displayOptions.peerEnabled}
                                    onClick={() => onCheckboxClick('peerEnabled')}>
                                    Peer Comparison
                                </Checkbox>
                            </div>
                        </Menu>
                    }
                    placement="bottomLeft"
                    trigger="click">
                    <Button
                        className="rounded-xs-sm text-xs flex justify-between items-center space-x-2"
                        style={{ minWidth: 216 }}>
                        <div>
                            <span>{toggledOptions.join(', ')}</span>
                            {!toggledOptions.length && <span className="opacity-50">Select</span>}
                        </div>
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
            {displayOptions.demographicEnabled && (
                <div className="lg:pl-4 w-28">
                    <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">
                        {!title ? 'Demographic' : title}
                    </h3>
                    <Cascader onChange={dispatchNewSelection} value={value} options={options} />
                </div>
            )}
        </div>
    );
};

export default PeerAndDemographicFilter;
