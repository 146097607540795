import React, { forwardRef } from 'react';
import { Cell, Tooltip, PieChart, Pie, Legend, ResponsiveContainer } from 'recharts';
import { MAIN_CHART_COLORS } from '../../constants';

const AdHocPieChartCustomizedLabel = ({ cx, cy, midAngle, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 40;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const AdHocPieChart = forwardRef(({ chartHeight, vals }, ref) => {
    return (
        <ResponsiveContainer width="100%" height={chartHeight}>
            <PieChart margin={{ top: 10, bottom: 10, left: 30, right: 30 }} ref={ref}>
                <Pie
                    data={vals}
                    cx="50%"
                    cy="50%"
                    dataKey="count"
                    nameKey="label"
                    innerRadius={100}
                    outerRadius={150}
                    // fix for customized label sometimes not rendering due a race condition
                    // check out: https://github.com/recharts/recharts/issues/929#issuecomment-348280812
                    isAnimationActive={false}
                    label={AdHocPieChartCustomizedLabel}>
                    {vals.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={MAIN_CHART_COLORS[index % MAIN_CHART_COLORS.length]} />
                    ))}
                </Pie>
                <Legend iconType="circle" />
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
});

export default AdHocPieChart;
