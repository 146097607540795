import GridWrapper from './GridWrapper';
import { ChildrenWrapper } from './ComponentInterpreter';
import { usePdfDelegate } from '../../hooks/usePdfDelegate';
import LoadingSpinner from '../states/LoadingSpinner';

function RepeatedChildComponent({ params, children, data, loading, error, endpoint, pdfDelegate }) {
    let childComponents = [];
    (data || []).forEach((item) => {
        childComponents = childComponents.concat(
            children.map((child) => {
                return { ...child, defaultData: item };
            })
        );
    });

    const subtreeDelegate = usePdfDelegate(
        pdfDelegate,
        (subtreeResult) => {
            pdfDelegate?.setPdfChildCompleteFlag(subtreeResult);
        },
        (subtreeError) => {
            pdfDelegate?.setPdfChildErrorFlag(subtreeError);
        }
    );

    if (!!loading) {
        const filledInDataHeight = 96;
        return (
            <div className="p-2">
                <div
                    className={'border border-main-accent rounded-xs-sm h-24 flex flex-col justify-center items-center'}
                    style={{ height: filledInDataHeight }}>
                    <LoadingSpinner size="medium" />
                </div>
            </div>
        );
    }

    return (
        <GridWrapper isSubcomponent={false}>
            <ChildrenWrapper childComponents={childComponents} pdfDelegate={subtreeDelegate} />
        </GridWrapper>
    );
}

export default RepeatedChildComponent;
