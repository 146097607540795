import SubpageSummaryNavigationComponent from '../components/summary-navigation/SubpageSummaryNavigationComponent';
import PieChartComponent from '../components/charts/PieChartComponent';
import LinkList from '../components/links/LinkList';
import {
    TwoHeadingButtonCard,
    TwoHeadingButtonCardEditorSchema
} from '../components/summary-navigation/TwoHeadingButtonCard';
import {
    DocumentDownloadButton,
    DocumentDownloadButtonEditorSchema
} from '../components/generic/DocumentDownloadButton';
import {
    HorizontalBarChartComponent,
    HorizontalBarChartComponentEditorSchema
} from '../components/charts/HorizontalBarChartComponent';
import ScatterPlotComponent from '../components/charts/ScatterPlotComponent';
import VerticalBarChartComponent from '../components/charts/VerticalBarChartComponent';
import { TrendChartComponent, TrendChartComponentEditorSchema } from '../components/charts/TrendChartComponent';
import { ProfileStyleCard, ProfileStyleCardEditorSchema } from '../components/summary-navigation/ProfileStyleCard';
import OfficerInformationHeaderComponent from '../components/layout/main-layout/OfficerInformationHeaderComponent';
import AlertBoxComponent from '../components/generic/AlertBoxComponent';
import SharedFilterComponent from '../components/generic/SharedFilterComponent';
import TextComponent from '../components/generic/TextComponent';
import DotTextDateComponent from '../components/generic/DotTextDateComponent';
import IconButtonComponent from '../components/generic/IconButtonComponent';
import IconButtonRowComponent from '../components/generic/IconButtonRowComponent';
import RepeatedChildComponent from '../components/generic/RepeatedChildComponent';
import {
    SummaryStatsNavigationCard,
    SummaryStatsNavigationCardEditorSchema
} from '../components/summary-navigation/SummaryStatsNavigationCard';
import {
    LinkGridNavigationCard,
    LinkGridNavigationCardEditorSchema
} from '../components/summary-navigation/LinkGridNavigationCard';
import { TableComponent, TableComponentParams, TableComponentEditorSchema } from '../components/table/TableComponent';
import {
    UserDataTableComponent,
    UserDataTableComponentEditorSchema
} from '../components/spatial/UserDataTableComponent';
import { CardComponent, CardComponentParams, CardComponentEditorSchema } from '../components/cards/CardComponent';
import { CardHeaderComponent, CardHeaderComponentParams } from '../components/cards/CardHeaderComponent';
import {
    CollapsableCardComponent,
    CollapsableCardComponentParams,
    CollapsableCardComponentEditorSchema
} from '../components/cards/CollapsableCardComponent';
import { Separator, SeparatorParams, SeparatorEditorSchema } from '../components/generic/Separator';
import { BasicTitleComponent } from '../components/layout/main-layout/BasicTitleComponent';
import AdHocComponent from '../components/spatial/AdHocComponent';
import AdHocFilterComponent from '../components/spatial/AdHocFilterComponent';
import UserDataFilterComponent from '../components/spatial/UserDataFilterComponent';
import ExternalLinkDropdown from '../components/layout/navigation/ExternalLinkDropdown';
import {
    SummaryStatsFilterCard,
    SummaryStatsFilterCardEditorSchema
} from '../components/summary-navigation/SummaryStatsFilterCard';
import {
    RadioButtonGenericFilter,
    RadioButtonGenericFilterEditorSchema
} from '../components/summary-navigation/RadioButtonGenericFilter';
import CourseOfActionButtonComponent from '../components/generic/CourseOfActionButtonComponent';

const Visualizations = {
    CardComponent,
    CardHeaderComponent,
    CollapsableCardComponent,
    RepeatedChildComponent,
    TableComponent,
    SubpageSummaryNavigationComponent,
    PieChartComponent,
    LinkList,
    SummaryStatsNavigationCard,
    LinkGridNavigationCard,
    TwoHeadingButtonCard,
    DocumentDownloadButton,
    AlertBoxComponent,
    HorizontalBarChartComponent,
    VerticalBarChartComponent,
    TrendChartComponent,
    ProfileStyleCard,
    Separator,
    ScatterPlotComponent,
    OfficerInformationHeaderComponent,
    SharedFilterComponent,
    TextComponent,
    DotTextDateComponent,
    IconButtonComponent,
    CourseOfActionButtonComponent,
    IconButtonRowComponent,
    BasicTitleComponent,
    ExternalLinkDropdown,
    AdHocComponent,
    AdHocFilterComponent,
    UserDataFilterComponent,
    SummaryStatsFilterCard,
    RadioButtonGenericFilter,
    UserDataTableComponent
};

const VisualizationsParams = {
    TableComponentParams,
    CardComponentParams,
    CardHeaderComponentParams,
    CollapsableCardComponentParams,
    SeparatorParams
};

const EditorComponentsWithoutAPI = {
    SeparatorEditorSchema,
    CardComponentEditorSchema,
    LinkGridNavigationCardEditorSchema
};

const EditorComponentsWithAPI = {
    TwoHeadingButtonCardEditorSchema,
    DocumentDownloadButtonEditorSchema,
    SummaryStatsNavigationCardEditorSchema,
    HorizontalBarChartComponentEditorSchema,
    ProfileStyleCardEditorSchema,
    TrendChartComponentEditorSchema,
    UserDataTableComponentEditorSchema,
    TableComponentEditorSchema,
    CollapsableCardComponentEditorSchema,
    SummaryStatsFilterCardEditorSchema,
    RadioButtonGenericFilterEditorSchema
};

const ComponentsSupportingChildren = {
    CollapsableCardComponent,
    CardComponent,
    SharedFilterComponent,
    BasicTitleComponent
};

export {
    Visualizations,
    VisualizationsParams,
    ComponentsSupportingChildren,
    EditorComponentsWithAPI,
    EditorComponentsWithoutAPI
};
