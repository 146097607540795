import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './add-lato-regular-to-jspdf';
import './add-lato-italic-to-jspdf';

/**
 * @typedef {object} hotfixesOption
 * @property {string[]} hotfixes - https://github.com/MrRio/jsPDF/blob/master/HOTFIX_README.md
 * @typedef {import('jspdf').jsPDFOptions & hotfixesOption} PDFOptions
 *
 * @type PDFOptions
 */
const options = { format: 'letter', unit: 'px', hotfixes: ['px_scaling'] };

const createPdf = () => new jsPDF(options);

export default createPdf;
