import { useContext } from 'react';
import GridContext from '../../contexts/GridContext';
import { MAX_COL_SPAN } from '../../constants';

const GridSpanWrapper = ({ className, children }) => {
    const [colSpan, rowSpan] = useContext(GridContext);
    return (
        <div
            className={`relative ${
                colSpan === MAX_COL_SPAN ? 'col-span-full' : `md:col-span-${colSpan} sm:col-span-1`
            } ${rowSpan ? `row-span-${rowSpan}` : ''} ${className ? className : ''}`}>
            {children}
        </div>
    );
};

export default GridSpanWrapper;
