import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../states/LoadingSpinner';
import { numberFormat, replaceTemplateStrings } from '../../utilities';
import SummaryStatsNavigationCardEditorSchema from './SummaryStatsNavigationCardEditorSchema.json';
import EmptyState from '../states/EmptyState';

const SummaryStatsNavigationCard = ({ params, loading, data, pdfDelegate }) => {
    const {
        title,
        dynamicTitle,
        noDataTitle,
        descriptor = '',
        routeTo,
        field = 'total',
        format = 'number',
        text = '',
        disableHyperlink = false
    } = params;
    const value = !!data ? data[field] || 0 : 0;
    let txt = value;
    switch (format) {
        case 'text':
            txt = replaceTemplateStrings(text, data || {});
            break;
        case 'number':
            txt = numberFormat(value);
            break;
        case 'percent':
            txt = numberFormat(value, { maximumFractionDigits: 0 }) + ' %'; // percents use whole numbers only (as parseInt) per Tammy 3/23/21
            break;
        default:
    }

    const noData = !data || (Array.isArray(data) && data.length == 0);

    const renderedTitle =
        noDataTitle && noData
            ? noDataTitle
            : !!data && !!dynamicTitle
            ? Array.isArray(data) && data.length > 0
                ? replaceTemplateStrings(dynamicTitle, data[0])
                : replaceTemplateStrings(dynamicTitle, data)
            : title;

    const cursor = disableHyperlink ? 'cursor-default select-none' : 'cursor-pointer';

    useEffect(() => {
        if (pdfDelegate) {
            if (!loading && data) {
                const newline = { text: ' ', style: 'paragraph' };
                const descriptorContent = !!descriptor ? { text: descriptor, style: 'paragraph_italics' } : [];
                const contents = [
                    {
                        text: [renderedTitle + ':  ', { text: txt, style: 'paragraph_big_bold' }],
                        style: 'paragraph_big'
                    },
                    descriptorContent,
                    newline
                ];
                pdfDelegate?.setPdfChildCompleteFlag(contents);
            }
            if (!data && !loading) {
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
    }, [pdfDelegate]);

    if (!!loading) {
        const filledInDataHeight = 96;
        return (
            <div className="p-2">
                <div
                    className={'border border-main-accent rounded-xs-sm h-24 flex flex-col justify-center items-center'}
                    style={{ height: filledInDataHeight }}>
                    <LoadingSpinner size="medium" />
                </div>
            </div>
        );
    }

    if (!data) {
        const filledInDataHeight = 96;
        return (
            <div className="p-2">
                <div
                    className={
                        'border border-main-accent rounded-xs-sm h-24 flex flex-col justify-center items-center'
                    }>
                    <EmptyState height={filledInDataHeight} />
                </div>
            </div>
        );
    }

    return (
        <div className="p-2">
            <LinkWrapper
                disableHyperlink={disableHyperlink}
                to={disableHyperlink ? '' : routeTo}
                className={`border border-main-accent rounded-xs-sm h-24 flex flex-col justify-center items-center ${cursor}`}>
                <h2 className={!!disableHyperlink ? 'text-xs text-center' : 'text-xs text-link text-center'}>
                    {renderedTitle}
                </h2>
                <div className={`pt-3 ${descriptor ? 'pb-1' : ''}`}>
                    {!!loading ? <LoadingSpinner size="medium" /> : <h1 className="text-2xl">{txt}</h1>}
                </div>
                {descriptor ? (
                    <div className="text-xs">
                        <small>
                            <em>{descriptor}</em>
                        </small>
                    </div>
                ) : null}
            </LinkWrapper>
        </div>
    );
};

const LinkWrapper = ({ disableHyperlink, children, ...rest }) =>
    disableHyperlink ? <div {...rest}>{children}</div> : <Link {...rest}>{children}</Link>;

export { SummaryStatsNavigationCard, SummaryStatsNavigationCardEditorSchema };
