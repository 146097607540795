import { useContext, useMemo, useState, useEffect } from 'react';
import ErrorState from '../states/ErrorState';
import ComponentWrapperWithApiCall from './ComponentWrapperWithApiCall';
import GridContext from '../../contexts/GridContext';
import GridSpanWrapper from './GridSpanWrapper';
import { ComponentFiltersContext, ComponentFiltersProvider } from '../../contexts/ComponentFiltersContext';
import { Visualizations } from '../../utilities/visualization-lookup';
import { FiltersContext } from '../../contexts/FiltersContext';
import { determineElementVisibility } from '../../utilities';
import ComponentEditorOverlay from './ComponentEditorOverlay';
import { PDFProgressContext } from '../../contexts/PDFProgressContext';

const ChildrenWrapper = ({ childComponents, pdfDelegate, includeComponentFiltersProvider = true, defaultData }) => {
    const [pdfChildWritingIndex, setPdfChildWritingIndex] = useState(-1);
    const [pdfChildrenContents, setPdfChildrenContents] = useState([]);
    const [pdfChildCompleteFlag, setPdfChildCompleteFlag] = useState(false);
    const [pdfChildErrorFlag, setPdfChildErrorFlag] = useState(false);
    const { setPdfSubtreeComplete, setPdfSubtreeError, setPdfTitle, setOverrideTitlePageElement } = pdfDelegate || {};
    const { progressIncrement } = useContext(PDFProgressContext);

    const pdfDelegateConst = useMemo(() => {
        if (setPdfChildCompleteFlag && setPdfChildErrorFlag && setPdfTitle && setOverrideTitlePageElement) {
            return {
                setPdfChildCompleteFlag: (value) => {
                    progressIncrement();
                    setPdfChildCompleteFlag(value);
                },
                setPdfChildErrorFlag,
                setPdfTitle,
                setOverrideTitlePageElement
            };
        } else {
            return undefined;
        }
    }, [pdfDelegate, setPdfChildCompleteFlag, setPdfChildErrorFlag, setPdfTitle, setOverrideTitlePageElement]);

    useEffect(() => {
        if (!childComponents || childComponents.length === 0 || !pdfDelegate) {
            setPdfChildWritingIndex(-1);
            setPdfChildCompleteFlag(false);
            setPdfChildErrorFlag(false);
            setPdfChildrenContents(null);
            if (setPdfSubtreeComplete) {
                setPdfSubtreeComplete([]);
            }
            return;
        }
        if (pdfChildWritingIndex >= childComponents.length) {
            setPdfSubtreeComplete(pdfChildrenContents);
            return;
        }
        if (pdfChildCompleteFlag || childComponents[pdfChildWritingIndex]?.visible === false) {
            setPdfChildrenContents(
                (pdfChildrenContents || []).concat(
                    pdfChildCompleteFlag !== true && pdfChildCompleteFlag !== false ? pdfChildCompleteFlag : []
                )
            );
            setPdfChildCompleteFlag(false);
            setPdfChildWritingIndex(pdfChildWritingIndex + 1);
            return;
        }
        if (pdfChildWritingIndex === -1) {
            // start the engine
            setPdfChildCompleteFlag(true);
            return;
        }
        if (pdfChildErrorFlag) {
            setPdfSubtreeError(true);
            return;
        }
    }, [childComponents, pdfDelegate, pdfChildCompleteFlag, pdfChildErrorFlag, pdfChildWritingIndex]);

    return childComponents
        ? childComponents.map((options, index) => {
              const { colSpan = 12, key } = options;
              const pdfDelegate = pdfChildrenContents && index === pdfChildWritingIndex && pdfDelegateConst;
              const params = Object.assign({}, options, {
                  colSpan,
                  includeComponentFiltersProvider,
                  componentKey: key,
                  key: index,
                  pdfDelegate
              });
              return <ComponentInterpreter {...params} />;
          })
        : null;
};

const ComponentInterpreter = (options) => {
    const {
        children,
        component,
        params = {},
        defaultData,
        endpoint,
        colSpan,
        rowSpan,
        filters,
        visible,
        componentKey,
        includeComponentFiltersProvider = true,
        pdfDelegate
    } = options;

    const Component = Visualizations[component];

    if (!Component) {
        console.error(`${component} couldn't be found.`);
        return (
            <div className={`my-2 col-span-${colSpan} row-span-${rowSpan}`}>
                <ErrorState title="Error" text={`We couldn't find a suitable component. Please contact support.`} />
            </div>
        );
    }

    return (
        <GridContext.Provider value={[colSpan, rowSpan]}>
            {!!includeComponentFiltersProvider && (
                <ComponentFiltersProvider params={params} filters={filters}>
                    <ComponentRenderer
                        {...options}
                        {...{
                            componentKey,
                            component,
                            endpoint,
                            defaultData,
                            params,
                            children,
                            filters,
                            Component,
                            visible,
                            colSpan,
                            rowSpan,
                            pdfDelegate
                        }}
                    />
                </ComponentFiltersProvider>
            )}
            {!includeComponentFiltersProvider && (
                <ComponentRenderer
                    {...options}
                    {...{
                        componentKey,
                        component,
                        endpoint,
                        defaultData,
                        params,
                        children,
                        filters,
                        Component,
                        visible,
                        colSpan,
                        rowSpan,
                        pdfDelegate
                    }}
                />
            )}
        </GridContext.Provider>
    );
};

const ComponentRenderer = (props) => {
    const { endpoint, params, defaultData, children, filters, Component, visible, pdfDelegate } = props;
    const [{ filterSelections }] = useContext(FiltersContext);
    const [componentFilterSelections] = useContext(ComponentFiltersContext);
    const componentMeetsVisibilityConditions = useMemo(() => {
        const combinedSelections = { ...filterSelections, ...componentFilterSelections };
        return determineElementVisibility(visible, combinedSelections);
    }, [filterSelections, componentFilterSelections, visible]);

    if (!componentMeetsVisibilityConditions) {
        return null;
    }

    return (
        <GridSpanWrapper>
            <ComponentEditorOverlay {...{ ...props }} />
            {!endpoint?.url && (
                <Component
                    params={params}
                    data={defaultData}
                    defaultData={defaultData}
                    children={children}
                    filters={filters}
                    pdfDelegate={pdfDelegate}
                />
            )}
            {!!endpoint?.url && (
                <ComponentWrapperWithApiCall
                    {...{
                        endpoint,
                        defaultData,
                        Component,
                        params,
                        filters,
                        pdfDelegate,
                        children
                    }}
                />
            )}
        </GridSpanWrapper>
    );
};

export { ComponentInterpreter, ChildrenWrapper };
