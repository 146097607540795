import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { determineElementVisibility } from '../../utilities';

const SubpageSummaryNavigationComponent = ({ params }) => {
    const { title, buttons = [], paddingHorizontal = false, centered = false } = params;
    const buttonsToMap = useMemo(() => buttons.filter((btn) => determineElementVisibility(btn.visible)), [buttons]);
    return (
        <div className={`${paddingHorizontal ? 'px-4 lg:px-6' : ''} lg:py-4`}>
            {!!title && <h2 className="text-sm pb-6">{title}</h2>}
            <div className="flex flex-col md:flex-row md:flex-wrap">
                {buttonsToMap.map(({ title, routeTo }, key) => (
                    <div
                        className={`flex-1 m-0.5 items-center ${centered ? 'flex items-center justify-center' : ''}`}
                        key={key}>
                        <Link
                            to={routeTo}
                            className="w-full md:w-40 block my-1 md:my-0 rounded-xs-sm border border-accent h-8 py-2 px-4 text-center text-link text-xs">
                            {title}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SubpageSummaryNavigationComponent;
