import { useMemo } from 'react';
import { getFilterComponent } from '../../../utilities';

const PageFilters = ({ filters }) => {
    const availableFiltersForPage = useMemo(
        () => (filters ? filters.map((filter) => getFilterComponent(filter)) : []),
        [filters]
    );
    return (
        <div
            className="lg:h-20 flex flex-col lg:flex-row lg:items-center bg-main-accent"
            style={{ minHeight: '80px', flexShrink: 0 }}>
            {availableFiltersForPage.map((FilterComponent, index) => (
                <div className="p-2 lg:p-0 lg:pl-6" key={index}>
                    <FilterComponent />
                </div>
            ))}
        </div>
    );
};

export default PageFilters;
