import GridWrapper from './GridWrapper';
import { ChildrenWrapper } from './ComponentInterpreter';
import { useMemo, useEffect } from 'react';
import { getFilterComponent } from '../../utilities';

const SharedFilterComponent = ({ params, children, loading, pdfDelegate }) => {
    const { filtersToShow = [] } = params;
    const availableFiltersForPage = useMemo(() => filtersToShow.map((filter) => getFilterComponent(filter)), [
        filtersToShow
    ]);

    useEffect(() => {
        if (pdfDelegate && !loading) {
            pdfDelegate?.setPdfChildCompleteFlag(true);
        }
    }, [pdfDelegate, loading]);

    return (
        <>
            <div className="mb-4 mx-1">
                {availableFiltersForPage.map((Filter, key) => (
                    <Filter key={key} useComponentState={true} />
                ))}
            </div>
            <GridWrapper isSubcomponent={true}>
                <ChildrenWrapper childComponents={children} includeComponentFiltersProvider={false} />
            </GridWrapper>
        </>
    );
};

export default SharedFilterComponent;
