import { Skeleton } from 'antd';
import { useMemo } from 'react';

const OfficerInformationHeaderComponent = ({ data, error, loading }) => {
    const size = 'small';
    const active = true;
    const person = useMemo(() => {
        if (data) {
            const person = data[0];
            person.initials = person.officer_full_name
                .split(' ')
                .map((n) => n[0])
                .join('');
            return person;
        }
        return {};
    }, [data]);

    return (
        <div className="flex items-center space-x-6">
            <LoadingWrapper
                loading={loading}
                fallback={
                    <div className="rounded-full bg-link h-16 w-16 text-2xl text-white font-bold flex items-center justify-center">
                        {person.initials}
                    </div>
                }>
                <Skeleton.Avatar active={active} size={size} shape="circle" className="h-16 w-16" />
            </LoadingWrapper>
            <div className="py-5">
                <LoadingWrapper loading={loading} fallback={<h1 className="officer-name">{person.officer_full_name}</h1>}>
                    <Skeleton.Input style={{ width: 200, height: 28 }} active={active} size={size} />
                </LoadingWrapper>

                <LoadingWrapper
                    loading={loading}
                    fallback={<h4 className="officer-role">{person.title}</h4>}>
                    <Skeleton.Input
                        style={{ width: 50, height: 14 }}
                        active={active}
                        size={size}
                        className="my-1 block"
                    />
                </LoadingWrapper>

                <div className="mt-6 flex items-center space-x-20">
                    <div>
                        <h4 className="text-xs opacity-60">Star/Badge Number:</h4>
                        <LoadingWrapper
                            loading={loading}
                            fallback={<h3 className="text-xs font-bold">{person.badge_number}</h3>}>
                            <Skeleton.Input style={{ width: 75, height: 14 }} active={active} size={size} />
                        </LoadingWrapper>
                    </div>
                    <div>
                        <h4 className="text-xs opacity-60">Organization:</h4>
                        <LoadingWrapper
                            loading={loading}
                            fallback={
                                <h3 className="text-xs font-bold">
                                    {person.organization_name} {person.organization_code}
                                </h3>
                            }>
                            <Skeleton.Input style={{ width: 200, height: 14 }} active={active} size={size} />
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LoadingWrapper = ({ loading, fallback, children }) => (!!loading ? children : fallback);

export default OfficerInformationHeaderComponent;
