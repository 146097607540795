import { useEffect } from 'react';
import { ChildrenWrapper } from '../../generic/ComponentInterpreter';

const BasicTitleComponent = ({ params, children, pdfDelegate }) => {
    const { title } = params;

    useEffect(() => {
        if (pdfDelegate) {

            pdfDelegate?.setPdfChildCompleteFlag(true);
        }
    }, [pdfDelegate]);
    return (
        <div className={'flex items-center w-full'}>
            <div className="flex-1">
                <h1 className="text-2xl">{title}</h1>
            </div>
            <div className="text-right">
                <ChildrenWrapper childComponents={children} />
            </div>
        </div>
    );
};

export { BasicTitleComponent };
