import { useState, useEffect, useContext } from 'react';
import { COLOR_FUNCTIONS } from '../../constants/index';
import LoadingSpinner from '../states/LoadingSpinner';
import { numberFormat, replaceTemplateStrings } from '../../utilities';
import SummaryStatsFilterCardEditorSchema from './SummaryStatsFilterCardEditorSchema.json';
import { InfoCircleOutlined } from '@ant-design/icons';
import EmptyState from '../states/EmptyState';
import { FiltersContext, FiltersActions } from '../../contexts/FiltersContext';
import { Filters } from '../../constants';
import { Tooltip } from 'antd';

const SummaryStatsFilterCard = ({ params, loading, data }) => {
    const {
        title,
        field = 'total',
        format = 'number',
        text = '',
        toolTip = '',
        colorFunction,
        colorInput,
        defaultFilter = false,
        filterField = '',
        filterValue = ''
    } = params;
    const [active, setActive] = useState(false);
    const [{ filterSelections }, dispatch] = useContext(FiltersContext);

    const colorScheme = (
        COLOR_FUNCTIONS[colorFunction] ||
        (() => {
            return undefined;
        })
    )(colorInput);

    useEffect(() => {
        if (filterSelections) {
            if (filterSelections[Filters.SUMMARY_STATS_CARD]) {
                const currentFilterValue = filterSelections[Filters.SUMMARY_STATS_CARD].filterValue;
                setActive(currentFilterValue === filterValue);
            } else {
                dispatch({
                    type: FiltersActions.SET_FILTER_STATE,
                    payload: {
                        filterId: Filters.SUMMARY_STATS_CARD,
                        value: {
                            filterField,
                            stats_navigation_filter: defaultFilter ? filterValue : '',
                            filterValue: defaultFilter ? filterValue : ''
                        }
                    }
                });
            }
        }
    }, [filterSelections]);

    const value = !!data ? data[field] || 0 : 0;

    let txt = value;

    switch (format) {
        case 'text':
            txt = replaceTemplateStrings(text, data || {});
            break;
        case 'number':
            txt = numberFormat(value);
            break;
        case 'percent':
            txt = numberFormat(value, { maximumFractionDigits: 0 }) + ' %';
            break;
        default:
    }

    const handleClickFilter = () => {
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: {
                filterId: Filters.SUMMARY_STATS_CARD,
                value: {
                    filterField,
                    stats_navigation_filter: filterValue,
                    filterValue
                }
            }
        });

        setActive(true);
    };

    const handleTooltipClickPropagation = (event) => {
        event.stopPropagation();
    };

    if (!!loading) {
        return (
            <div className="flex relative flex-col justify-around align-middle w-auto h-24 border-b-2 border-transparent">
                <LoadingSpinner size="medium" />
            </div>
        );
    }

    if (!data) {
        return (
            <div className="flex relative flex-col justify-around align-middle w-auto h-24 border-b-2 border-transparent">
                <EmptyState />
            </div>
        );
    }

    return (
        <div
            onClick={handleClickFilter}
            className={`flex relative flex-col justify-around align-middle w-auto h-24 cursor-pointer border-b-4 ${
                active ? `border-blue-500` : 'border-transparent'
            }`}>
            <h1 className={`text-center text-2xl`}>
                <span
                    className={`px-4 py-3 rounded border-2`}
                    style={{
                        ...(colorScheme
                            ? {
                                  'border-color': colorScheme['primary'],
                                  backgroundColor: colorScheme['secondary'],
                                  color: colorScheme['text']
                              }
                            : {}),
                        fontWeight: 500
                    }}>
                    {txt}
                </span>
            </h1>
            <span
                className={`text-center normal-text whitespace-nowrap ${
                    active ? `text-blue-800` : `text-gray-600`
                }`}>
                {title}
                {toolTip ? (
                    <div className="relative inline-block" onClick={handleTooltipClickPropagation}>
                        <Tooltip title={toolTip} placement="right" trigger={['click', 'hover']}>
                            <InfoCircleOutlined className="ml-1.5" />
                        </Tooltip>
                    </div>
                ) : null}
            </span>
        </div>
    );
};

export { SummaryStatsFilterCard, SummaryStatsFilterCardEditorSchema };
