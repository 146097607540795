import { useContext, useEffect } from 'react';
import { Filters } from '../../../constants';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { Select } from 'antd';
const { Option } = Select;

const AdHocApisFilter = ({ title }) => {
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);
    const url = filterSelections[Filters.ADHOC];
    const apis = filterOptions[Filters.ADHOC] || [];

    useEffect(() => {
        dispatchNewSelection(url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    const dispatchNewSelection = (newUrl) => {
        if (!newUrl) {
            return;
        }
        if (newUrl === url) {
            return;
        }
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: {
                filterId: Filters.ADHOC,
                value: newUrl || ''
            }
        });
    };
    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">{!title ? 'Data Type' : title}</h3>
            <Select
                id="adhocapis"
                placeholder="Select data source"
                style={{ width: 225 }}
                onSelect={(value) => {
                    dispatchNewSelection(value);
                }}
                dropdownMatchSelectWidth={500}
                value={url}>
                {apis.map((option, index) => {
                    return (
                        <Option key={index} value={option.url}>
                            {option.label}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

export default AdHocApisFilter;
