const ErrorState = ({ encompassPage = false, title, text, safetyLinkText, safetyLinkURL }) => (
    <div className={`min-w-screen w-full ${encompassPage ? 'h-full' : ''} flex flex-row items-center justify-center`}>
        <div className="text-center flex flex-col items-center my-1">
            <svg
                className="text-center h-32 w-32 text-inactive-tab"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
            {!!title && <h1 className="font-medium text-lg opacity-80 mt-4">{title}</h1>}
            {!!text && <h2 className="font-medium text-md opacity-50 mt-2">{text}</h2>}
            {!!safetyLinkText && !!safetyLinkURL && (
                <a href={safetyLinkURL}>
                    <h2 className="font-medium text-md opacity-50 mt-2">{safetyLinkText}</h2>
                </a>
            )}
        </div>
    </div>
);

export default ErrorState;
