const statusPayload = {
    environment: process.env.REACT_APP_ENVIRONMENT_NAME || process.env.NODE_ENV || 'unknown',
    current_branch: process.env.REACT_APP_CURRENT_BRANCH || 'unknown',
    current_commit: process.env.REACT_APP_CURRENT_COMMIT || 'unknown',
    current_commit_message: process.env.REACT_APP_CURRENT_COMMIT_MESSAGE || 'unknown',
    build_time: process.env.REACT_APP_BUILD_TIME || 'unknown',
    started: process.env.REACT_APP_START_TIME || 'unknown',
    version: process.env.REACT_APP_VERSION || 'unknown'
};

function Status() {
    return <code>{JSON.stringify(statusPayload)}</code>;
}

export default Status;
