import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useStatsApi } from '../hooks/useApi';
import { USER_DATA_SEARCH_ENDPOINT } from '../constants/index';

const UserDataActions = {
    SELECT: 'SELECT',
    SEARCH_FILTER: 'SEARCH_FILTER',
    FILTER_LIMITS: 'FILTER_LIMITS',
    UPDATE_DATA: 'UPDATE_DATA',
    UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',
    RESET_SELECT: 'RESET_SELECT',
    RESET_SELECT_BY_NAME: 'RESET_SELECT_BY_NAME',
    SET_LOADING: 'SET_LOADING',
    GENERIC_TABLE_SEARCH: 'GENERIC_TABLE_SEARCH',
    PAGINATION: 'PAGINATION',
    FORCE_RESET_PAGE: 'FORCE_RESET_PAGE',
    SORTING: 'SORTING'
};

const INIT_STATE = {
    loading: false,
    data: [],
    filters: [],
    selected: {},
    filter_searches: {},
    filter_limits: {},
    pagination: { page: 0, pageSize: 50, forceResetPage: false },
    sorting: {},
    generic_table_search: {}
};

const UserDataContext = createContext(null);

const reducer = (state = {}, action) => {
    const { type, payload = {} } = action;

    switch (type) {
        case UserDataActions.UPDATE_DATA:
            return {
                ...state,
                loading: false,
                ...payload
            };
        case UserDataActions.UPDATE_QUERY_PARAMS:
            return {
                ...state,
                query: payload
            };
        case UserDataActions.RESET_SELECT:
            return {
                ...state,
                selected: {}
            };
        case UserDataActions.RESET_SELECT_BY_NAME: {
            const { name } = payload;

            return {
                ...state,
                selected: { ...state.selected, [name]: {} }
            };
        }
        case UserDataActions.SELECT: {
            const { name, value, checked } = payload;

            const selection = (state && state.selected[name]) || {};

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    forceResetPage: true
                },
                selected: {
                    ...state.selected,
                    [name]: {
                        ...selection,
                        [value]: checked
                    }
                }
            };
        }
        case UserDataActions.FILTER_SEARCHES: {
            const { name, value } = payload;

            return {
                ...state,
                filter_searches: {
                    ...state.filter_searches,
                    [name]: value
                }
            };
        }
        case UserDataActions.GENERIC_TABLE_SEARCH: {
            return {
                ...state,
                generic_table_search: payload
            };
        }
        case UserDataActions.FILTER_LIMITS: {
            const { name, value } = payload;

            return {
                ...state,
                filter_limits: {
                    ...state.filter_limits,
                    [name]: value
                }
            };
        }
        case UserDataActions.PAGINATION: {
            const { page, pageSize } = payload;
            return { ...state, loading: true, pagination: { page, pageSize, forceResetPage: false } };
        }
        case UserDataActions.SORTING: {
            const { field, order } = payload;
            return { ...state, loading: true, sorting: { field, order } };
        }
        case UserDataActions.SET_LOADING:
            return { ...state, loading: true };
        default:
            return state;
    }
};

const UserDataContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, INIT_STATE);
    const { selected, filter_searches, filter_limits, pagination, sorting, generic_table_search } = state;
    const url = USER_DATA_SEARCH_ENDPOINT;
    const queryParams = {
        ...filter_searches,
        ...filter_limits,
        ...generic_table_search
    };

    Object.keys(selected).forEach((s) => {
        const vals = Object.keys(selected[s]).filter((k) => selected[s][k]);
        queryParams[s] = vals.join('|');
    });

    if (pagination) {
        const { page, pageSize } = pagination;
        queryParams.page = page;
        queryParams.page_size = pageSize;
    }

    if (sorting) {
        const { field, order } = sorting;
        if (field && order) {
            queryParams.sort_field = field;
            queryParams.sort_order = order;
        }
    }

    const [results, loading] = useStatsApi({ url, queryParams });

    const queryParamsJson = JSON.stringify(queryParams);

    useEffect(() => {
        dispatch({ type: UserDataActions.UPDATE_QUERY_PARAMS, payload: queryParams });
    }, [queryParamsJson]);

    useEffect(() => {
        if (loading) {
            dispatch({ type: UserDataActions.SET_LOADING });
        }
    }, [loading]);

    useEffect(() => {
        dispatch({ type: UserDataActions.UPDATE_DATA, payload: results });
    }, [results, dispatch]);

    return <UserDataContext.Provider value={[state, dispatch]}>{children}</UserDataContext.Provider>;
};

export { UserDataContext, UserDataActions, UserDataContextProvider };
