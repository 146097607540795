import { useEffect } from 'react';

import { Button } from 'antd'
import { UserOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

import LoadingSpinner from '../states/LoadingSpinner';
import { replaceTemplateStrings } from '../../utilities';
import EmptyState from '../states/EmptyState';
import TwoHeadingButtonCardEditorSchema from './TwoHeaddingButtonCardEditorSchema.json';
import * as moment from 'moment';

/*
  "params": {
    "headingText": "${officer_full_name}",
    "subHeadingText": "Badge #${badge_number} | ${title} | ${organization_name}",
    "buttonText": "Officer Profile",
    "buttonLink": "/organization-overview/officer?organization=${organization_id}&officer=${id}",
    "routeTo": "/incidents/uof-summary/details"
  }
*/

const TwoHeadingButtonCard = ({ params, loading, data, pdfDelegate }) => {
    let {
        routeTo,
        headingText = '',
        subHeadingText = '',
        subHeadingItems = null,
        subHeadingItemsSeparator = '',
        buttonText = '',
        buttonLink = '',
        disableHyperlink = false
    } = params;

    disableHyperlink = disableHyperlink || (!routeTo || routeTo.length == 0)
    if (Array.isArray(data) && data.length > 0) {
        data = data[0];
    }

    const fmtSubHeadingItems = subHeadingItems && subHeadingItems.map((item) => {
        try {
            return replaceTemplateStrings(item, data || {}, true, {}, true)
        } catch (ex) {
            return null
        }
    }).filter((item) => { return !!item }).join(subHeadingItemsSeparator)

    const fmtHeadingText = replaceTemplateStrings(headingText, data || {});
    const fmtSubHeadingText = fmtSubHeadingItems || replaceTemplateStrings(subHeadingText, data || {});
    const fmtButtonText = replaceTemplateStrings(buttonText, data || {});
    const fmtButtonLink = replaceTemplateStrings(buttonLink, data || {});

    const cursor = disableHyperlink ? 'cursor-default select-none' : 'cursor-pointer';
    useEffect(() => {
        if (pdfDelegate && !loading) {
            if (data) {
                pdfDelegate?.setPdfTitle(fmtHeadingText);
                pdfDelegate?.setOverrideTitlePageElement({
                    subtitle: { text: fmtHeadingText, style: 'subtitle' },
                    subtitle2: { text: fmtSubHeadingText, style: 'subtitle' }
                });
                pdfDelegate?.setPdfChildCompleteFlag(true);
            } else {
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
    }, [pdfDelegate, loading, data]);

    if (!!loading) {
        const filledInDataHeight = 64;
        return (
            <div className={'flex justify-center items-center w-full'} style={{ height: filledInDataHeight }}>
                <LoadingSpinner size="medium" />
            </div>
        );
    }

    if (!data) {
        const filledInDataHeight = 128;
        return <EmptyState height={filledInDataHeight} />;
    }

    return (
        <div className={'flex items-center w-full'}>
            <div className="p-2 flex-1 w-full">
                <LinkWrapper
                    disableHyperlink={disableHyperlink}
                    to={disableHyperlink ? '' : routeTo}
                    className={`rounded-xs-sm flex flex-col justify-center ${cursor}`}>
                    <div className="pb-3">
                        <h2>{fmtHeadingText}</h2>
                    </div>
                    {!loading && <h3>{fmtSubHeadingText}</h3>}
                </LinkWrapper>
            </div>
            <div className={`m-0.5 items-center flex items-center justify-center'}`}>
                <Button
                    type="secondary"
                    shape="round"
                    size="medium"
                    icon={<UserOutlined />}
                    onClick={() => { window.location.href=fmtButtonLink }}>
                    {fmtButtonText}
                </Button>
            </div>
        </div>
    );
};

const LinkWrapper = ({ disableHyperlink, children, ...rest }) =>
    disableHyperlink ? <div {...rest}>{children}</div> : <Link {...rest}>{children}</Link>;

export { TwoHeadingButtonCard, TwoHeadingButtonCardEditorSchema };
