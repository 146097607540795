import { useMemo, useState, useCallback, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { MAIN_CHART_COLORS } from '../../constants';
import LoadingSpinner from '../states/LoadingSpinner';
import ErrorState from '../states/ErrorState';
import EmptyState from '../states/EmptyState';
import {returnSortedData, transformDataIndexField} from '../../utilities';




const PieChartComponent = ({ data, loading, error, params, endpoint, pdfDelegate }) => {
    const { 
        name, 
        value, 
        height = 250, 
        isAnimationActive=true,
        includePieLabel=false,
        sortBy=null
    } = params;

    const sortByObject = (sortBy !== null) ? sortBy : {
        "field": value,
        "fieldType": "number",
        "order": "descending"
      }

    const validatedData = useMemo(() => {
        if (data) {
            const hasValidDataPoints = !!data.filter((item) => !!item[value]).length;
            return hasValidDataPoints ? data : [];
        }
        return null;
    }, [data]);

    const sortedData = useMemo(() => {
        try { 
            const sorted = returnSortedData(sortByObject, transformDataIndexField(validatedData, value))
            return sorted;
        } catch(err) {
            console.log(`Error in sorting data... ${err}`);
            return validatedData
        }
    }, [validatedData]);
    

    // useCurrentPng usage (isLoading is optional)
    const [getPng, { ref, isLoading }] = useCurrentPng();
    const [png64, setPng64] = useState(null);
    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            // FileSaver.saveAs(png, 'myChart.png');
            const image = png.toString('base64');
            setPng64(image);
        } else {
            if (pdfDelegate) {
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
    }, [getPng]);

    const PieChartCustomizedLabelLine = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        if (!includePieLabel) { return false }
        if (percent > .20 || percent < .02) { return false }
        
        const RADIAN = Math.PI / 180;

        // eslint-disable-next-line
        let radius1 = 20 + innerRadius + (outerRadius - innerRadius);

        let radius2 = innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        let x2 = cx + radius1 * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        let y2 = cy + radius1 * Math.sin(-midAngle * RADIAN);

        let x1 = cx + radius2 * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        let y1 = cy + radius2 * Math.sin(-midAngle * RADIAN);

        if (value<30){
            return null;
        }

        return(
            <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="#ccc" strokeWidth={1}>

            </line>
        )
    };
    const PieChartCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const RADIAN = Math.PI / 180;
        const innerLabel = (percent > .20)
        const radius = innerLabel ? innerRadius + (outerRadius - innerRadius)/5 : outerRadius + 40;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const fill = (innerLabel) ? "#fff" : "#000"

        if (includePieLabel === false) {return null}
        if (percent < .02) {return null}
    
        return (
            <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{fontSize: 14}}>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    useEffect(() => {
        console.log('pdfDelegate, loading, data: ', pdfDelegate, loading, data);
        if (pdfDelegate && !loading && !!data) {
            if (png64) {
                pdfDelegate?.setPdfChildCompleteFlag([
                    {
                        image: png64,
                        fit: [400, 600]
                        // width: 300,
                        // height: 300
                    },
                    { text: '\n', style: 'paragraph' }
                ]);
                setPng64(null);
                return;
            }
            if (png64 === false) {
                console.error('png conversion failed');
                setPng64(null);
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
            if (data) {
                if (!validatedData.length) {
                    pdfDelegate?.setPdfChildCompleteFlag([{ text: 'No chart data available\n', style: 'paragraph' }]);
                } else {
                    handleDownload();
                }
            } else {
                console.log('setting child error flag');
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
        if (!pdfDelegate) {
            setPng64(null);
            return;
        }
        if (!loading && !data) {
            pdfDelegate?.setPdfChildErrorFlag(true);
        }
    }, [pdfDelegate, loading, data, png64]);

    if (!endpoint) {
        return <ErrorState title="Error" text="No endpoint specified." />;
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center" style={{ height }}>
                <LoadingSpinner size="large" />
            </div>
        );
    }

    if (error) {
        return <ErrorState title="Error" text="We encountered an error while attempting to load the chart data" />;
    }

    if (!validatedData.length) {
        return <EmptyState height={height} />;
    }

    return (
        <ResponsiveContainer width="99%" height={height}>
            <PieChart margin={{ bottom: 0 }} ref={ref}>
                <Pie
                    dataKey={value}
                    nameKey={name}
                    data={sortedData}
                    innerRadius={50}
                    outerRadius={85}
                    fill="#8884d8"
                    isAnimationActive={isAnimationActive}
                    labelLine={PieChartCustomizedLabelLine}
                    label={PieChartCustomizedLabel}>
                    {data.map((entry, index) => (
                        <Cell key={index} fill={MAIN_CHART_COLORS[index % MAIN_CHART_COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip
                    wrapperStyle={{
                        fontSize: 12,
                        color: 'rgba(0,0,0,0.65)'
                    }}
                />
                <Legend
                    iconSize={9}
                    iconType="circle"
                    wrapperStyle={{
                        fontSize: 10,
                        color: 'rgba(0,0,0,0.65)',
                        lineHeight: 1.5
                    }}
                    align="center"
                    layout="horizontal"
                    verticalAlign="bottom"
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PieChartComponent;
