import StyledLink from '../../links/StyledLink';
import React from 'react';
import '../../../assets/styles/navMenu.css';

const HoverMenu = ({ module, path, closeTab }) => {
    return (
        <div className={'dropdown-content dropdown-content-show'}>
            <div className="dropdown-content-module dropdown-content-module-main">
                <StyledLink to={'/' + path} className="no-underline" onClick={closeTab}>
                    <p class="title text-lg text-black">{module.shortTitle}</p>
                </StyledLink>
                <p />
                <p class="description text-xs text-gray-400">{module.description}</p>
            </div>
            {Object.keys(module.children)
                .sort((childPath1, childPath2) => {
                    return module.children[childPath1].order - module.children[childPath2].order;
                })
                .map((childPath) => {
                    const childModule = module.children[childPath];
                    return (
                        <div class="dropdown-content-module" key={childPath}>
                            <StyledLink to={'/' + path + '/' + childPath} className="no-underline">
                                <p class="title text-sm text-black">{childModule.shortTitle}</p>
                            </StyledLink>
                            {Object.keys(childModule.children).map((grandchildPath) => {
                                const grandchildModule = childModule.children[grandchildPath];
                                return (
                                    <StyledLink
                                        to={'/' + path + '/' + childPath + '/' + grandchildPath}
                                        key={grandchildPath}
                                        className="no-underline">
                                        <p class="text-xs text-gray-400">{grandchildModule.shortTitle}</p>
                                    </StyledLink>
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
};

export default HoverMenu;
