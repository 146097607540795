import React, { createRef, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import classes from './TableComponent.module.css';
import { Button, Input, Space, Table } from 'antd';
import { Filters, PAGINATION_CONFIGURATION } from '../../constants';
import { ComponentFiltersContext } from '../../contexts/ComponentFiltersContext';
import { useStatsApi } from '../../hooks/useApi';
import { FiltersContext } from '../../contexts/FiltersContext';
import { UserDataActions, UserDataContext } from '../../contexts/UserDataContext';
import * as moment from 'moment';
import {
    determineElementVisibility,
    fetchStatsApi,
    makeCSV,
    numberFormat,
    returnGroupedData,
    returnSortedData,
    uppercaseWordsInSentence
} from '../../utilities';
import { getRenderer } from '../../utilities/renderer';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import LoadingSpinner from '../states/LoadingSpinner';
import TableComponentParams from './TableComponentParams.json';
import TableComponentEditorSchema from './TableComponentEditorSchema.json';
import EmptyState from '../states/EmptyState';

/**
 * TableComponent - doc
 * Configuration format
 * {
 *    endpoint: 'report/get_the_table',  // BE API which returns rows used as dataSource
 *    component: 'TableComponent',       // Component name
 *    params: {
 *      title: 'Title',     // Optional. If ommited - no title
 *      searchOff: false,   // Optional. If ommited - the search bar is ON
 *      bordered: true,     // Optional. If ommited - no border around cells
 *      defaultPageSize: true,     // Optional. If ommited - defaults to table page size of 10
 *      columns: [
 *        {
 *          title: "Organization",          // title on header
 *          dataIndex: "organization_name", // name of field in dataSource record
 *          sorter: 'string'                // Optional. Value one of : 'string', 'number'
 *        },
 *        ...
 *      ]
 *    }
 *  }
 */

const getSorter = (type, field) => {
    switch (type) {
        case 'string':
            return (a, b) => {
                const str1 = a[field],
                    str2 = b[field];
                return str1 === str2 ? 0 : str1 > str2 ? 1 : -1;
            };
        case 'number':
            return (a, b) => {
                const num1 = parseFloat(a[field]) || 0,
                    num2 = parseFloat(b[field]) || 0;
                return num1 - num2;
            };
        case 'date':
            return (a, b) => {
                const utcDate1 = new Date(a[field]).getTime() || 0;
                const utcDate2 = new Date(b[field]).getTime() || 0;
                return utcDate1 - utcDate2;
            };
        case true:
            return true;
        default:
    }
    return null;
};

function TableComponent({ endpoint, data = [], currentFilters, params, loading, modifiedFilters = {}, pdfDelegate }) {
    const {
        title,
        showFilterDetails = false,
        columns = [],
        exportAsCsv = false,
        exportFileName = 'table-export',
        exportFileNameDisableDateRange = false,
        exportAsPdfBypassPagination = false,
        csvTitle = '',
        showHeader = true,
        rowDividers = true,
        bordered = false,
        showTotal = false,
        searchOff = false,
        scrollXWidth = true,
        scrollYWidth,
        timePeriodFormat = 'MM/DD/YYYY',
        defaultPageSize = PAGINATION_CONFIGURATION.defaultPageSize,
        backendPaginated: isBackendPaginated = false,
        paginationTotal: assignedPaginationTotal = undefined,
        paginationTotalEndpoint,
        paginationTotalField = 'total',
        paginationEnabled = true,
        sortBy,
        groupBys,
        overrideClassName = ''
    } = params;
    const [{ filterSelections }] = useContext(FiltersContext);

    const [componentFilterState, setFilterState] = useContext(ComponentFiltersContext);
    const [userDataState, dispatchUserDataState] = useContext(UserDataContext) || [{}, () => { }];
    const searchFilterState = componentFilterState[Filters.GENERIC_TABLE_SEARCH];
    const removeNonApplicableFilters = (filterSelections = {}, pageSpecificFilters = []) => {
        const newFilters = {};
        const selections = Object.keys(filterSelections);
        selections.forEach((selection) =>
            pageSpecificFilters.includes(selection) ? (newFilters[selection] = filterSelections[selection]) : null
        );
        return newFilters;
    };
    const actualFilterSelections = {
        ...removeNonApplicableFilters(filterSelections, [...Object.keys(modifiedFilters), 'timePeriod']),
        [Filters.GENERIC_TABLE_SEARCH]: {
            ...(filterSelections[Filters.GENERIC_TABLE_SEARCH] || {}),
            ...searchFilterState
        }
    };
    const [unpaginatedData, unpaginatedDataLoading, unpaginatedDataError] = useStatsApi(
        pdfDelegate ? endpoint?.url : {},
        currentFilters,
        'GET',
        null,
        { skip_pagination: true }
    );
    const [paginationTotal] = useStatsApi(paginationTotalEndpoint, actualFilterSelections);
    const [search, updateSearch] = useState('');
    const [loadingExport, setLoadingExport] = useState(false);

    const pagination = useMemo(() => {
        const filter = componentFilterState[Filters.PAGINATION];
        return {
            ...PAGINATION_CONFIGURATION,
            defaultPageSize,
            ...filter,
            ...(filter && filter.page === 0 ? { current: 1 } : undefined),
            ...(paginationTotal && {
                total: paginationTotal[paginationTotalField]
            }),
            // If no paginationTotalEndpoint, but paginationTotalField is set: it means total field located in the data (every row)
            ...(!paginationTotal && paginationTotalField && { total: data[0] ? data[0][paginationTotalField] : 0 }),
            ...(assignedPaginationTotal && { total: assignedPaginationTotal })
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentFilterState, paginationTotal, data]);

    function doFormat(data) {
        if (data) {
            let filteredData = data;

            const groupedData = returnGroupedData(groupBys, filteredData, {
                ...componentFilterState,
                ...filterSelections
            });
            const sortedData = returnSortedData(sortBy, groupedData);
            if (!Array.isArray(sortedData)) {
                return [];
            }
            return sortedData.map((item) => {
                const newItem = {};
                Object.keys(item).forEach((key) => {
                    const column = columns.filter((column) => column['dataIndex'] === key)[0];
                    if (column) {
                        const { formatType, formatOption } = column;
                        switch (formatType) {
                            case 'moment':
                                if (item[key] && item[key].length > 0) {
                                    newItem[key] = moment(item[key]).format(formatOption);
                                } else {
                                    newItem[key] = '';
                                }
                                break;
                            case 'capitalize':
                                newItem[key] = uppercaseWordsInSentence(item[key]);
                                break;
                            default:
                                newItem[key] = item[key];
                        }
                    } else {
                        newItem[key] = item[key];
                    }
                });
                return newItem;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const formattedData = useMemo(() => {
        return doFormat(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, columns, componentFilterState, filterSelections]);

    const getColumnSearchProps = (dataIndex, title, searchFilterQueryParam) => {
        const searchInput = createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={searchInput}
                            placeholder={`Search by ${title}`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, searchFilterQueryParam)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, searchFilterQueryParam)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => handleReset(clearFilters, searchFilterQueryParam)}
                                size="small"
                                style={{ width: 90 }}>
                                Reset
                            </Button>
                        </Space>
                    </div>
                );
            },
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#666c72' }} />,
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput?.current?.select(), 100);
                }
            }
        };
    };

    function doFilter(formattedData) {
        return search.length < 2
            ? formattedData
            : formattedData.filter((d) => {
                return JSON.stringify(d).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
            });
    }

    const filteredData = doFilter(formattedData);

    const columnsDef = useMemo(
        () =>
            columns
                .filter(({ visible }) =>
                    determineElementVisibility(visible, { ...actualFilterSelections, ...componentFilterState })
                )
                .filter(({ optionalColumn, dataIndex }) => {
                    if (optionalColumn) {
                        // first or any other element that gets DTO'd
                        const firstDataElement = filteredData[0];

                        if (firstDataElement) {
                            // TODO: add or change conditions in the columns definition to make it reusable
                            return firstDataElement[dataIndex] != null && firstDataElement[dataIndex] != undefined;
                        }
                    } else {
                        return true;
                    }
                })
                .map((c) => {
                    const {
                        title = '',
                        key,
                        dataIndex,
                        sortIndex,
                        render,
                        csvDataIndex,
                        sorter,
                        children = [],
                        fixed,
                        width,
                        pdfTableColumnWidth,
                        renderer,
                        searchFilterQueryParam,
                        displayTotal,
                        displayTotalAs = 'number'
                    } = c;
                    const currentFilterState = actualFilterSelections[Filters.GENERIC_TABLE_SEARCH];
                    const hasFilterApplied = currentFilterState && currentFilterState[searchFilterQueryParam];
                    return {
                        title,
                        dataIndex,
                        render,
                        csvDataIndex,
                        sorter: getSorter(sorter, sortIndex || dataIndex),
                        fixed,
                        width,
                        pdfTableColumnWidth,
                        key,
                        displayTotal,
                        displayTotalAs,
                        // Children may also have templates aka 'render'
                        // TODO: filter/sort/total etc should be handled for children same as top level columns
                        children: children.length
                            ? children
                                .filter(({ visible }) =>
                                    determineElementVisibility(visible, {
                                        ...actualFilterSelections,
                                        ...componentFilterState
                                    })
                                )
                                .map((ch) => {
                                    if (ch.renderer && ch.renderer.type !== 'None') {
                                        return { ...ch, render: (text, row) => getRenderer(text, row, ch.renderer) };
                                    }
                                    return ch;
                                })
                            : null, // defaultFilteredValue does not work when children exist - not sure why, maybe ant d bug?
                        defaultFilteredValue: hasFilterApplied ? [currentFilterState[searchFilterQueryParam]] : null,
                        ...(searchFilterQueryParam && getColumnSearchProps(dataIndex, title, searchFilterQueryParam)),
                        ...(renderer &&
                            renderer.type !== 'None' && {
                            render: (text, row) => getRenderer(text, row, renderer)
                        })
                    };
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [columns, actualFilterSelections, componentFilterState]
    );

    const headerPdf = useMemo(() => {
        return columnsDef.map((def) => {
            return def.title;
        });
    }, [columnsDef]);

    const pdfBody = useMemo(() => {
        if (exportAsPdfBypassPagination && (unpaginatedDataLoading || unpaginatedDataError || !unpaginatedData)) {
            return null;
        }
        const filteredData = (exportAsPdfBypassPagination ? doFilter(doFormat(unpaginatedData)) : formattedData) || [];
        const rows = filteredData.map((row) => {
            return columnsDef.map((column) => {
                const value = row[column['dataIndex']];
                return isNaN(value)
                    ? value || ''
                    : numberFormat(value, { maximumFractionDigits: 2, useGrouping: false }); // disable commas (useGrouping)
            });
        });
        return [headerPdf].concat(rows);
    }, [headerPdf, unpaginatedData, formattedData, exportAsPdfBypassPagination]);

    const handlePageChange = (pagination, filters, sorter, extra) => {
        const { action } = extra; // paginate | sort | filter
        const { current, pageSize } = pagination;
        if (isBackendPaginated) {
            switch (action) {
                case 'paginate':
                    setFilterState((currentState) => ({
                        ...currentState,
                        [Filters.PAGINATION]: { page: current - 1, page_size: pageSize }
                    }));
                    break;
                case 'sort':
                    const { order, field } = sorter;
                    const orderType = order === 'ascend' ? 'asc' : order === 'descend' ? 'desc' : null;
                    const newSortFilters = order ? { order: orderType, field } : {};
                    setFilterState((currentState) => ({
                        ...currentState,
                        [Filters.SORTING]: newSortFilters
                    }));
                    break;
                default:
            }
        }
    };

    const handleSearchChange = (e) => {
        updateSearch(e.target.value);
    };

    const updateComponentFilterSearchState = (filter, value) => {
        setFilterState((currentState) => ({
            ...currentState,
            [Filters.GENERIC_TABLE_SEARCH]: {
                ...(currentState[Filters.GENERIC_TABLE_SEARCH] || {}),
                [filter]: value
            }
        }));
        dispatchUserDataState({
            type: UserDataActions.GENERIC_TABLE_SEARCH,
            payload: {
                ...(userDataState[Filters.GENERIC_TABLE_SEARCH] || {}),
                [filter]: value
            }
        });
    };

    const resetPagination = useCallback(() => {
        setFilterState((currentState) => ({
            ...currentState,
            [Filters.PAGINATION]: { page: 0, page_size: currentState[Filters.PAGINATION]?.page_size }
        }));
    }, [setFilterState]);

    useEffect(() => {
        resetPagination();
    }, [filterSelections, resetPagination]);

    const handleSearch = (selectedKeys, confirm, searchFilterQueryParam) => {
        resetPagination();
        confirm();
        updateComponentFilterSearchState(searchFilterQueryParam, selectedKeys[0]);
    };

    const handleReset = (clearFilters, searchFilterQueryParam) => {
        clearFilters();
        updateComponentFilterSearchState(searchFilterQueryParam, '');
    };

    const { groupedBy, total, startDate, endDate, todayDate } = useMemo(() => {
        const searchFilters = actualFilterSelections[Filters.GENERIC_TABLE_SEARCH];
        const keys = Object.keys(searchFilters || {})
            .map((key) => {
                const col = columns.filter((col) => col.searchFilterQueryParam === key)[0];
                const value = searchFilters[key];
                if (col && value) {
                    return `${col.title} (${value})`;
                }
                return null;
            })
            .filter((key) => !!key);
        const total = (!!pagination.total || data) && (
            <span>
                <i className="text-gray-600 mr-1">Total Results: </i>
                {numberFormat(pagination.total || data.length)}
            </span>
        );
        const timePeriod = actualFilterSelections[Filters.TIME_PERIOD];
        const demographic = actualFilterSelections[Filters.DEMOGRAPHIC];
        const startDate = moment(timePeriod.date_unit == 'century' ? '20010101' : timePeriod.date_id).format(
            timePeriodFormat
        );
        const endDate =
            timePeriod.date_unit == 'century'
                ? moment().format(timePeriodFormat)
                : moment(timePeriod.date_id).add('1', timePeriod.date_unit).subtract(1, 'day').format(timePeriodFormat);
        const todayDate = moment().format(timePeriodFormat);
        const joinedKeys = keys.join(', ');
        const groupedBy = modifiedFilters[Filters.DEMOGRAPHIC]
            ? `${demographic.type === 'All' ? 'All Demographics' : demographic.type}${demographic.dimension ? ` (${demographic.dimension})` : ''
            }${keys.length ? `, ${joinedKeys}` : joinedKeys}`
            : keys.join(', ');
        return { groupedBy, total, startDate, endDate, todayDate };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, columns, actualFilterSelections, data]);

    const doExport = async () => {
        setLoadingExport(true);
        try {
            const columns = [];
            columnsDef.forEach(({ dataIndex, csvDataIndex, title, children }) => {
                if (!children) {
                    if (!!title && (!!dataIndex || !!csvDataIndex)) {
                        columns.push({ field: csvDataIndex || dataIndex, label: title });
                    }
                } else {
                    children.forEach((child) => {
                        columns.push({
                            field: child.csvDataIndex || child.dataIndex,
                            label: `${title} ${child.title}`
                        });
                    });
                }
            });

            if (!isBackendPaginated) {
                const contentType = 'text/csv';
                downloadCsv(new Blob([makeCSV(formattedData, columns, csvTitle)], { type: contentType }));
                setLoadingExport(false);
                return;
            }

            const results = await fetchStatsApi(endpoint, modifiedFilters, 'POST', { columns });
            if (results.ok) {
                const contentType = 'text/csv';
                const csvFormattedTitle = csvTitle
                    ? `"${csvTitle.replace(/"/g, '\\"')}"` +
                    Array(columns.length - 1)
                        .fill(',')
                        .join('')
                    : '';
                const csvText = `${await results.text()}`;
                downloadCsv(new Blob([csvText], { type: contentType }));
            } else {
                // todo implement toast
            }
            setLoadingExport(false);
        } catch (e) {
            setLoadingExport(false);
            console.error('An error occurred during export: ', e);
        }
    };

    const downloadCsv = (finalResults) => {
        const objUrl = window.URL.createObjectURL(finalResults);
        let link = document.createElement('a');
        link.href = objUrl;
        if (exportFileNameDisableDateRange) {
            link.download = `${exportFileName}-${todayDate}.csv`;
        } else {
            link.download = `${exportFileName}-${startDate}-${endDate}.csv`;
        }
        link.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
        }, 250);
    };

    const CsvExport = () => (
        <Button
            // type="primary"
            shape="round"
            size="large"
            loading={loadingExport}
            icon={<DownloadOutlined />}
            onClick={doExport}>
            Export CSV
        </Button>
    );
    useEffect(() => {
        if (pdfDelegate && pdfBody) {
            pdfDelegate?.setPdfChildCompleteFlag({
                layout: 'lightHorizontalLines', // optional
                table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,
                    widths: columnsDef.map((column) => parseInt(column.pdfTableColumnWidth) || '*'),
                    body: pdfBody
                }
            });
        }
    }, [pdfDelegate, pdfBody, columnsDef]);

    return (
        <>
            {!title ? null : <h2>{title}</h2>}
            <div className={classes.TableWrapper}>
                {!!showFilterDetails && (
                    <div className="flex flex-row items-end mb-2 text-xs">
                        <div className="flex-1">
                            {!!exportAsCsv && <CsvExport className="mb-1" />}
                            {!!groupedBy && (
                                <div>
                                    <i className="text-gray-600 mr-1">Statistics Grouped By: </i>
                                    <span>{groupedBy}</span>
                                </div>
                            )}
                        </div>
                        <div className="text-right">
                            <div>
                                <i className="text-gray-600 mr-1">Time Period:</i>
                                <span>
                                    {startDate} - {endDate}
                                </span>
                            </div>
                            <div>{showTotal && total}</div>
                        </div>
                    </div>
                )}
                {!showFilterDetails && (
                    <div className="flex flex-row items-center mb-2">
                        {exportAsCsv && <CsvExport className="flex-1" />}
                        <div className="text-xs flex-1 ml-4 text-right">{showTotal && total}</div>
                        {searchOff ? null : (
                            <div className="ml-2 w-1/3">
                                <Input placeholder="Search" value={search} onChange={handleSearchChange} />
                            </div>
                        )}
                    </div>
                )}
                <Table
                    size="small"
                    bordered={bordered}
                    columns={columnsDef}
                    showHeader={showHeader}
                    rowClassName={rowDividers ? {} : classes.NoDividerRow}
                    loading={loading}
                    className={overrideClassName}
                    dataSource={filteredData.map((d, index) => {
                        d.key = index;
                        return d;
                    })}
                    scroll={{ x: scrollXWidth, y: scrollYWidth }}
                    pagination={paginationEnabled && pagination}
                    onChange={handlePageChange}
                    // summary={(pageData) => <TableSummary pageData={pageData} columnsDef={columnsDef} />}
                    locale={{ items_per_page: '', emptyText: <EmptyState /> }}
                />
            </div>
        </>
    );
}

export { TableComponentParams, TableComponentEditorSchema, TableComponent };
