const LocalStorageConstants = {
    SYNC_TO_LOCAL_STORAGE_OPTION: 'SYNC_TO_LOCAL_STORAGE_OPTION',
    PAGE_CONFIGURATION_JSON: 'PAGE_CONFIGURATION_JSON',
    SHOW_CODE_EDITOR_ICON: 'SHOW_CODE_EDITOR_ICON'
};

const BLANK_PAGE = {
    parentModule: 'organization',
    path: '/organization-overview/something',
    title: 'New Unnamed Page',
    showCrumbs: true,
    filters: ['organization', 'timePeriod'],
    children: [
        {
            component: 'CollapsableCardComponent',
            params: {
                title: 'My New Collapsable Card'
            }
        }
    ]
};

const MODULE_EDITOR_PARAMS = [
    {
        path: 'module',
        type: 'string',
        defaultValue: 'module',
        readableName: 'Module',
        help: 'Name of the module.'
    },
    {
        path: 'path',
        type: 'string',
        defaultValue: '/module',
        readableName: 'Path',
        help: 'Path of the module.'
    },
    {
        path: 'titleComponent.params.title',
        type: 'string',
        defaultValue: 'module',
        readableName: 'Title',
        help: 'Title of the module.'
    },
    {
        path: 'titleComponent.children',
        type: 'json',
        defaultValue: [],
        readableName: 'Title Component Children',
        help: "Title Component of the module.  Please pardon our dust.  You probably don't want to mess with this."
    },
    {
        path: 'showCrumbs',
        type: 'boolean',
        defaultValue: false,
        readableName: 'Show Crumbs',
        help: 'Show crumbs.'
    },
    {
        path: 'filters',
        type: 'filter',
        defaultValue: [],
        readableName: 'Filters',
        help: 'Filters'
    }
];

const PAGE_EDITOR_PARAMS = [
    {
        path: 'path',
        type: 'string',
        defaultValue: '/page',
        readableName: 'Path',
        help: 'Path of the page.'
    },
    {
        path: 'pageId',
        type: 'string',
        defaultValue: '0',
        readableName: 'Page ID',
        help: 'ID of the page.'
    },
    {
        path: 'module',
        type: 'string',
        defaultValue: '',
        readableName: 'Products (CSV)',
        help: 'Enter comma separated values of product slugs for which this page should be visible'
    },
    {
        path: 'order',
        type: 'string',
        defaultValue: '0',
        readableName: 'Order',
        help: 'Order of the page.'
    },
    {
        path: 'title',
        type: 'string',
        defaultValue: '',
        readableName: 'Title',
        help: 'Title of the page'
    },
    {
        path: 'fullWidth',
        type: 'boolean',
        defaultValue: false,
        readableName: 'Full Width',
        help: 'If set, the components will occupy the available viewport width.'
    },
    {
        path: 'filters',
        type: 'filter',
        defaultValue: [],
        readableName: 'Filters',
        help: 'Filters'
    }
];

const BASE_COMPONENT_EDITOR_PARAMS = [
    {
        path: 'componentId',
        type: 'string',
        defaultValue: 0,
        readableName: 'Component ID',
        help: 'Component ID'
    },
    {
        path: 'colSpan',
        type: 'number',
        defaultValue: 12,
        readableName: 'Column Span'
    },
    {
        path: 'products',
        type: 'string',
        defaultValue: '',
        readableName: 'Products (CSV)',
        help: 'Enter comma separated values of product slugs for which this component should be visible'
    },
    {
        path: 'filters',
        type: 'json',
        defaultValue: [],
        readableName: 'Filters'
    },
    {
        path: 'order',
        type: 'string',
        defaultValue: 0,
        readableName: 'Order',
        help: 'Order'
    },
    {
        path: 'visible',
        type: 'boolean',
        defaultValue: true,
        readableName: 'Visible',
        help: 'If set, component is visible.  Otherwise it is hidden.'
    },
    {
        path: 'deleted',
        type: 'boolean',
        defaultValue: false,
        readableName: 'Deleted',
        help: 'If set, component will be deleted and you will not be able to retrieve it.'
    }
];

const BASE_API_COMPONENT_EDITOR_PARAMS = [
    ...BASE_COMPONENT_EDITOR_PARAMS,
    {
        path: 'endpoint.url',
        type: 'string',
        defaultValue: '',
        readableName: 'Endpoint URL',
        help: ''
    }
];

const UNKNOWN_COMPONENT_EDITOR_PARAMS = [
    ...BASE_API_COMPONENT_EDITOR_PARAMS,
    {
        path: 'params',
        type: 'json',
        defaultValue: [],
        readableName: 'Params JSON',
        help: "Raw JSON params for unknown component.  You probably don't want to mess with this."
    }
];

export {
    LocalStorageConstants,
    BLANK_PAGE,
    BASE_API_COMPONENT_EDITOR_PARAMS,
    BASE_COMPONENT_EDITOR_PARAMS,
    MODULE_EDITOR_PARAMS,
    PAGE_EDITOR_PARAMS,
    UNKNOWN_COMPONENT_EDITOR_PARAMS
};
