import React, { useEffect, useMemo, useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import { FiltersProvider } from '../../../contexts/FiltersContext';
import { useStatsApi, useStatsApiAbusively } from '../../../hooks/useApi';
import LoadingState from '../../states/LoadingState';
import ErrorState from '../../states/ErrorState';
import ConfigurationRouteMapper from './ConfigurationRouteMapper';
import { useQueryParam } from 'use-query-params';
import { Redirect, useLocation } from 'react-router-dom';
import config from '../../../config.json';
import NewEditableMainLayoutContainer from '../../../editor/NewEditableMainLayoutContainer';
import { PDFProgressProvider } from '../../../contexts/PDFProgressContext';
var CryptoJS = require('crypto-js');

const MainLayout = () => {
    const EDIT_MODE_SECRET = '529809629f2e35df5943b1f0c6aa2e19';
    const [pages, loadingPages, pagesError] = useStatsApi('dashboard/pages');
    const modules = useMemo(() => pages && pages.filter((pages) => !!pages.module), [pages]);

    const location = useLocation();
    const [convertFlag] = useQueryParam('external');
    const [queryPerson] = useQueryParam('person');
    const [editModeQuery] = useQueryParam('edit_mode');
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (CryptoJS.MD5(editModeQuery).toString() === EDIT_MODE_SECRET) {
            setEditMode(true);
        }
    }, [editModeQuery]);

    const convertTuples = useMemo(() => {
        if (convertFlag && queryPerson) {
            return {
                bms_person_id: {
                    value: queryPerson,
                    resultField: 'person_id',
                    assignTo: 'person'
                },
                bms_organization_id: {
                    value: 0,
                    resultField: 'organization_id',
                    assignTo: 'organization'
                }
            };
        } else {
            return undefined;
        }
    }, [convertFlag, queryPerson]);
    const convertQueryParams = useMemo(() => {
        let queryParams = {};
        for (var key in convertTuples) {
            queryParams[key] = convertTuples[key].value;
        }
        return queryParams;
    }, [convertTuples]);

    const [convertedIds, loadingConvert, convertError] = useStatsApiAbusively('bridge/bms2gyro', convertQueryParams);

    const convertedQueryParams = useMemo(() => {
        return !convertTuples
            ? {}
            : Object.keys(convertedIds || {}).reduce((accumulator, convertKey, index) => {
                  return {
                      ...accumulator,
                      [Object.values(convertTuples).find((element) => element.resultField === convertKey)?.assignTo ||
                      '']: convertedIds[convertKey]
                  };
              }, {});
    }, [convertedIds, convertTuples]);

    const redirectTo = useMemo(() => {
        const convertRedirectPath = location.pathname;
        if (!!convertFlag && !loadingConvert && !convertError) {
            var getUrl = window.location;
            var baseUrl = getUrl.protocol + '//' + getUrl.host;
            const redirectBaseURL = new URL(convertRedirectPath, baseUrl);
            const searchParams = new URLSearchParams(redirectBaseURL.search);
            Object.keys(convertedQueryParams).forEach((key, index) => {
                searchParams.set(key, convertedQueryParams[key]);
            });
            redirectBaseURL.search = searchParams.toString();
            return redirectBaseURL.pathname + '?' + searchParams.toString();
        }
        return undefined;
    }, [convertedQueryParams, location, convertFlag, loadingConvert, convertError]);

    if (editMode) {
        return <NewEditableMainLayoutContainer />;
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    if (loadingPages || loadingConvert) {
        return <LoadingState />;
    }

    if (convertFlag && convertError) {
        return (
            <ErrorState
                encompassPage={true}
                title="Officer Analytics -- Unauthorized"
                text="Resource not found within your agency."
                safetyLinkText="Click to return to BMS"
                safetyLinkURL={config.bmsUrl}
            />
        );
    }

    if (pagesError) {
        return (
            <ErrorState
                encompassPage={true}
                title="Failed"
                text="We had an issue communicating with one of our services, please try again."
            />
        );
    }

    return (
        <div className="min-w-full flex flex-row" style={{ height: '100vh' }}>
            <Sidebar />
            <div className="flex-1 flex flex-col">
                <PDFProgressProvider>
                    <FiltersProvider>
                        <ConfigurationRouteMapper modules={modules} pages={pages} />
                    </FiltersProvider>
                </PDFProgressProvider>
            </div>
        </div>
    );
};

export default MainLayout;
