import { useContext, useMemo, useState, useEffect } from 'react';
import { Filters } from '../../../constants';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { Cascader } from 'antd';
import { formatDemographicOptions } from '../../../utilities';

const DemographicsFilter = ({ title }) => {
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);
    const { type, dimension } = filterSelections[Filters.DEMOGRAPHIC];
    const dimensions = filterOptions[Filters.DEMOGRAPHIC];
    const [value, setValue] = useState([type, dimension]);

    useEffect(() => {
        setValue([type, dimension]);
    }, [type, dimension]);

    useEffect(
        () => () => dispatchNewSelection(['All'], [{ label: 'All', value: 'All' }]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const dispatchNewSelection = (value, list) => {
        if (value.length) {
            const type = list[0].value;
            const dimension = list[1] ? list[1].value : '';
            dispatch({
                type: FiltersActions.SET_FILTER_STATE,
                payload: {
                    filterId: Filters.DEMOGRAPHIC,
                    value: { type, dimension }
                }
            });
            setValue([type, dimension]);
        } else {
            setValue(null);
        }
    };

    const options = useMemo(() => formatDemographicOptions(dimensions), [dimensions]);

    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">
                {!title ? 'Demographic' : title}
            </h3>
            <Cascader value={value} options={options} onChange={dispatchNewSelection} />
        </div>
    );
};

export default DemographicsFilter;
