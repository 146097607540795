import { useEffect } from 'react';
import SeparatorEditorSchema from './SeparatorEditorSchema.json';
import SeparatorParams from './SeparatorParams.json';

const Separator = ({ params, className = '', pdfDelegate }) => {
    const additionalSpaceAbove = params && params.spaceAbove ? 'mt-4' : '';
    const additionalSpaceBelow = params && params.spaceBelow ? 'mb-4' : '';

    useEffect(() => {
        if (pdfDelegate) {
            pdfDelegate?.setPdfChildCompleteFlag(true);
        }
    }, [pdfDelegate]);

    return <div className={`border-t border-hr h-1 ${additionalSpaceAbove} ${additionalSpaceBelow} ${className}`} />;
};

export { Separator, SeparatorParams, SeparatorEditorSchema };
