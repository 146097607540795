import { useState, useMemo, useEffect } from 'react';
import withTitle from '../withTitle';
import { FiltersContext } from '../../contexts/FiltersContext';
import { useContext } from 'react';
import { ChildrenWrapper } from './ComponentInterpreter';
import GridWrapper from './GridWrapper';
import { usePdfDelegate } from '../../hooks/usePdfDelegate';
import getBase64ImageFromURL from '../../utilities/base64-image-download';

const PageLayout = ({ config, title, filters = [] }) => {
    const [{ pdfDownload, filterSelections }] = useContext(FiltersContext);
    const { loading: pdfDownloadLoading, reportDate } = pdfDownload;
    const { children } = config;
    const [benchmarkLogo64, setBenchmarkLogo64] = useState(null);

    const filterText = useMemo(() => {
        const nameArray = filters.map((filter) => {
            return filterSelections[filter]?.name;
        });
        return nameArray
            .filter((name) => {
                return !!name;
            })
            .join('; ');
    }, [filterSelections, filters]);

    useEffect(() => {
        if (pdfDownloadLoading && !benchmarkLogo64) {
            getBase64ImageFromURL('https://www.benchmarkanalytics.com/wp-content/uploads/2019/05/Benchmark-Logo.png')
                .then((result) => {
                    setBenchmarkLogo64(result);
                })
                .catch((error) => {
                    setBenchmarkLogo64(null);
                });
        }
    }, [pdfDownloadLoading]);

    const subtreeDelegate = usePdfDelegate(
        pdfDownloadLoading,
        (subtreeResult) => {
            const newline = { text: ' ', style: 'subtitle' };
            const pageOrientation = { text: ' ', style: 'paragraph', pageMargins: [200, 200] };
            const titleSection = subtreeDelegate.getTitlePageOverride('title') || { text: title, style: 'title' };
            const logoSection = {
                image: benchmarkLogo64,
                alignment: 'center',
                width: 400,
                height: 112,
                margin: [0, 200, 0, 0]
            };
            const subtitleSection = subtreeDelegate.getTitlePageOverride('subtitle') || {};
            const subtitle2Section = subtreeDelegate.getTitlePageOverride('subtitle2') || {};
            const suffixSection = subtreeDelegate.getTitlePageOverride('suffix') || {
                text: `PDF snapshot report as prepared by Benchmark Analytics on ${reportDate}`,
                style: 'suffix'
            };
            const pageBreakSection = subtreeDelegate.getTitlePageOverride('page_break') || {
                text: '',
                pageBreak: 'after'
            };
            const contents = [
                logoSection,
                { ...newline },
                titleSection,
                { ...newline },
                subtitleSection,
                subtitle2Section,
                { ...newline },
                suffixSection,
                pageBreakSection
            ].concat(subtreeResult);
            subtreeDelegate.setPdfComplete(contents);
        },
        (subtreeError) => {
            subtreeDelegate.setPdfError(subtreeError);
        }
    );

    useEffect(() => {
        if (subtreeDelegate) {
            subtreeDelegate.setPdfTitle(title);
            subtreeDelegate.setPdfFilterText(filterText);
        }
    }, [subtreeDelegate, filterText]);

    const passSubtreeDelegate = !!benchmarkLogo64 && subtreeDelegate;
    if (benchmarkLogo64 === false && subtreeDelegate) {
        subtreeDelegate.setPdfError(true);
    }

    return (
        <div className="p-2 md:p-3 lg:p-6 w-full">
            <GridWrapper>
                <ChildrenWrapper childComponents={children} pdfDelegate={passSubtreeDelegate} />
            </GridWrapper>
        </div>
    );
};

export default withTitle(PageLayout);
