import { useEffect } from 'react';
import LoadingSpinner from '../states/LoadingSpinner';
import EmptyState from '../states/EmptyState';
import ProfileStyleCardEditorSchema from './ProfileStyleCardEditorSchema.json'
import defaultPhoto from '../../assets/images/doggo.png'

const ProfileStyleCard = ({ params, loading, data, pdfDelegate }) => {
    const {
        columns = []
    } = params;
    if (Array.isArray(data) && data.length > 0) {
        data = data[0];
    }

    useEffect(() => {
        if (pdfDelegate && !loading) {
            if (data) {
                // pdfDelegate?.setPdfTitle(fmtHeadingText);
                // pdfDelegate?.setOverrideTitlePageElement({
                //     subtitle: { text: fmtHeadingText, style: 'subtitle' },
                //     subtitle2: { text: fmtSubHeadingText, style: 'subtitle' }
                // });
                pdfDelegate?.setPdfChildCompleteFlag(true);
            } else {
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
    }, [pdfDelegate, loading, data]);

    if (!!loading) {
        const filledInDataHeight = 64;
        return (
            <div className={'flex justify-center items-center w-full'} style={{ height: filledInDataHeight }}>
                <LoadingSpinner size="medium" />
            </div>
        );
    }

    // if (!data) {
    //     const filledInDataHeight = 64;
    //     return <EmptyState height={filledInDataHeight} />;
    // }
    // console.log('styles: ',styles)

    const styles = {
        container: {
            display: 'flex',
            height: '200px',
            background: 'linear-gradient(to bottom, #FEFEFE 50%, #F7F7F7 50%)'
        },
        profile: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px'
        },
        picture: {
            aspectRatio: '1 / 1',
            maxHeight: '100%',
            borderRadius: '50%',
            border: '5px solid #665B8D',
            overflow: 'hidden'
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        },
        data: {
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            maxWidth: 'calc(100% - 220px)',
            height: '50%',
            marginTop: 'auto'
        },
        table: {
            width: '600px',
            borderCollapse: 'collapse',
            tableLayout: 'fixed'
        },
        cell: {
            width: '200px',
            borderLeft: '4px solid #f4f4f4',
            borderRight: '4px solid #f4f4f4',
            padding: '0px 10px 0px 40px',
            textAlign: 'left',
            color: '#4A4E57'
        },
        hyperlink: {
            color: '#0000ff'
        },
        secondRowText: {
            color: '#AAAFB3'
        }
    };
    return (
        // <div className={'flex items-center w-full'}>
        <>
            <div style={styles.container}>
                <div style={styles.profile}>
                    <div style={styles.picture}>
                        <img style={styles.image} src={data?.[params['profilePhotoURL']] || defaultPhoto} alt="Profile Picture" />
                    </div>
                </div>
                <div style={styles.data}>
                    <table style={styles.table}>
                        <tr>
                            {columns.map(({name, dataIndex, linkIndex}) => {
                                if (data?.[linkIndex]) {
                                    return data?.[dataIndex] &&<th style={{...styles.cell, ...styles.hyperlink}}> <a href={data[linkIndex]}>{data?.[dataIndex]}</a></th>
                                } else {
                                    return data?.[dataIndex] && <th style={{...styles.cell}}>{data?.[dataIndex]}</th>
                                }                                
                            })}
                        </tr>
                        <tr>
                            {columns.map(({name, dataIndex}) => {
                                return data?.[dataIndex] && <td style={{...styles.cell, ...styles.secondRowText}}>{name}</td>
                            })}
                        </tr>
                    </table>
                </div>
            </div>
        </>
        // </div>
    );
};

export { ProfileStyleCard, ProfileStyleCardEditorSchema };
