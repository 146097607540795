import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import classes from './Navigation.module.css';
import { resolve } from './NavigationResolver';

function Navigation({ permissions }) {
    const [links, setLinks] = useState([]);
    const location = useLocation();

    useEffect(() => {
        setLinks(resolve(permissions, location.pathname));
    }, [permissions, location]);

    return <ol className={classes.Navigation}>{links}</ol>;
}

export default Navigation;
