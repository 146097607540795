import TextComponent from './TextComponent';
import * as moment from 'moment';

const DotTextDateComponent = ({ params }) => {
    const {
        primaryTextTemplate,
        hideDot = false,
        datePreamble,
        dateOverride,
        dateField,
        data,
        useNumberFormat,
        className = '',
        position = 'left',
        colorFunction,
        colorInput
    } = params;

    const dotCmp = (
        <TextComponent
            params={{
                text: '●',
                colorFunction,
                colorInput,
                size: 'sm',
                className,
                position
            }}
        />
    );
    const primaryTextCmp = (
        <TextComponent
            params={{
                template: primaryTextTemplate,
                data,
                useNumberFormat,
                size: 'xs',
                className: 'font-bold ' + className,
                position
            }}
        />
    );
    const dateToRender = dateOverride || (data && data[dateField]);
    const dateTextCmp = dateToRender && (
        <TextComponent
            params={{
                text: (datePreamble || '') + moment(dateToRender).format('M/D/YYYY'),
                className: 'font-thin ' + className,
                size: 'xs',
                position
            }}
        />
    );
    return (
        <div className="flex-row flex flex-1 items-center space-x-2">
            {!hideDot && dotCmp}
            {primaryTextCmp}
            {dateTextCmp}
        </div>
    );
};

export default DotTextDateComponent;
