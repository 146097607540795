import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { FilterPreferences, Filters, DateUnit, TIME_PERIOD_FILTER_FORMAT } from '../constants';
import * as moment from 'moment';
import { useStatsApi } from '../hooks/useApi';
import ErrorState from '../components/states/ErrorState';
import { constructOrganizationDetailString, constructOfficerDetailString } from '../utilities';
import LoadingState from '../components/states/LoadingState';
import { useLocation, useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
var _ = require('lodash');

export const FiltersActions = {
    SET_FILTER_STATE: 'RESOLVING',
    SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
    SET_FILTER_STATE_AND_OPTIONS: 'SET_FILTER_STATE_AND_OPTIONS',
    START_PDF_DOWNLOAD: 'START_PDF_DOWNLOAD',
    OVERRIDE_PDF_TITLE_PAGE_ELEMENT: 'OVERRIDE_PDF_TITLE_PAGE_ELEMENT',
    SET_PDF_TITLE: 'SET_PDF_TITLE',
    COMPLETE_PDF_DOWNLOAD: 'COMPLETE_PDF_DOWNLOAD',
    ERROR_PDF_DOWNLOAD: 'ERROR_PDF_DOWNLOAD',
    CANCEL_PDF_DOWNLOAD: 'CANCEL_PDF_DOWNLOAD'
};

export const dateFilterToText = (date_id, date_unit) => {
    let name = moment(date_id).format();
    switch (date_unit) {
        case DateUnit.YEAR:
            name = moment(date_id).format('YYYY');
            break;
        case DateUnit.MONTH:
            name = moment(date_id).format('MMMM YYYY');
            break;
        case DateUnit.QUARTER:
            name = 'Q' + moment(date_id).format('Q YYYY');
            break;
    }
    return name;
};

const FiltersContext = createContext(null);

const reducer = (state, action) => {
    const { filterId, value = {} } = action.payload || {};
    const { selections, options, title, newPdf, error, overrideTitlePageElement } = value;
    switch (action.type) {
        case FiltersActions.SET_FILTER_STATE:
            return {
                ...state,
                filterSelections: {
                    ...state.filterSelections,
                    [filterId]: value.additive ? {
                        ...state.filterSelections[filterId],
                        ...value,
                    } : value
                }
            };
        case FiltersActions.SET_FILTER_OPTIONS:
            return {
                ...state,
                filterOptions: { ...state.filterOptions, [filterId]: value },
                filtersLoading: { ...state.filtersLoading, [filterId]: false }
            };
        case FiltersActions.SET_FILTER_STATE_AND_OPTIONS:
            return {
                ...state,
                filterSelections: {
                    ...state.filterSelections,
                    [filterId]: selections
                },
                filterOptions: { ...state.filterOptions, [filterId]: options },
                filtersLoading: { ...state.filtersLoading, [filterId]: false }
            };
        case FiltersActions.START_PDF_DOWNLOAD:
            const reportDate = moment().format('MM-DD-YYYY');
            if (state.loading) {
                return {
                    ...state
                };
            }
            return {
                ...state,
                pdfDownload: {
                    loading: true,
                    complete: false,
                    error: null,
                    title,
                    reportDate,
                    pdf: [],
                    titlePageOverrides: {}
                }
            };
        case FiltersActions.SET_PDF_TITLE:
            if (state.loading) {
                return {
                    ...state
                };
            }
            return {
                ...state,
                pdfDownload: {
                    ...state.pdfDownload,
                    title
                }
            };
        case FiltersActions.COMPLETE_PDF_DOWNLOAD:
            if (state.pdfDownload.loading) {
                return {
                    ...state,
                    pdfDownload: {
                        ...state.pdfDownload,
                        loading: false,
                        complete: true,
                        error: null,
                        pdf: newPdf
                    }
                };
            }
        case FiltersActions.OVERRIDE_PDF_TITLE_PAGE_ELEMENT:
            if (state.pdfDownload.loading) {
                return {
                    ...state,
                    pdfDownload: {
                        ...state.pdfDownload,
                        titlePageOverrides: overrideTitlePageElement
                    }
                };
            } else {
                return {
                    ...state
                };
            }
        case FiltersActions.ERROR_PDF_DOWNLOAD:
            if (state.pdfDownload.loading) {
                return {
                    ...state,
                    pdfDownload: {
                        pdf: null,
                        loading: false,
                        complete: false,
                        error: error || true,
                        titlePageOverrides: {}
                    }
                };
            }
        case FiltersActions.CANCEL_PDF_DOWNLOAD:
            return {
                ...state,
                pdfDownload: {
                    pdf: null,
                    loading: false,
                    complete: false,
                    error: null,
                    titlePageOverrides: {}
                }
            };
        default:
            throw new Error('Unknown filter action');
    }
};

const initialState = {
    filtersLoading: {
        [Filters.ORGANIZATION]: true,
        [Filters.USER_DATA_SECTION]: true,
        [Filters.OFFICER]: true,
        [Filters.DEMOGRAPHIC]: true,
        [Filters.TIME_PERIOD]: true,
        [Filters.ADHOC]: true
    },
    pdfDownload: {
        loading: false,
        complete: false,
        error: null,
        pdf: null
    }
};

const useDemographicsApi = () => {
    const [raceDimension, loadingRaceDimension, errorLoadingRaceDimension] = useStatsApi({
        url: 'dashboard/dimensions',
        queryParams: { type: 'Race' }
    });
    const [genderDimension, loadingGenderDimension, errorLoadingGenderDimension] = useStatsApi({
        url: 'dashboard/dimensions',
        queryParams: { type: 'Gender' }
    });
    const [ageDimension, loadingAgeDimension, errorLoadingAgeDimension] = useStatsApi({
        url: 'dashboard/dimensions',
        queryParams: { type: 'Age' }
    });
    const hasError = errorLoadingAgeDimension || errorLoadingGenderDimension || errorLoadingRaceDimension;
    const isLoading = loadingAgeDimension || loadingGenderDimension || loadingRaceDimension;

    return useMemo(() => {
        if (isLoading || hasError) {
            return [null, isLoading, hasError];
        } else {
            return [
                {
                    Gender: genderDimension,
                    Race: raceDimension,
                    Age: ageDimension
                },
                false,
                errorLoadingAgeDimension || errorLoadingAgeDimension || errorLoadingRaceDimension
            ];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, hasError]);
};

const FiltersProvider = ({ children }) => {
    const [filterState, dispatch] = useReducer(reducer, initialState);
    const [timeFilters, , errorLoadingTimeFilters] = useStatsApi('dashboard/time-filters');
    const [organizationFilters, , errorLoadingOrganizationFilters] = useStatsApi('dashboard/organization');
    const [userDataV0Filters, , errorLoadingUserDataV0Filters] = useStatsApi('attributes/agency/sections');
    const [usernameFilters, , errorLoadingUsernameFilters] = useStatsApi('dashboard/username');
    const [demographicFilters, , errorLoadingDemographicFilters] = useDemographicsApi();
    const { organization_id } = filterState?.filterSelections?.organization || {};
    const { person_filter } = filterState?.filterSelections?.officer || {};
    const [officerFilters, , errorLoadingOfficerFilters] = useStatsApi('dashboard/officers/single_org', {
        organization: { organization_id }
    });
    const [policyAlertFilters, , errorLoadingPolicyAlertFilters] = useStatsApi('policy_alerts/alerts_for_officer', {
        organization: { organization_id },
        officer: { person_filter }

    });
    const [adHocApisFilter, , errorLoadingAdHoc] = useStatsApi('adhoc/api-list');
    const [investigatorsFilters, , errorLoadingInvestigatorsFilters] = useStatsApi('internal_affairs/investigators');
    const [featureFilters, , errorLoadingFeatureFilters] = useStatsApi('first_sign/features');

    const location = useLocation();

    const [queryDate, setQueryDate] = useQueryParam('date', StringParam);
    const [queryDateUnit, setQueryDateUnit] = useQueryParam('date_unit', StringParam);
    const [queryDateTimespanUnits, setQueryDateTimespanUnits] = useQueryParam('timespan_units', StringParam);
    const [queryOrganization, setQueryOrganizationParam] = useQueryParam('organization', NumberParam);
    const [queryPerson, setQueryPersonParam] = useQueryParam('person', NumberParam);
    const [queryPolicyAlert, setQueryPolicyAlert] = useQueryParam('policy_alert', NumberParam);
    const [queryInvestigator, setQueryInvestigatorParam] = useQueryParam('investigator', NumberParam);
    const [queryFeature, setQueryFeatureParam] = useQueryParam('feature', StringParam);

    const history = useHistory();
    const setQueryOrganization = (organization) => {
        setQueryOrganizationParam(organization, 'replaceIn');
    };

    const setQueryPerson = (person) => {
        setQueryPersonParam(person, 'replaceIn');
    };

    useEffect(() => {
        if (adHocApisFilter) {
            const emptySelection = { url: null, label: '' };
            const apisAndEmpty = [emptySelection, ...adHocApisFilter];
            dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.ADHOC, {
                selections: [null],
                options: apisAndEmpty
            });
        }
    }, [adHocApisFilter]);

    const isLoading = useMemo(
        () => !!Object.keys(filterState.filtersLoading).filter((key) => !!filterState.filtersLoading[key]).length,
        [filterState.filtersLoading]
    );

    useEffect(() => {
        const filtersToCancelValues = [];
        if (location.search) {
            const params = new URLSearchParams(decodeURI(location.search.replace(/\+/gi, '%2B')));
            const filters = params.get('filters');
            if (filters) {
                const parsedFilters = JSON.parse(filters);
                parsedFilters.forEach((item) => {
                    const filterPreferences = FilterPreferences[item.filter];
                    if (filterPreferences && filterPreferences.isTemporary) {
                        filtersToCancelValues.push(item.filter);
                    }
                    dispatchToReducer(FiltersActions.SET_FILTER_STATE, item.filter, item.payload);
                });
            }
        }
        return () => {
            filtersToCancelValues.forEach((filter) => {
                dispatchToReducer(FiltersActions.SET_FILTER_STATE, filter, null);
            });
        };
    }, [location.search]);

    const dispatchToReducer = (type, filterId, options) =>
        dispatch({
            type,
            payload: { filterId, value: options }
        });

    useEffect(() => {
        if (demographicFilters) {
            const options = { All: null, ...demographicFilters };
            if (!(filterState && filterState.filterSelections && filterState.filterSelections[Filters.DEMOGRAPHIC])) {
                dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.DEMOGRAPHIC, {
                    selections: { type: 'All', dimension: '' },
                    options
                });
            } else {
                dispatchToReducer(FiltersActions.SET_FILTER_OPTIONS, Filters.DEMOGRAPHIC, options);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [demographicFilters]);

    useEffect(() => {
        if (featureFilters) {
            const options = featureFilters || [];
            dispatchToReducer(FiltersActions.SET_FILTER_OPTIONS, Filters.FEATURE, options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureFilters, queryFeature]);

    useEffect(() => {
        if (timeFilters) {
            const force_date_id = timeFilters[0].force_date_id;
            const date_id = queryDate || moment().startOf(timeFilters[0].range_unit).format(TIME_PERIOD_FILTER_FORMAT);
            const timespan_units = queryDateTimespanUnits || '1';
            const date_unit = queryDateUnit || timeFilters[0].range_unit;
            const preselectedPeriod =
                (queryDate && queryDateUnit) || timeFilters.length
                    ? {
                          date_id,
                          date_unit,
                          force_date_id,
                          timespan_units,
                          name: dateFilterToText(date_id, date_unit)
                      }
                    : {};
            dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.TIME_PERIOD, {
                selections: preselectedPeriod,
                options: timeFilters
            });
        }
    }, [timeFilters, queryDate, queryDateUnit]);

    useEffect(() => {
        if (organizationFilters && usernameFilters) {
            const organizationFiltersSorted = organizationFilters
                .filter((org) => org.is_active)
                .sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase()
                        ? -1
                        : a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : 0
                );
            const queryOrganizationFilter = _.find(organizationFiltersSorted, (organizationFilter) => {
                return queryOrganization == organizationFilter.organization_id;
            });
            let currentFilter = !!filterState?.filterSelections && filterState.filterSelections[Filters.ORGANIZATION];
            let foundOrganizationByUsernameOrgId;
            if (!queryOrganization) {
                if (!currentFilter) {
                    setQueryOrganization(usernameFilters.organization_id);

                    foundOrganizationByUsernameOrgId = organizationFiltersSorted?.find(
                        ({ organization_id }) => organization_id === usernameFilters.organization_id
                    );
                } else {
                    setQueryOrganization(currentFilter.organization_id);
                    return;
                }
            }
            const preselectedOrganization =
                queryOrganizationFilter || foundOrganizationByUsernameOrgId || organizationFiltersSorted.length
                    ? {
                          organization_id: (queryOrganizationFilter || organizationFiltersSorted[0]).organization_id,
                          name: constructOrganizationDetailString(
                              queryOrganizationFilter || organizationFiltersSorted[0]
                          )
                      }
                    : {};
            dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.ORGANIZATION, {
                selections: preselectedOrganization,
                options: organizationFiltersSorted
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationFilters, usernameFilters, queryOrganization]);

    useEffect(() => {
        if (userDataV0Filters) {
            const userDataV0FiltersSorted = userDataV0Filters
                // .filter((section) => section.section_name)
                .sort((a, b) =>
                    a.section_name.toLowerCase() < b.section_name.toLowerCase()
                        ? -1
                        : a.section_name.toLowerCase() > b.section_name.toLowerCase()
                        ? 1
                        : 0
                );
            const preselectedSection = userDataV0FiltersSorted.length
                ? {
                      user_data_section_id: userDataV0FiltersSorted[0].section_id,
                      user_data_section_name: userDataV0FiltersSorted[0].section_name
                  }
                : {};
            dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.USER_DATA_SECTION, {
                selections: preselectedSection,
                options: userDataV0FiltersSorted.map((option) => {
                    return { user_data_section_id: option.section_id, user_data_section_name: option.section_name };
                })
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDataV0Filters]);

    useEffect(() => {
        dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.CHILD_ORGANIZATIONS, {
            selections: {
                include_child_organizations: true
            },
            options: {}
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const officerFiltersSorted = (officerFilters || []).sort((a, b) => {
            const fullNameA = a?.last_name?.toLowerCase() + ', ' + a?.first_name?.toLowerCase();
            const fullNameB = b?.last_name?.toLowerCase() + ', ' + b?.first_name?.toLowerCase();
            return fullNameA < fullNameB ? -1 : fullNameA > fullNameB ? 1 : 0;
        });
        let currentFilter = !!filterState?.filterSelections && filterState.filterSelections[Filters.OFFICER];
        let currentFilterFound = !!_.find(officerFiltersSorted, (officerFilter) => {
            return !!currentFilter && officerFilter.person_id == currentFilter.person_id;
        });
        let officerFiltersActive = officerFiltersSorted.filter((filter) => {
            return filter.is_active;
        });
        if (!!currentFilter?.person_id && !queryPerson && currentFilterFound) {
            setQueryPerson(currentFilter.person_id);
            return;
        }
        if (!!currentFilter && !currentFilterFound) {
            dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.OFFICER, {
                selections: null,
                options: officerFiltersActive
            });
            if (currentFilter.person_id == queryPerson) {
                setQueryPerson(null);
            }
        }
        const queryOfficerFilter = _.find(officerFiltersSorted, (officerFilter) => {
            return queryPerson == officerFilter.person_id;
        });

        const querySelectedOrDefault = queryOfficerFilter || officerFiltersActive[0] || {};

        const preselectedOfficer =
            queryOfficerFilter || officerFiltersSorted.length
                ? {
                      person_id: querySelectedOrDefault.person_id,
                      person_filter: querySelectedOrDefault.person_id,
                      name: constructOfficerDetailString(querySelectedOrDefault)
                  }
                : {};
        dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.OFFICER, {
            selections: preselectedOfficer,
            options: officerFiltersSorted.filter((filter) => {
                return filter.is_active || (queryPerson && filter.person_id == queryPerson);
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officerFilters, queryPerson]);

    useEffect(() => {
        const policyAlertFiltersSorted = (policyAlertFilters || [])
        let currentFilter = !!filterState?.filterSelections && filterState.filterSelections[Filters.POLICY_ALERT];
        let currentFilterFound = !!_.find(policyAlertFiltersSorted, (policyFilter) => {
            return !!currentFilter && policyFilter.aggregate_risk_id == currentFilter.aggregate_risk_id;
        });        
        if (!!currentFilter?.aggregate_risk_id && !queryPolicyAlert && currentFilterFound) {
            setQueryPolicyAlert(currentFilter.aggregate_risk_id);
            return;
        }
        if (!!currentFilter && !currentFilterFound) {
            dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.POLICY_ALERT, {
                selections: null,
                options: policyAlertFiltersSorted
            });
            if (currentFilter.aggregate_risk_id == queryPolicyAlert) {
                setQueryPolicyAlert(null);
            }
        }
        const queryPolicyAlertFilter = _.find(policyAlertFiltersSorted, (policyFilter) => {
            return queryPolicyAlert == policyFilter.person_risk_id;
        });

        const querySelectedOrDefault = queryPolicyAlertFilter || policyAlertFiltersSorted[0] || {};

        const preselectedAlert =
        queryPolicyAlertFilter || policyAlertFiltersSorted.length
                ? {
                    policy_alert: querySelectedOrDefault.person_risk_id,
                  }
                : {};
        dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.POLICY_ALERT, {
            selections: preselectedAlert,
            options: policyAlertFiltersSorted
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyAlertFilters, queryPolicyAlert]);

    useEffect(() => {
        if (filterState?.filterSelections?.organization?.organization_id) {
        }
    }, [filterState]);

    useEffect(() => {
        if (investigatorsFilters) {
            const allInvestigators = {
                investigator_id: '',
                investigator_last_name: 'All',
                investigator_first_name: 'Investigators'
            };
            let investigator_id;

            if (!queryInvestigator) {
                investigator_id = '';
            } else {
                investigator_id = queryInvestigator;
            }

            setQueryInvestigatorParam(investigator_id, 'replaceIn');

            dispatchToReducer(FiltersActions.SET_FILTER_STATE_AND_OPTIONS, Filters.INVESTIGATOR, {
                selections: { investigator_id },
                options: [allInvestigators, ...investigatorsFilters]
            });
        }
    }, [investigatorsFilters, usernameFilters, queryInvestigator]);

    if (
        errorLoadingTimeFilters ||
        errorLoadingOrganizationFilters ||
        errorLoadingUsernameFilters ||
        errorLoadingDemographicFilters ||
        errorLoadingAdHoc ||
        errorLoadingInvestigatorsFilters ||
        errorLoadingFeatureFilters
    ) {
        return (
            <ErrorState
                encompassPage={true}
                title="Failed"
                text="We had an issue communicating with one of our services, please try again."
            />
        );
    }

    if (isLoading) {
        return <LoadingState />;
    }
    return <FiltersContext.Provider value={[filterState, dispatch]}>{children}</FiltersContext.Provider>;
};

export { FiltersContext, FiltersProvider };
