import React, { forwardRef } from 'react';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    Tooltip,
    Label,
    Legend,
    CartesianGrid,
    ResponsiveContainer
} from 'recharts';
import { MAIN_CHART_COLORS } from '../../constants';

const AdHocBarChart = forwardRef(({ chartHeight, layout, vals }, ref) => {
    return (
        <ResponsiveContainer width="100%" height={chartHeight}>
            {layout === 'horizontal' ? (
                <BarChart
                    layout="vertical"
                    data={vals}
                    barCategoryGap={12}
                    margin={{ top: 10, bottom: 10, left: 30, right: 30 }}
                    ref={ref}>
                    <CartesianGrid stroke="rgba(0,0,0,0.1)" strokeDasharray="3 0" horizontal={false} />
                    <YAxis
                        allowDecimals={false}
                        width={150}
                        dataKey="label"
                        type="category"
                        interval={0}
                        fontSize={10}
                        axisLine={false}
                    />
                    <XAxis allowDecimals={false} height={40} type="number" orientation="top" axisLine={false}>
                        <Label dy={-20} fill="#485465" fontWeight="bold">
                            Count
                        </Label>
                    </XAxis>
                    <Tooltip />
                    <Bar barSize={10} name="Count" dataKey="count">
                        {vals.map((entry) => (
                            <Cell key={entry.label} fill={MAIN_CHART_COLORS[0]} />
                        ))}
                    </Bar>
                </BarChart>
            ) : (
                <BarChart
                    layout="horizontal"
                    data={vals}
                    barCategoryGap={1}
                    margin={{ top: 10, bottom: 10, left: 30, right: 30 }}
                    ref={ref}>
                    <CartesianGrid stroke="rgba(0,0,0,0.1)" vertical={false} strokeDasharray="3 0" />
                    <XAxis
                        allowDecimals={false}
                        width={300}
                        dataKey="label"
                        type="category"
                        interval={0}
                        fontSize={vals.length < 8 ? 10 : 7}
                    />
                    <YAxis allowDecimals={false} type="number">
                        <Label dx={-20} fill="#485465" angle={-90} fontWeight="bold">
                            Count
                        </Label>
                    </YAxis>
                    <Tooltip />
                    <Legend
                        iconType="circle"
                        payload={vals.map(({ label }, index) => ({
                            value: label,
                            color: MAIN_CHART_COLORS[index % MAIN_CHART_COLORS.length]
                        }))}
                        wrapperStyle={{
                            marginBottom: -5
                        }}
                    />
                    <Bar barSize={100} name="Count" dataKey="count">
                        {vals.map((entry, index) => (
                            <Cell key={entry.label} fill={MAIN_CHART_COLORS[index % MAIN_CHART_COLORS.length]} />
                        ))}
                    </Bar>
                </BarChart>
            )}
        </ResponsiveContainer>
    );
});

export default AdHocBarChart;
