import React, { useContext } from 'react';
import moment from 'moment';
import { AdHocContext } from '../../contexts/AdHocContext';
import { FiltersContext } from '../../contexts/FiltersContext';
import { TableComponent } from '../table/TableComponent';
import { Filters, ADHOC_MAX_CHARS_FILENAME_PREFIX, ADHOC_SAFE_CHAR_REPLACEMENT_FILENAME_PREFIX } from '../../constants';
import { camelize } from '../../utilities';

const AdHocChartsTable = ({ title, name, vals }) => {
    const [adHocState] = useContext(AdHocContext);
    const [filtersState] = useContext(FiltersContext);
    const timePeriod = filtersState?.filterSelections[Filters.TIME_PERIOD];
    const { filters, selected } = adHocState;
    const { filterSelections, filterOptions } = filtersState;

    const columns = [
        {
            title: name,
            dataIndex: 'label'
        },
        {
            title: 'Total Count',
            dataIndex: 'count'
        }
    ];

    const url = filterSelections[Filters.ADHOC];
    const label = filterOptions[Filters.ADHOC]?.find((option) => option.url === url)?.label;
    const fileNamePrefix = label
        ? label
              .trim()
              .replace(/[^a-z0-9]/gi, ADHOC_SAFE_CHAR_REPLACEMENT_FILENAME_PREFIX)
              .toLowerCase()
              .slice(0, ADHOC_MAX_CHARS_FILENAME_PREFIX)
        : '';

    const timePeriodFormat = 'M/D/YYYY';
    const startDate = moment(timePeriod.date_id).format(timePeriodFormat);
    const endDate = moment(timePeriod.date_id)
        .add('1', timePeriod.date_unit)
        .subtract(1, 'day')
        .format(timePeriodFormat);
    const titleFilters = filters.reduce((array, { name, param }) => {
        const hasSelections = Object.values(selected[param] || {}).reduce((a, v) => a || v, false);
        if (hasSelections) {
            return array.concat(name);
        } else {
            return array;
        }
    }, []);

    const csvTitle = `Counts for ${startDate}-${endDate} (Data Type: ${label}${
        titleFilters.length ? `, Filtered by: ${titleFilters.join(', ')}` : ''
    })`;
    const tabName = title ? camelize(title) : '';

    const params = {
        exportFileName: `${fileNamePrefix}-${tabName}`,
        exportAsCsv: true,
        csvTitle,
        showFilterDetails: false,
        searchOff: true,
        showTotal: false,
        bordered: false,
        paginationEnabled: false,
        backendPaginated: false,
        columns
    };

    return (
        <div id={`AdHocChartsTable-${title ? camelize(title) : ''}`} className="AdHocHideLastTableLine">
            <TableComponent data={vals} params={params} />
        </div>
    );
};

export default AdHocChartsTable;
