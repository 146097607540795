import { TreeSelect } from 'antd';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { useContext, useEffect, useState } from 'react';
import { Filters } from '../../../constants';
import { constructOrganizationDetailString } from '../../../utilities';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

const OrganizationAutoCompleteFilter = ({ title }) => {
    const [queryOrganization, setQueryOrganization] = useQueryParam('organization', NumberParam);
    const [queryPerson, setQueryPerson] = useQueryParam('person', NumberParam);
    const [expandedKeys, setExpandedKeys] = useState(null);
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [showAll, setShowAll] = useState(true);
    const [isHovering, setIsHovering] = useState(false);
    const { name } = filterSelections[Filters.ORGANIZATION];
    const organizationOptionsRaw = filterOptions[Filters.ORGANIZATION];

    useEffect(() => {
        if (!queryOrganization && organizationOptions.length) {
            setQueryOrganization(organizationOptions[0].key);
        }
    }, [queryOrganization, organizationOptions]);

    useEffect(() => {
        let node = organizationOptionsRaw.find((option) => option.parent_id == null)
        function makeTree(options, node = null, ancestors = []) {
            if (!node) {
                return {
                    title: 'Loading'
                }
            }
            const children = options.filter((option) => option.parent_id === node.organization_id).map((option) => makeTree(options, option, ancestors.concat([node.organization_id])))
            return {
                id: node.organization_id,
                title: constructOrganizationDetailString(node),
                value: node.organization_id,
                key: node.organization_id,
                pId: node.parent_id,
                children,
                ancestors
            }
        }
        setOrganizationOptions([makeTree(organizationOptionsRaw, node)]);
    }, [organizationOptionsRaw]);

    const onOrganizationChange = (id) => {
        setExpandedKeys(organizationOptions.find((option) => option.id == id)?.ancestors);
        setQueryOrganization(id);
        setQueryPerson(null);
    };

    const onTreeExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys);
    };

    const onSearch = () => {
        setExpandedKeys(null);
    };

    function find(id, node = null) {
        if (!node) {
            node = organizationOptions[0]
            if (!node) {
                return null
            }
        }
        if (node.id === id) {
            return node
        }
        return (node.children || []).map((child) => find(id, child)).filter((child) => !!child)[0]
    }

    function getAncestors(id) {
        return find(id)?.ancestors || []
    }

    const filterFunction = (inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
    return (
        <div>
            <h3 className="pb-1 text-black text-opacity-80">
                {!title ? 'Organization / Unit' : title}
            </h3>
            <TreeSelect
                treeDataSimpleMode
                value={queryOrganization}
                showSearch
                treeNodeFilterProp="title"
                showCheckedStrategy="SHOW_ALL"
                treeDefaultExpandedKeys={getAncestors(queryOrganization)}
                treeExpandedKeys={expandedKeys}
                onTreeExpand={onTreeExpand}
                style={{
                    width: '100%'
                }}
                onChange={onOrganizationChange}
                dropdownStyle={{
                    // maxHeight: 400,
                    overflow: 'auto'
                }}
                // dropdownMatchSelectWidth={false}
                placeholder="Please select"
                treeData={organizationOptions}
            />
        </div>
    );
};

const DropdownSvg = ({ isHovering }) =>
    !isHovering ? (
        <svg
            className="h-2.5 w-2.5 text-inactive-tab"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
    ) : (
        ''
    );

export default OrganizationAutoCompleteFilter;
