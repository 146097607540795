const CodeButton = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            className="h-4 w-4 text-accent-text hover:text-accent justify-center"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
            />
        </svg>
    );
};

export default CodeButton;
