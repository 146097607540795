import { useMemo, useContext, useState, createRef } from 'react';
import { DatePicker, Radio } from 'antd';
import * as moment from 'moment';
import { DateUnit, Filters, TIME_PERIOD_FILTER_FORMAT } from '../../../constants';
import { FiltersActions, FiltersContext, dateFilterToText } from '../../../contexts/FiltersContext';
import dayjs from 'dayjs';

const getStartOfDateUnit = (momentDate, dateUnit) => momentDate.startOf(dateUnit).format(TIME_PERIOD_FILTER_FORMAT);

const TimePeriodFilter = ({ title }) => {
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);
    const { date_id, date_unit } = filterSelections[Filters.TIME_PERIOD];
    const dateUnitOptions = filterOptions[Filters.TIME_PERIOD];
    const timePeriodState = useMemo(() => (!date_id ? '' : moment(date_id)), [date_id]);
    const [value, setValue] = useState(timePeriodState);
    const datePickerRef = createRef();
    const selectedDUOption = dateUnitOptions.find((duOpt) => {
        return duOpt.range_unit == date_unit;
    });
    const dispatchNewSelection = (date_id, date_unit) => {
        const newTimeSpan = dateUnitOptions.find((duOpt) => {
            return duOpt.range_unit == date_unit;
        });
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: {
                filterId: Filters.TIME_PERIOD,
                value: {
                    force_date_id: newTimeSpan.force_date_id,
                    date_id,
                    date_unit,
                    name: dateFilterToText(date_id, date_unit)
                }
            }
        });
    };

    const onTimeSpanChange = (e) => {
        const newDate = !date_id ? moment() : moment(date_id);
        setValue(newDate);
        dispatchNewSelection(getStartOfDateUnit(newDate, e.target.value), e.target.value);
    };

    const onDateChange = (momentDate) => {
        setValue(momentDate);
        momentDate && dispatchNewSelection(getStartOfDateUnit(momentDate, date_unit), date_unit);
    };

    const disabledDate = (current) => current && current > moment().endOf(DateUnit.DAY);

    const dayjsValue = dayjs(value.format('YYYY-MM-DD'));

    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">
                {!title ? 'Time Period' : title}
            </h3>
            <div className="flex flex-row items-center">
                <div className="mr-1">
                    <Radio.Group value={date_unit} onChange={onTimeSpanChange}>
                        {dateUnitOptions.map(({ title, range_unit }) => (
                            <Radio.Button key={range_unit} value={range_unit}>
                                {title}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </div>
                {!selectedDUOption.hide_date_selector && (
                    <DatePicker
                        className="w-24"
                        allowClear={false}
                        ref={datePickerRef}
                        value={dayjsValue}
                        disabledDate={disabledDate}
                        onChange={onDateChange}
                        picker={date_unit}
                    />
                )}
            </div>
        </div>
    );
};

export default TimePeriodFilter;
