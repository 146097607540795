import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { AdHocActions, AdHocContext } from '../../contexts/AdHocContext';
import { TableComponent } from '../table/TableComponent';
import { ComponentFiltersContext } from '../../contexts/ComponentFiltersContext';
import { Filters } from '../../constants';
import { FiltersContext } from '../../contexts/FiltersContext';
import { ADHOC_MAX_CHARS_FILENAME_PREFIX, ADHOC_SAFE_CHAR_REPLACEMENT_FILENAME_PREFIX } from '../../constants';

import AdHocFiltersAppliedComponent from './AdHocFiltersAppliedComponent';

const AdHocTable = () => {
    const [state, dispatch] = useContext(AdHocContext);
    const [componentFilterState] = useContext(ComponentFiltersContext);
    const { data, columns, selected, filters, total } = state;

    const [filterState] = useContext(FiltersContext);
    const url = filterState?.filterSelections[Filters.ADHOC];
    const timePeriod = filterState?.filterSelections[Filters.TIME_PERIOD];

    // TableComponent uses ComponentFiltersContext
    // We need to transfere pagination & sorting to AdHocContext
    const pg = componentFilterState[Filters.PAGINATION];
    const sr = componentFilterState[Filters.SORTING];
    useEffect(() => {
        if (pg) {
            const { page, page_size } = pg;
            dispatch({ type: AdHocActions.PAGINATION, payload: { page: page + 1, pageSize: page_size } });
        }
    }, [pg, dispatch]);
    useEffect(() => {
        if (sr) {
            const { order, field } = sr;
            dispatch({ type: AdHocActions.SORT, payload: { orderby: field, order } });
        }
    }, [sr, dispatch]);

    // Put total into total_count of first record
    const dataAndTotal = data.map((d, i) => (i === 0 ? { ...d, _total_count: total } : d));

    const label = filterState?.filterOptions[Filters.ADHOC]?.find((option) => option.url === url)?.label;
    const fileNamePrefix = label
        ? label
              .trim()
              .replace(/[^a-z0-9]/gi, ADHOC_SAFE_CHAR_REPLACEMENT_FILENAME_PREFIX)
              .toLowerCase()
              .slice(0, ADHOC_MAX_CHARS_FILENAME_PREFIX)
        : '';

    const timePeriodFormat = 'M/D/YYYY';
    const startDate = moment(timePeriod.date_id).format(timePeriodFormat);
    const endDate = moment(timePeriod.date_id)
        .add('1', timePeriod.date_unit)
        .subtract(1, 'day')
        .format(timePeriodFormat);
    const titleFilters = filters.reduce((array, { name, param }) => {
        const hasSelections = Object.values(selected[param] || {}).reduce((a, v) => a || v, false);
        if (hasSelections) {
            return array.concat(name);
        } else {
            return array;
        }
    }, []);

    const csvTitle = `Counts for ${startDate}-${endDate} (Data Type: ${label}${
        titleFilters.length ? `, Filtered by: ${titleFilters.join(', ')}` : ''
    })`;

    const params = {
        exportFileName: `${fileNamePrefix}-table`,
        exportAsCsv: true,
        csvTitle,
        showFilterDetails: true,
        paginationTotalField: '_total_count',
        defaultPageSize: 10,
        searchOff: true,
        showTotal: true,
        bordered: true,
        scrollXWidth: 800,
        backendPaginated: true,
        columns: columns
    };

    const queryParams = {};
    Object.keys(selected).forEach((s) => {
        const vals = Object.keys(selected[s]).filter((k) => selected[s][k]);
        queryParams[s] = vals.join(',');
    });

    return (
        <>
            <AdHocFiltersAppliedComponent filters={state.filters} selected={state.selected} />
            <TableComponent endpoint={{ url, queryParams }} data={dataAndTotal} params={params} />
        </>
    );
};

export default AdHocTable;
