import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Filters, MAX_ADHOC_RESULT_COUNT } from '../constants';
import { useStatsApi } from '../hooks/useApi';
import { FiltersContext } from './FiltersContext';

const AdHocActions = {
    UPDATE_DATA: 'UPDATE_DATA',
    SELECT: 'SELECT',
    RESET_SELECT: 'RESET_SELECT',
    RESET_SELECT_BY_NAME: 'RESET_SELECT_BY_NAME',
    PAGINATION: 'PAGINATION',
    SET_LOADING: 'SET_LOADING',
    UPDATE_SORT: 'UPDATE_SORT',
    ERROR_CONDITION: 'ERROR_CONDITION'
};

const INIT_STATE = {
    loading: false,
    data: [],
    filters: [],
    unfiltered: [],
    columns: [],
    total: 0,
    query: {},
    pagination: { page: 1, pageSize: 10 },
    sort: { orderby: null, order: null },
    selected: {}, // { filterName -> { value: true|false } }
    errorMessage: null
};

const AdHocContext = createContext(null);

const reducer = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case AdHocActions.UPDATE_DATA:
            return { ...state, loading: false, trends: [], ...(payload || {}) };
        case AdHocActions.RESET_SELECT:
            return {
                ...state,
                loading: true,
                pagination: { page: 1, pageSize: state.pagination.pageSize },
                selected: {}
            };
        case AdHocActions.RESET_SELECT_BY_NAME: {
            const { name } = payload;
            return {
                ...state,
                loading: true,
                pagination: { page: 1, pageSize: state.pagination.pageSize },
                selected: { ...state.selected, [name]: {} }
            };
        }
        case AdHocActions.SELECT:
            const { name, value, checked } = payload;
            const currSelection = state?.selected[name] || {};
            return {
                ...state,
                loading: true,
                pagination: { page: 1, pageSize: state.pagination.pageSize },
                selected: { ...state.selected, [name]: { ...currSelection, [value]: checked } }
            };
        case AdHocActions.PAGINATION:
            const { page, pageSize } = payload;
            return { ...state, loading: true, pagination: { page, pageSize } };
        case AdHocActions.UPDATE_SORT:
            const { orderby, order } = payload;
            return { ...state, loading: false, sort: { orderby, order } };
        case AdHocActions.SET_LOADING:
            return { ...state, loading: true, errorMessage: null };
        case AdHocActions.ERROR_CONDITION:
            return { ...state, data: [], unfiltered: [], loading: false, errorMessage: payload };
        default:
            return state;
    }
};

const AdHocContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, INIT_STATE);
    const [filterState] = useContext(FiltersContext);

    const { selected, pagination, sort } = state;
    const { orderby, order } = sort;

    const url = filterState?.filterSelections[Filters.ADHOC] || '';
    const queryParams = {};
    Object.keys(selected).forEach((s) => {
        const vals = Object.keys(selected[s]).filter((k) => selected[s][k]);
        queryParams[s] = vals.join('|');
    });

    if (pagination) {
        const { page, pageSize } = pagination;
        queryParams.page = page - 1;
        queryParams.page_size = pageSize;
    }
    if (orderby) {
        queryParams.sort = orderby;
        queryParams.sort_order = order;
    }
    if (MAX_ADHOC_RESULT_COUNT) {
        queryParams.limit = MAX_ADHOC_RESULT_COUNT;
    }

    const [results] = useStatsApi({ url, queryParams }, filterState.filterSelections);

    useEffect(() => {
        if (!Array.isArray(url) && url) {
            dispatch({ type: AdHocActions.RESET_SELECT });
        }
    }, [url, dispatch]);

    useEffect(() => {
        if (results) {
            if (results.error) {
                dispatch({ type: AdHocActions.ERROR_CONDITION, payload: results.message });
            } else {
                dispatch({ type: AdHocActions.UPDATE_DATA, payload: results });
            }
        }
    }, [results, dispatch]);

    return <AdHocContext.Provider value={[state, dispatch]}>{children}</AdHocContext.Provider>;
};

export { AdHocContext, AdHocActions, AdHocContextProvider };
