import { TokenProvider } from './contexts/TokenContext';
import MainLayout from './components/layout/main-layout/MainLayoutContainer';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import './assets/styles/antd.css';
import NewEditableMainLayoutContainer from './editor/NewEditableMainLayoutContainer';
import EditableMainLayoutContainer from './editor/EditableMainLayoutContainer';

library.add(fas);

const EDIT_MODE_ENABLED = process.env.REACT_APP_CONFIGURATION_EDITOR_MODE;
const EDITOR = true ? <NewEditableMainLayoutContainer /> : <EditableMainLayoutContainer />;

function App() {
    return (
        <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
                <TokenProvider>{!EDIT_MODE_ENABLED ? <MainLayout /> : EDITOR}</TokenProvider>
            </QueryParamProvider>
        </Router>
    );
}

export default App;
