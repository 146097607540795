import React, {useState, createContext} from 'react'

export const PDFProgressContext = createContext()

export const PDFProgressProvider = ({ children }) => {
    const [count, setCount] = useState(0)
    const increment = () => {
        setCount(prevCount => prevCount + 1)
    }
    const reset = () => {
        setCount(0)
    }

    return <PDFProgressContext.Provider value={{ progressCount: count, progressIncrement: increment, progressReset: reset}}>
        {children}
    </PDFProgressContext.Provider>
}