import {
    VisualizationsParams,
    EditorComponentsWithAPI,
    EditorComponentsWithoutAPI
} from '../../utilities/visualization-lookup';
import { BASE_COMPONENT_EDITOR_PARAMS, BASE_API_COMPONENT_EDITOR_PARAMS } from '../constants';

const editorSchemaForComponentType = (componentType) => {
    const componentTypeEditorSchema = componentType + 'EditorSchema';
    let editorSchema = undefined;
    if (EditorComponentsWithAPI[componentTypeEditorSchema]) {
        editorSchema = [...BASE_API_COMPONENT_EDITOR_PARAMS, ...EditorComponentsWithAPI[componentTypeEditorSchema]];
    } else if (EditorComponentsWithoutAPI[componentTypeEditorSchema]) {
        editorSchema = [...BASE_COMPONENT_EDITOR_PARAMS, ...EditorComponentsWithoutAPI[componentTypeEditorSchema]];
    }
    return editorSchema;
};

const insertChildAtKey = (e, key, newChild) => {
    const objectCopy = { ...e };
    if (objectCopy.children) {
        const mappedChildren = objectCopy.children.map((e) => insertChildAtKey(e, key, newChild));
        const children =
            objectCopy.key === key
                ? [...mappedChildren, newChild].map((e) => insertChildAtKey(e, key, newChild))
                : mappedChildren;
        return {
            ...objectCopy,
            children
        };
    } else {
        if (objectCopy.key === key) {
            return { ...objectCopy, children: [newChild] };
        }
        return objectCopy;
    }
};

const modifyChildAtKey = (e, key, newChild) => {
    const objectCopy = { ...(e.key === key ? { ...e, ...newChild } : e) };
    if (objectCopy.children) {
        const children = objectCopy.children.map((e) => modifyChildAtKey(e, key, newChild));
        return {
            ...objectCopy,
            children
        };
    } else {
        return objectCopy;
    }
};

const jsonSortFunction = (a, b) => {
    if (a.order && b.order && a.order !== b.order) {
        return a.order - b.order;
    }
    if (a.order && !b.order) {
        return -1;
    }
    if (b.order && !a.order) {
        return 1;
    }
    if (a.componentId && b.componentId && a.componentId !== b.componentId) {
        return a.componentId - b.componentId;
    }
    if (a.componentId && !b.componentId) {
        return -1;
    }
    if (b.componentId && !a.componentId) {
        return 1;
    }
    if (a.component && b.component) {
        if (a.component < b.component) {
            return -1;
        }
        if (a.component > b.component) {
            return 1;
        }
    }
    if (a.pageId && b.pageId && a.pageId !== b.pageId) {
        return a.pageId - b.pageId;
    }
    if (a.pageId && !b.pageId) {
        return -1;
    }
    if (b.pageId && !a.pageId) {
        return 1;
    }
    if (a.path && b.path) {
        if (a.path < b.path) {
            return -1;
        }
        if (a.path > b.path) {
            return 1;
        }
    }
    return 0;
};

const stripIrrelevantProperties = (e) => {
    const objectCopy = { ...e };
    if (typeof objectCopy.title === 'function') {
        delete objectCopy.title;
    }

    if (objectCopy.componentId >= 10000 || objectCopy.componentId < 0) {
        delete objectCopy.componentId;
    }

    if (objectCopy.pageId >= 10000 || objectCopy.pageId < 0) {
        delete objectCopy.pageId;
    }

    if (objectCopy.parentPage >= 10000 || objectCopy.parentPage < 0) {
        delete objectCopy.parentPage;
    }

    if (objectCopy.parentComponent >= 10000 || objectCopy.parentComponent < 0) {
        delete objectCopy.parentComponent;
    }

    delete objectCopy.default_id;
    delete objectCopy.default_id_id;
    delete objectCopy.icon;
    delete objectCopy.key;
    delete objectCopy.Component;
    delete objectCopy.agencyId;
    delete objectCopy.deleted;
    delete objectCopy.includeComponentFiltersProvider;
    delete objectCopy.pdfDelegate;

    var stripped;
    if (objectCopy.children) {
        const children = objectCopy.children.map(stripIrrelevantProperties).filter((map) => !!map);
        children.sort(jsonSortFunction);
        stripped = {
            ...objectCopy,
            children
        };
    } else {
        stripped = { ...objectCopy };
    }
    return stripped;
};

function copy(text) {
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
}

const toType = function (obj) {
    return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase();
};

const getComponentParams = (componentName) => VisualizationsParams[`${componentName}Params`];

function validateComponentJson(componentObject) {
    const componentParams = getComponentParams(componentObject.component);
    if (!componentObject.component) {
        return null;
    }
    const errors = [];
    const params = componentParams?.params || {};
    const keys = Object.keys(params).sort((a, b) => params[b].required - params[a].required);
    if (keys.length) {
        Object.keys(componentObject.params || {})
            .filter((key) => !keys.filter((pKey) => pKey === key).length)
            .forEach((key) => {
                errors.push({ field: key, error: `Unrecognized param \`${key}\`` });
            });
    }
    const endpointRequiredAndDoesNotExist = !!componentParams?.endpointRequired && !componentObject.endpoint;
    if (endpointRequiredAndDoesNotExist) {
        errors.push({ field: 'endpoint', error: 'Field `endpoint` is required but does not exist on component' });
    }
    keys.forEach((key) => {
        const param = params[key];
        const { type, required } = param;

        const value = componentObject && componentObject.params ? componentObject.params[key] : undefined;

        if (typeof value === 'undefined' && required) {
            errors.push({ field: key, error: `Param \`${key}\` required, but does not exist` });
        }

        if (toType(type) !== 'array') {
            if (typeof value !== 'undefined' && toType(value) !== type) {
                errors.push({
                    field: key,
                    error: `Param \`${key}\` expected type \`${type}\`, received type \`${toType(value)}\``
                });
            }
        } else {
            if (typeof value !== 'undefined' && !type.includes(toType(value))) {
                errors.push({
                    field: key,
                    error: `Param \`${key}\` expected one of type \`${type.join(' | ')}\`, received type \`${toType(
                        value
                    )}\``
                });
            }
        }
    });
    return errors;
}

export {
    editorSchemaForComponentType,
    insertChildAtKey,
    modifyChildAtKey,
    stripIrrelevantProperties,
    copy,
    toType,
    jsonSortFunction,
    validateComponentJson,
    getComponentParams
};
