import { useContext, useMemo } from 'react';
import { FiltersContext } from '../../contexts/FiltersContext';
import { useStatsApi } from '../../hooks/useApi';
import PageSpecificFilters from '../../contexts/PageSpecificFilters';
import { ComponentFiltersContext } from '../../contexts/ComponentFiltersContext';

const removeNonApplicableFilters = (filterSelections = {}, pageSpecificFilters = []) => {
    const newFilters = {};
    const selections = Object.keys(filterSelections);
    selections.forEach((selection) =>
        pageSpecificFilters.includes(selection) ? (newFilters[selection] = filterSelections[selection]) : null
    );
    return newFilters;
};

const useModifiedFilters = (filters) => {
    const pageSpecificFilters = useContext(PageSpecificFilters);
    const [{ filterSelections }] = useContext(FiltersContext);
    const [componentFilterSelections, , componentLevelFilters] = useContext(ComponentFiltersContext);
    return useMemo(
        () =>
            removeNonApplicableFilters(
                { ...filterSelections, ...componentFilterSelections },
                componentLevelFilters.length ? filters : pageSpecificFilters
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [componentFilterSelections, filterSelections, pageSpecificFilters]
    );
};

const ComponentWrapperWithApiCall = ({
    endpoint,
    Component,
    params,
    defaultData,
    colSpan,
    rowSpan,
    filters,
    pdfDelegate,
    children
}) => {
    const modifiedFilters = useModifiedFilters(filters);
    const [data, loading, error] = useStatsApi(endpoint, modifiedFilters);
    if (error && pdfDelegate) {
        pdfDelegate?.setPdfChildErrorFlag(error);
    }
    return (
        <Component
            {...{
                data: !endpoint.url && defaultData ? defaultData : data,
                endpoint,
                params,
                currentFilters: modifiedFilters,
                defaultData,
                loading,
                error,
                colSpan,
                rowSpan,
                modifiedFilters,
                pdfDelegate: !error && !loading && pdfDelegate,
                children
            }}
        />
    );
};

export default ComponentWrapperWithApiCall;
