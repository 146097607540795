import { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, Legend, Cell } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { ALT_CHART_COLORS, MAIN_CHART_COLORS } from '../../constants';
import LoadingSpinner from '../states/LoadingSpinner';
import ErrorState from '../states/ErrorState';
import EmptyState from '../states/EmptyState';
import {
    determineElementVisibility,
    limitStringLength,
    returnGroupedData,
    returnSortedData,
    splitByChars,
    splitWords,
    transformDataIndexField,
    uppercaseWordsInSentence,
    withCaseScheme
} from '../../utilities';
import { FiltersContext } from '../../contexts/FiltersContext';
import { ComponentFiltersContext } from '../../contexts/ComponentFiltersContext';

const DEFAULT_MAX_HEIGHT = 325;
const AXIS_LINE = { stroke: '#EAF0F4' };
const LINE_STROKE = '#485465';
const TICK_STYLE = { fontSize: 10 };

const VerticalBarChartComponent = ({ params, data, loading, error, endpoint, pdfDelegate }) => {
    const [{ filterSelections }] = useContext(FiltersContext);
    const [componentFilterSelections] = useContext(ComponentFiltersContext);
    const {
        caption,
        allowDecimals,
        yAxisLabel,
        xLabelField,
        stacks = [],
        barName,
        useSingleColor = false,
        isAnimationActive=true,
        dataIndex,
        height = DEFAULT_MAX_HEIGHT,
        sortBy,
        xLabelWithCaseScheme,
        xLabelWordsToCamelUpperCase,
        xLabelMaxLength,
        xLabelReplaceSlash,
        xLabelSplitBy,
        xLabelMaxWordLength,
        limit,
        groupBys
    } = params;

    // useCurrentPng usage (isLoading is optional)
    const [getPng, { ref, isLoading }] = useCurrentPng();
    const [png64, setPng64] = useState(null);
    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            // FileSaver.saveAs(png, 'myChart.png');
            const image = png.toString('base64');
            setPng64(image);
        } else {
            if (pdfDelegate) {
                console.error('no png');
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
    }, [getPng]);

    useEffect(() => {
        if (pdfDelegate && !loading && !!data) {
            if (png64) {
                pdfDelegate?.setPdfChildCompleteFlag([
                    {
                        image: png64,
                        fit: [400, 600]
                        // width: 300,
                        // height: 300
                    },
                    { text: '\n', style: 'paragraph' }
                ]);
                setPng64(null);
                return;
            }
            if (png64 === false) {
                console.error('png conversion failed');
                setPng64(null);
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
            if (data) {
                if (!sortedData.length) {
                    pdfDelegate?.setPdfChildCompleteFlag([{ text: 'No chart data available\n', style: 'paragraph' }]);
                } else {
                    handleDownload();
                }
            } else {
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
        if (!pdfDelegate) {
            setPng64(null);
            return;
        }
        if (!loading && !data) {
            pdfDelegate?.setPdfChildErrorFlag(true);
        }
    }, [pdfDelegate, loading, data, png64]);

    const xTickFormatter = (string = '') => {
        let formattedString = string;

        if (xLabelWithCaseScheme) {
            formattedString = withCaseScheme(formattedString, xLabelWithCaseScheme);
        }
        if (xLabelSplitBy) {
            formattedString = splitByChars(formattedString, xLabelSplitBy);
        }
        if (xLabelMaxWordLength) {
            formattedString = splitWords(formattedString, xLabelMaxWordLength);
        }
        if (xLabelWordsToCamelUpperCase) {
            formattedString = uppercaseWordsInSentence(formattedString);
        }
        if (xLabelMaxLength) {
            formattedString = limitStringLength(formattedString, xLabelMaxLength);
        }
        if (xLabelReplaceSlash) {
            formattedString = formattedString.replaceAll('/', ' / ');
        }

        //return String(formattedString).split(' ').join('\n');
        return formattedString
    };

    const { sortedData } = useMemo(
        () => {
            if (data) {
                const groupedData = returnGroupedData(groupBys, data, {
                    ...filterSelections,
                    ...componentFilterSelections
                });
                const sortedData = returnSortedData(sortBy, transformDataIndexField(groupedData, dataIndex)).slice(
                    0,
                    limit ? limit : data.length
                );
                return {
                    sortedData
                };
            }
            return {};
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, filterSelections, componentFilterSelections]
    );

    const barStacks = useMemo(
        () =>
            stacks.filter((item) =>
                determineElementVisibility(item.visible || [], {
                    ...filterSelections,
                    ...componentFilterSelections
                })
            ),
        [stacks, filterSelections, componentFilterSelections]
    );

    if (!endpoint) {
        return <ErrorState title="Error" text="No endpoint specified." />;
    }

    const responsiveContainerProps = {
        height,
        width: '99%'
    };

    const stateHeight = responsiveContainerProps.height || 144;

    if (loading) {
        return (
            <div className="flex items-center justify-center" style={{ height: stateHeight }}>
                <LoadingSpinner size="large" />
            </div>
        );
    }

    if (error) {
        return <ErrorState title="Error" text="We encountered an error while attempting to load the chart data" />;
    }

    if (!sortedData.length) {
        return <EmptyState height={stateHeight} />;
    }

    const cap = Array.isArray(caption) ? caption : [caption];

    return (
        <div>
            <div className="mx-2 pt-1 flex flex-column justify-between items-start">
                <h2 className="text-xxs">
                    {cap.map((c, ix) => (
                        <>
                            <span key={ix}>{c}</span>
                            <br />
                        </>
                    ))}
                </h2>
            </div>
            <ResponsiveContainer {...{ ...responsiveContainerProps }}>
                <BarChart
                    data={sortedData}
                    margin={{ top: 10, right: 0, left: 0, bottom: 50 }}
                    style={{ zIndex: 1 }}
                    ref={ref}>
                    <XAxis
                        angle={-45}
                        dy={20}
                        dx={-5}
                        strokeWidth={0}
                        axisLine={AXIS_LINE}
                        stroke={LINE_STROKE}
                        type="category"
                        tick={TICK_STYLE}
                        tickFormatter={xTickFormatter}
                        dataKey={xLabelField}
                        width={100}
                        interval={0}
                    />
                    <YAxis
                        label={
                            yAxisLabel && {
                                value: yAxisLabel,
                                angle: -90,
                                fontSize: 11,
                                color: LINE_STROKE,
                                dx: -20
                            }
                        }
                        dx={-8}
                        axisLine={false}
                        tickSize={0}
                        stroke={LINE_STROKE}
                        type="number"
                        tick={TICK_STYLE}
                        tickFormatter={(num) => new Intl.NumberFormat().format(num)}
                        allowDecimals={!!allowDecimals}
                    />
                    <CartesianGrid stroke="rgba(0,0,0,0.1)" horizontal={true} vertical={false} strokeDasharray="3 0" />
                    <Tooltip
                        cursor={false}
                        wrapperStyle={{
                            fontSize: 12,
                            color: 'rgba(0,0,0,0.65)'
                        }}
                        formatter={(label) => <span className="text-gray-800">{label}</span>}
                    />

                    {!barStacks.length && (
                        <Bar name={barName} barSize={20} dataKey={dataIndex}  isAnimationActive={isAnimationActive}>
                            {sortedData.map((entry, index) => {
                                const color = useSingleColor
                                    ? MAIN_CHART_COLORS[0]
                                    : MAIN_CHART_COLORS[index % MAIN_CHART_COLORS.length];
                                return <Cell key={index} fill={color} />;
                            })}
                        </Bar>
                    )}
                    {barStacks.length &&
                        barStacks.map(
                            (
                                {
                                    dataIndices = [],
                                    barNames = [],
                                    colors = 'main',
                                    colorSequence = true,
                                    colorOffset = 0
                                },
                                index
                            ) => {
                                return dataIndices.map((dataIndex, key) => {
                                    const color =
                                        colors === 'main'
                                            ? MAIN_CHART_COLORS[key + colorOffset]
                                            : ALT_CHART_COLORS[key + colorOffset];
                                    return (
                                        <Bar
                                            key={`${index}${key}`}
                                            name={barNames[key]}
                                            barSize={20}
                                            stackId={index}
                                            dataKey={dataIndex}
                                            fill={color}
                                            isAnimationActive={isAnimationActive}
                                        />
                                    );
                                });
                            }
                        )}
                    <Legend
                        verticalAlign="bottom"
                        y={-50}
                        dy={-50}
                        margin={{ top: 80, right: 0, left: 0, bottom: 0 }}
                        iconSize={8}
                        wrapperStyle={{ ...TICK_STYLE, marginBottom: -40 }}
                        iconType="circle"
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default VerticalBarChartComponent;
