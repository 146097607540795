import StyledLink from '../../links/StyledLink';
import React from 'react';
import { useLocation } from 'react-router';

const PageCrumbs = ({ navData }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const segments = location.pathname.split('/').slice(1);

    const pageFromPath = (path) => {
        const segments = path.split('/').slice(1);
        return segments.reduce((accumulator, segment, index) => {
            return accumulator && (accumulator.children || navData)[segment];
        }, {});
    };

    const constructCrumbs = (segments) => {
        const result =
            segments.length &&
            segments.reduce((accumulator, segment, index) => {
                const prevCrumb = (accumulator.length && accumulator[accumulator.length - 1]) || {
                    path: '',
                    title: ''
                };
                const node = accumulator.length ? accumulator[accumulator.length - 1].node : navData;
                if (node[segment]) {
                    const nextNode = {
                        title: node[segment].shortTitle || node[segment].title,
                        path: prevCrumb.path + '/' + segment,
                        node: node[segment].children
                    };
                    const parentPath = node[segment].parentPath;
                    const parentPage = parentPath && pageFromPath(parentPath);
                    return (parentPage ? constructCrumbs(parentPath.split('/').slice(1)) : accumulator).concat([
                        nextNode
                    ]);
                }
                return accumulator;
            }, []);
        return result;
    };

    const crumbs = constructCrumbs(segments);

    return (
        <div className=" flex flex-row items-center border-hr space-x-2 mx-4" style={{ minHeight: 36 }}>
            {crumbs.map((crumb, index) => {
                return (
                    <div className="text-center flex flex-row justify-center items-center space-x-2" key={crumb.path}>
                        <StyledLink to={`${crumb.path}?${queryParams.toString()}`} className="crumb-item no-underline">
                            {crumb.title}
                        </StyledLink>
                        {index + 1 < crumbs.length && <div className="crumb-item">/</div>}
                    </div>
                );
            })}
        </div>
    );
};

export default PageCrumbs;
