import React, { useState, useEffect, forwardRef } from 'react';
import moment from 'moment';

import { XAxis, YAxis, Tooltip, Label, Legend, Line, LineChart, CartesianGrid, ResponsiveContainer } from 'recharts';
import { MAIN_CHART_COLORS } from '../../constants';

const inpDateFormat = ['YYYY-MM-DD'];
const dateUnitFormatMap = {
    year: 'MMM YY',
    quarter: 'MMM YY',
    month: 'MMM DD'
};

const AdHocTrends = forwardRef(({ trends, trend_dates = [], name, timePeriod, chartHeight }, ref) => {
    const [series, setSeries] = useState([]);
    const [maxY, setMaxY] = useState(1);
    const [transformedSeries, setTransformedSeries] = useState([]);

    function d2t(d) {
        return moment(d, inpDateFormat).format(dateUnitFormatMap[timePeriod.date_unit]);
    }

    const ticks = trend_dates.map(d2t);

    useEffect(() => {
        if (trends?.length && name && timePeriod?.date_unit) {
            const maybeSeries = trends.find((trend) => trend.name === name)?.series;

            if (maybeSeries) {
                const series = ticks.reduce((a, label) => {
                    a[label] = { label };
                    return a;
                }, {});
                let y = 0;
                maybeSeries.forEach(({ label, data = [] }) => {
                    ticks.forEach((d) => {
                        series[d][label] = 0;
                    });
                    data.forEach(({ date, count }) => {
                        y = Math.max(y, count);
                        const dt = d2t(date);
                        series[dt] = series[dt] || { label: dt };
                        series[dt][label] = count;
                    });
                });
                setMaxY(y + 1);
                setTransformedSeries(Object.values(series));
                setSeries(maybeSeries);
            }
        }
    }, [trends, trend_dates, name, timePeriod]);

    return transformedSeries.length ? (
        <div>
            <div className="flex items-center justify-center my-4" style={{ minHeight: 50 }}>
                <h1 className="text-lg text-center">Trends Over Time</h1>
            </div>
            <ResponsiveContainer width="100%" height={chartHeight}>
                <LineChart data={transformedSeries} margin={{ top: 10, bottom: 10, left: 30, right: 30 }} ref={ref}>
                    <CartesianGrid stroke="rgba(0,0,0,0.1)" horizontal={true} vertical={false} strokeDasharray="3 0" />
                    <XAxis domain={ticks} ticks={ticks} dataKey="label" />
                    <YAxis domain={[0, maxY]} allowDecimals={false}>
                        <Label dx={-20} fill="#485465" angle={-90} fontWeight="bold">
                            Count
                        </Label>
                    </YAxis>
                    <Tooltip />
                    {transformedSeries.length < 10 ? (
                        <Legend
                            iconType="circle"
                            wrapperStyle={{
                                marginBottom: -8,
                                lineHeight: 2
                            }}
                        />
                    ) : null}
                    {series.map(({ label }, index) => (
                        <Line
                            key={label}
                            type="linear"
                            dataKey={label}
                            stroke={MAIN_CHART_COLORS[index % MAIN_CHART_COLORS.length]}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    ) : null;
});

export default AdHocTrends;
