import { useContext, useEffect } from 'react';
import { Filters } from '../../../constants';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { Select } from 'antd';
const { Option } = Select;

const InvestigatorFilter = ({ title }) => {
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);

    const selection = filterSelections[Filters.INVESTIGATOR];
    const options = filterOptions[Filters.INVESTIGATOR] || [];

    useEffect(() => {
        dispatchNewSelection();
    }, []);

    const dispatchNewSelection = (investigator_id) => {
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: {
                filterId: Filters.INVESTIGATOR,
                value: { investigator_id }
            }
        });
    };

    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">Investigator</h3>
            <Select
                id="investigatorfilter"
                placeholder="Select Investigator"
                style={{ width: 225 }}
                onSelect={dispatchNewSelection}
                dropdownMatchSelectWidth={500}
                value={selection?.investigator_id}>
                {options.map(({ investigator_last_name, investigator_first_name, investigator_id }) => {
                    return (
                        <Option key={investigator_id} value={investigator_id}>
                            {investigator_last_name} {investigator_first_name}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

export default InvestigatorFilter;
