import CodeButton from './CodeButton';
import NewComponentDropdown from './NewComponentDropdown';
import DeleteButton from './DeleteButton';
import { useContext } from 'react';
import EditorContext from '../../contexts/EditorContext';
import { ComponentsSupportingChildren } from '../../utilities/visualization-lookup';

const ComponentTitle = ({ onCodeSelected }) => {
    return (component) => {
        let title = null;
        if (component.titleComponent?.params?.title) {
            title = component.titleComponent.params.title;
        } else if (component.params?.title) {
            title = component.params.title;
        }

        title = !!title && title != '' ? ' (' + title + ')' : '';
        return (
            <div className="flex items-center space-x-2 my-1">
                <CodeButton
                    onClick={() => {
                        onCodeSelected(component);
                    }}
                />
                <span
                    className={`text-xs ${
                        component.key && typeof component.key === 'string' && component.key.split('-').length % 2
                            ? 'text-gray-600'
                            : 'text-link'
                    }`}>{`${component.component} ${`${title}`}`}</span>
            </div>
        );
    };
};

export default ComponentTitle;
