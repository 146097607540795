import { useState } from 'react';
import { Modal, Select } from 'antd';
import { FiltersProvider } from '../../contexts/FiltersContext';
import PageSpecificFilters from '../../contexts/PageSpecificFilters';
import PageLayout from '../../components/generic/PageLayout';

const AddNewComponentModal = ({
    pageOptions,
    pushEdit,
    getNextTempId,
    editComponent,
    componentOptions,
    setNewComponentModalOpen,
    componentLibraryOptions,
    newComponentModalOpen,
    components,
    modifiedPages
}) => {
    const [component, setComponent] = useState();
    const [componentType, setComponentType] = useState('fromList');
    const [pageKey, setPageKey] = useState(null);

    const setComponentFromOptions = (component) => {
        setComponent({ component, params: {} });
    };

    const setComponentFromLibrary = (key) => {
        setComponent();
        setTimeout(() => {
            const comp = { ...components[key] };
            delete comp.belongsToPage;
            setComponent(comp);
        }, 250);
    };

    const handleSubmit = () => {
        if (!component) {
            return;
        }
        pushEdit({
            op: 'newComponent',
            cmp: {
                ...component,
                componentId: getNextTempId(),
                parentPage: editComponent.pageId || editComponent.parentPage,
                parentComponent: editComponent.componentId || null
            }
        });
        setNewComponentModalOpen(false);
    };

    return (
        <Modal
            title="New Component"
            mask={true}
            bodyStyle={{ padding: 8, overflow: 'auto' }}
            destroyOnClose={true}
            onOk={handleSubmit}
            onCancel={() => setNewComponentModalOpen(false)}
            visible={newComponentModalOpen}>
            {componentType === 'fromList' && (
                <div className="rounded-xs-sm border border-inactive-tab mb-2 p-2 flex items-center">
                    <span className="font-bold flex-1">Select a component:</span>
                    <Select
                        style={{ width: '80%' }}
                        onChange={setComponentFromOptions}
                        placeholder="Select a component">
                        {componentOptions.map((page) => (
                            <Select.Option key={page.key} value={page.title}>
                                {page.title}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            )}
            {componentType === 'fromExisting' && (
                <div className="rounded-xs-sm border border-inactive-tab mb-2 p-2 flex items-center">
                    <span className="font-bold flex-1">Select a component:</span>
                    <Select
                        style={{ width: '80%' }}
                        onChange={setComponentFromLibrary}
                        placeholder="Select a component from the library">
                        {componentLibraryOptions.map((page) => (
                            <Select.Option key={page.key} value={page.key}>
                                {page.title}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            )}

            {!!component && componentType && (
                <div className="bg-gray-100 rounded-xs-sm border border-inactive-tab mb-2 flex items-center">
                    <FiltersProvider>
                        <PageSpecificFilters.Provider value={[]}>
                            <PageLayout config={{ children: [component] }} />
                        </PageSpecificFilters.Provider>
                    </FiltersProvider>
                </div>
            )}
        </Modal>
    );
};

export default AddNewComponentModal;
