import { MAX_ADHOC_RESULT_COUNT } from '../../constants';

const AdHocChartTopNotice = ({ show = false, className = '' }) => {
    return show ? (
        <div className={`bg-yellow-100 border-yellow-200 border-2 rounded-sm py-2 px-4 text-sm my-1 ${className}`}>
            <strong>Only the top {MAX_ADHOC_RESULT_COUNT} records will display in this chart</strong>. Please apply more
            filters to narrow the results down.
        </div>
    ) : null;
};

export default AdHocChartTopNotice;
