import { useContext, useMemo, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, Cell, Legend } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { ALT_CHART_COLORS, MAIN_CHART_COLORS } from '../../constants';
import LoadingSpinner from '../states/LoadingSpinner';
import ErrorState from '../states/ErrorState';
import EmptyState from '../states/EmptyState';
import {
    determineElementVisibility,
    limitStringLength,
    returnSortedData,
    splitByChars,
    splitWords,
    transformDataIndexField,
    uppercaseWordsInSentence,
    withCaseScheme
} from '../../utilities';
import { FiltersContext } from '../../contexts/FiltersContext';
import { ComponentFiltersContext } from '../../contexts/ComponentFiltersContext';
import HorizontalBarChartComponentEditorSchema from './HorizontalBarChartComponentEditorSchema.json';

const DEFAULT_MAX_HEIGHT = 500;
const DEFAULT_HEIGHT_PER_BAR = 35;
const AXIS_LINE = { stroke: '#EAF0F4' };
const LINE_STROKE = '#485465';
const TICK_STYLE = { fontSize: 10 };

const HorizontalBarChartComponent = ({ params, data, loading, error, endpoint, pdfDelegate }) => {
    const [{ filterSelections }] = useContext(FiltersContext);
    const [componentFilterSelections] = useContext(ComponentFiltersContext);
    const history = useHistory();
    const [stacks, setStacks] = useState(params.stacks || []);
    const {
        caption,
        xAxisLabel,
        allowDecimals,
        barName = '',
        includeLegend = false,
        includeBarLabel = true,
        isAnimationActive=true,
        barHeight = DEFAULT_HEIGHT_PER_BAR,
        stacksField,
        yLabelField,
        yLabelWidth,
        dataIndex,
        onClickURL,
        onClickQueryParams,
        useSingleColor = false,
        height,
        barsToActivateScroll = 10,
        sortBy,
        yLabelWithCaseScheme,
        yLabelWordsToCamelUpperCase,
        yLabelMaxLength,
        YLabelReplaceSlash,
        YLabelSplitBy,
        YLabelMaxWordLength,
        limit
    } = params;

    // useCurrentPng usage (isLoading is optional)
    const [getPng, { ref, isLoading }] = useCurrentPng();
    const [png64, setPng64] = useState(null);
    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            // FileSaver.saveAs(png, 'myChart.png');
            const image = png.toString('base64');
            setPng64(image);
        } else {
            if (pdfDelegate) {
                console.error('no png');
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
    }, [getPng]);

    useEffect(() => {
        if (pdfDelegate && !loading && !!data) {
            if (png64) {
                pdfDelegate?.setPdfChildCompleteFlag([
                    {
                        image: png64,
                        fit: [400, 600]
                        // width: 300,
                        // height: 300
                    },
                    { text: '\n', style: 'paragraph' }
                ]);
                setPng64(null);
                return;
            }
            if (png64 === false) {
                console.error('png conversion failed');
                setPng64(null);
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
            if (data) {
                if (!sortedData.length) {
                    pdfDelegate?.setPdfChildCompleteFlag([{ text: 'No chart data available\n', style: 'paragraph' }]);
                } else {
                    handleDownload();
                }
            } else {
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
        if (!pdfDelegate) {
            setPng64(null);
            return;
        }
        if (!loading && !data) {
            pdfDelegate?.setPdfChildErrorFlag(true);
        }
    }, [pdfDelegate, loading, data, png64]);

    const yTickFormatter = (string = '') => {
        let formattedString = string;
        if (yLabelWithCaseScheme) {
            formattedString = withCaseScheme(formattedString, yLabelWithCaseScheme);
        }
        if (YLabelSplitBy) {
            formattedString = splitByChars(formattedString, YLabelSplitBy);
        }
        if (YLabelMaxWordLength) {
            formattedString = splitWords(formattedString, YLabelMaxWordLength);
        }
        if (yLabelWordsToCamelUpperCase) {
            formattedString = uppercaseWordsInSentence(formattedString);
        }
        if (yLabelMaxLength) {
            formattedString = limitStringLength(formattedString, yLabelMaxLength);
        }
        if (YLabelReplaceSlash) {
            formattedString = formattedString.replaceAll('/', ' / ');
        }

        return formattedString;
    };

    const { sortedData, chartHeight } = useMemo(
        () => {
            if (data) {
                let newData = false;
                if (stacksField) {
                    // When stacksField is used, aggregate data for same yLabelField as stacks using stacksField value as barName
                    newData = {};
                    const allStacks = {};
                    data.forEach((d) => {
                        const yKey = d[yLabelField];
                        newData[yKey] = newData[yKey] || { ...d, [dataIndex]: 0 };
                        newData[yKey][d[stacksField]] = d[dataIndex];
                        newData[yKey][dataIndex] += d[dataIndex];
                        allStacks[d[stacksField]] = true;
                    });
                    const barNames = Object.keys(allStacks);
                    setStacks([{ dataIndices: barNames, barNames }]);
                }
                const dd = newData ? Object.values(newData) : data;
                const sortedData = returnSortedData(sortBy, transformDataIndexField(dd, dataIndex)).slice(
                    0,
                    limit ? limit : dd.length
                );
                const hasValidDataPoints = !!sortedData.filter((item) => typeof item[dataIndex] !== 'undefined').length;
                return {
                    chartHeight: (sortedData.length + 1) * barHeight,
                    sortedData: hasValidDataPoints ? sortedData : []
                };
            }
            return {};
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const barStacks = useMemo(
        () =>
            stacks.filter((item) =>
                determineElementVisibility(item.visible || [], {
                    ...filterSelections,
                    ...componentFilterSelections
                })
            ),
        [stacks, filterSelections, componentFilterSelections]
    );

    if (!endpoint) {
        return <ErrorState title="Error" text="No endpoint specified." />;
    }

    const activateScroll = barsToActivateScroll !== undefined && !!data && data.length >= barsToActivateScroll;

    const responsiveContainerProps = {
        ...(height && !activateScroll ? { height } : { height: chartHeight }),
        width: '99%'
    };

    const stateHeight = responsiveContainerProps.height || 144;
    if (loading) {
        return (
            <div className="flex items-center justify-center" style={{ height: stateHeight }}>
                <LoadingSpinner size="large" />
            </div>
        );
    }

    if (error) {
        return <ErrorState title="Error" text="We encountered an error while attempting to load the chart data" />;
    }

    if (!sortedData.length) {
        return <EmptyState height={stateHeight} />;
    }

    // const leftMargin = yLabelWidth
    //     ? yLabelWidth > STANDARD_LABEL_WIDTH_CUT_OFF
    //         ? STANDARD_LABEL_WIDTH_CUT_OFF - yLabelWidth
    //         : 0
    //     : 20;
    const leftMargin = 0;

    const cap = Array.isArray(caption) ? caption : [caption];

    // Set the size of the bars to take up the height of the space
    const barChartHeight = !!height ? height : Math.min(chartHeight, DEFAULT_MAX_HEIGHT);
    const barSize = (barChartHeight - 30) / barStacks.length;

    const CustomBarLabel = (props) => {
        //console.log(props);
        const { x, y, width, height, value, dataKey, payload, index } = props;
        const minBarSize = 20;
        const textWidth = Math.floor((Math.log10(parseInt(value, 0)))+1)*10
        //console.log([value, textWidth, width])
        const size = width;
        const isSmallBar = (size < textWidth)
        const labelX = (isSmallBar) ? x + width + minBarSize : x + width / 2
        const fill = (isSmallBar) ? "#000" : "#fff"

        if (includeBarLabel) {
            return (
                <text x={labelX} y={y + height / 2} fill={fill} textAnchor="middle" dominantBaseline="middle" style={{fontSize: 12}}>
                {parseInt(value)}
                </text>
            )
        }
        else {return null}
    }

    return (
        <div>
            <div className="mx-2 pt-1 flex flex-column justify-between items-start">
                <h2 className="text-xxs">
                    {cap.map((c, ix) => (
                        <>
                            <span key={ix}>{c}</span>
                            <br />
                        </>
                    ))}
                </h2>
            </div>
            {/*  scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-50 */}
            <div
                className={activateScroll && 'bg-white overflow-y-scroll overflow-x-hidden'}
                style={{ height: barChartHeight }}>
                <ResponsiveContainer {...{ ...responsiveContainerProps }}>
                    <BarChart
                        style={{ zIndex: 1 }}
                        data={sortedData}
                        layout="vertical"
                        horizontal={false}
                        ref={ref}
                        onClick={(e) => {
                            let pushThis = {};
                            if (e.activePayload && e.activePayload.length && e.activePayload[0]['payload']) {
                                const payload = e.activePayload[0]['payload'];
                                if (onClickQueryParams && payload[onClickQueryParams]) {
                                    const setParamsTo = payload[onClickQueryParams];
                                    const params = new URLSearchParams();
                                    Object.keys(setParamsTo).forEach((key) => {
                                        params.append(key, setParamsTo[key]);
                                    });
                                    pushThis['search'] = params.toString();
                                }
                                if (onClickQueryParams && payload[onClickURL]) {
                                    const pathName = payload[onClickURL];
                                    pushThis['pathname'] = pathName;
                                }
                                if (Object.keys(pushThis).length) {
                                    history.push(pushThis);
                                }
                            }
                        }}
                        margin={{ top: 10, right: 0, left: leftMargin, bottom: includeLegend ? 25 : 0 }}>
                        <XAxis
                            label={
                                xAxisLabel && {
                                    value: xAxisLabel,
                                    fontSize: 11,
                                    color: LINE_STROKE,
                                    dy: -12,
                                    dx: -35
                                }
                            }
                            tickCount={4}
                            orientation="top"
                            tickFormatter={(num) => new Intl.NumberFormat().format(num)}
                            strokeWidth={0}
                            axisLine={AXIS_LINE}
                            dy={-8}
                            tickSize={0}
                            stroke={LINE_STROKE}
                            type="number"
                            tick={TICK_STYLE}
                            allowDecimals={!!allowDecimals}
                        />
                        <YAxis
                            {...{ ...(yLabelWidth && { width: yLabelWidth }) }}
                            axisLine={AXIS_LINE}
                            dx={-8}
                            tickSize={0}
                            stroke={LINE_STROKE}
                            type="category"
                            tick={TICK_STYLE}
                            interval={0}
                            allowDataOverflow={false}
                            tickFormatter={yTickFormatter}
                            padding={{ top: 5, bottom: 5 }}
                            dataKey={yLabelField}
                        />
                        <CartesianGrid stroke="rgba(0,0,0,0.1)" horizontal={false} strokeDasharray="3 0" />
                        <Tooltip
                            wrapperStyle={{
                                fontSize: 12,
                                color: 'rgba(0,0,0,0.65)'
                            }}
                        />
                        {!barStacks.length && (
                            <Bar name={barName} barSize={barSize} dataKey={dataIndex} label={CustomBarLabel} label2={{ position: "inside", fill: "white" }}  isAnimationActive={isAnimationActive}>
                                {sortedData.map((entry, index) => {
                                    const color = useSingleColor
                                        ? MAIN_CHART_COLORS[0]
                                        : MAIN_CHART_COLORS[index % MAIN_CHART_COLORS.length];
                                    return <Cell key={index} fill={color} />;
                                })}
                            </Bar>
                        )}
                        {barStacks.length &&
                            barStacks.map(
                                ({ dataIndices = [], barNames = [], colors = 'main', colorSequence = true }, index) => {
                                    return dataIndices.map((dataIndex, key) => {
                                        const colorsToUse = colors === 'main' ? MAIN_CHART_COLORS : ALT_CHART_COLORS;
                                        const color = colorsToUse[key];
                                        return (
                                            <Bar
                                                key={`${index}${key}`}
                                                name={barNames[key]}
                                                barSize={barSize}
                                                stackId={index}
                                                dataKey={dataIndex}
                                                fill={color}
                                                isAnimationActive={isAnimationActive}>
                                                {dataIndices.length === 1 &&
                                                    !useSingleColor &&
                                                    sortedData.map((entry, index) => {
                                                        const color = useSingleColor
                                                            ? colorsToUse[0]
                                                            : colorsToUse[index % colorsToUse.length];
                                                        return <Cell key={index} fill={color} />;
                                                    })}
                                            </Bar>
                                        );
                                    });
                                }
                            )}
                        {!!includeLegend && (
                            <Legend
                                verticalAlign="bottom"
                                y={-50}
                                dy={-50}
                                margin={{ top: 80, right: 0, left: 0, bottom: 0 }}
                                iconSize={8}
                                wrapperStyle={{
                                    ...TICK_STYLE,
                                    marginBottom: -12,
                                    paddingLeft: 80,
                                    paddingRight: 80,
                                    lineHeight: 2
                                }}
                                iconType="circle"
                            />
                        )}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export { HorizontalBarChartComponent, HorizontalBarChartComponentEditorSchema };
