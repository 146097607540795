import { useContext, useEffect } from 'react';
import { Filters } from '../../../constants';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { Checkbox } from 'antd';
import { ComponentFiltersContext } from '../../../contexts/ComponentFiltersContext';

const PeerFilter = ({ title, useComponentState = false }) => {
    const [, dispatchFilters] = useContext(FiltersContext);
    const [, dispatchComponentFilters] = useContext(ComponentFiltersContext) || [];

    useEffect(
        () => {
            const setDefault = () =>
                dispatch({
                    type: FiltersActions.SET_FILTER_STATE,
                    payload: { filterId: Filters.PEER, value: { peerEnabled: false } }
                });
            setDefault();
            return () => setDefault();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const dispatch = ({ type, payload }) => {
        if (useComponentState) {
            dispatchComponentFilters((currentState) => ({
                ...currentState,
                [payload.filterId]: payload.value
            }));
        } else {
            dispatchFilters({ type, payload });
        }
    };

    const onCheckboxClick = (e, e2) => {
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: { filterId: Filters.PEER, value: { peerEnabled: e.target.checked } }
        });
    };

    return (
        <>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">
                {!title ? 'Additional Analysis' : title}
            </h3>
            <Checkbox className="text-xs h-8 flex items-center" onChange={onCheckboxClick}>
                <span className="opacity-75">Peer Comparison</span>
            </Checkbox>
        </>
    );
};

export default PeerFilter;
