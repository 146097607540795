import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useCurrentPng } from 'recharts-to-png';
import { Radio, Select, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { BarChartOutlined, PieChartOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';

import { Filters, MAX_ADHOC_RESULT_COUNT } from '../../constants';
import { camelize } from '../../utilities';
import createPdf from '../../utilities/create-pdf/index.js';
import { AdHocActions } from '../../contexts/AdHocContext';

import AdHocFiltersAppliedComponent from './AdHocFiltersAppliedComponent';
import AdHocChartsTable from './AdHocChartsTable';
import AdHocBarChart from './AdHocBarChart';
import AdHocPieChart from './AdHocPieChart';
import AdHocTrends from './AdHocTrends';
import AdHocChartTopNotice from './AdHocChartTopNotice';

const CHART_HEIGHT = 500;
const FONT_WEIGHT = 400;
const PAGE_X_AXIS_PADDING = 40;
const PAGE_X_AXIS_AGGREGATE_PADDING = PAGE_X_AXIS_PADDING * 2;
const TITLE_Y_AXIS_OFFSET = 60;
const CHART_Y_AXIS_OFFSET = 100;
const FILTER_TEXT_Y_AXIS_OFFSET = 60;

const AdHocCharts = ({
    dispatch,
    title,
    trends,
    trend_dates,
    filtered,
    selected,
    filters,
    fileNamePrefix,
    timePeriod,
    filterOptions,
    filterSelections
}) => {
    // if any chart does not render at least once it will look bad in the pdf
    const [displayAllCharts, setDisplayAllCharts] = useState(false);
    const [chartType, setChartType] = useState('hbar');

    const [getHBarPng, { ref: hBarRef, isLoading: hBarIsLoading }] = useCurrentPng();
    const [getVBarPng, { ref: vBarRef, isLoading: vBarIsLoading }] = useCurrentPng();
    const [getPiePng, { ref: pieRef, isLoading: pieIsLoading }] = useCurrentPng();
    const [getTrendsPng, { ref: trendsRef, isLoading: trendsIsLoading }] = useCurrentPng();

    const [sort, setSort] = useState('count');
    const [order, setOrder] = useState('desc');

    const isValsEqualMaxCount = filtered?.values?.length >= MAX_ADHOC_RESULT_COUNT;
    const tableVals = filtered?.values.map((v) => {
        return { label: v.label, count: parseFloat(v.count) };
    });
    const chartVals = isValsEqualMaxCount ? tableVals.slice(0, MAX_ADHOC_RESULT_COUNT) : tableVals;

    const options = [
        { label: 'Count', value: 'count' },
        { label: 'Type', value: 'label' }
    ];

    const handleSortChange = (value) => {
        setSort(value);
    };

    const handleToggleOrderChange = () => {
        setOrder((order) => (order === 'desc' ? 'asc' : 'desc'));
    };

    const handleChangeChart = (event) => {
        setChartType(event.target.value);
    };

    const handleDownloadChart = useCallback(async () => {
        const hBarPng = await getHBarPng();
        const vBarPng = await getVBarPng();
        const piePng = await getPiePng();
        const trendsPng = await getTrendsPng();

        const url = filterSelections[Filters.ADHOC];
        const label = filterOptions[Filters.ADHOC]?.find((option) => option.url === url)?.label;

        const timePeriodFormat = 'M/D/YYYY';
        const startDate = moment(timePeriod.date_id).format(timePeriodFormat);
        const endDate = moment(timePeriod.date_id)
            .add('1', timePeriod.date_unit)
            .subtract(1, 'day')
            .format(timePeriodFormat);
        const titleFilters = filters.reduce((array, { name, param }) => {
            const hasSelections = Object.values(selected[param] || {}).reduce((a, v) => a || v, false);
            if (hasSelections) {
                return array.concat(name);
            } else {
                return array;
            }
        }, []);
        const titleFiltersWithSelections = filters.reduce((array, { name, param, values }) => {
            const hasSelections = Object.values(selected[param] || {}).reduce((a, v) => a || v, false);
            if (hasSelections) {
                if (name === 'Organization') {
                    const selections = Object.entries(selected[param]).reduce((a, [k, v]) => (v ? [...a, k] : a), []);
                    return array.concat(
                        `${name} = ${selections
                            .map((selection) => values.find(({ value }) => value == selection)?.label)
                            .join(', ')}`
                    );
                } else {
                    const selections = Object.entries(selected[param]).reduce((a, [k, v]) => (v ? [...a, k] : a), []);
                    return array.concat(`${name} = ${selections.join(', ')}`);
                }
            } else {
                return array;
            }
        }, []);

        const titleText = `Counts for ${startDate}-${endDate} (Data Type: ${label}${
            titleFilters.length ? `, Filtered by: ${titleFilters.join(', ')}` : ''
        })`;

        const filterText = titleFiltersWithSelections.length
            ? `Filtered by: ${titleFiltersWithSelections.join('; ')}`
            : '';

        setDisplayAllCharts(true);

        if (hBarPng && vBarPng && piePng && (!trends?.length || trendsPng)) {
            setDisplayAllCharts(false);

            const pdf = createPdf();
            const content = [vBarPng, hBarPng, piePng, 'table', ...(trendsPng ? [trendsPng] : [])];

            content.forEach((pngOrTable, index) => {
                pdf.setFont('Lato-Regular', 'normal', FONT_WEIGHT);
                pdf.setFontSize(10);
                pdf.setTextColor('#272727');
                pdf.text(
                    pdf.splitTextToSize(titleText, pdf.internal.pageSize.getWidth() - PAGE_X_AXIS_AGGREGATE_PADDING),
                    PAGE_X_AXIS_PADDING,
                    TITLE_Y_AXIS_OFFSET
                );

                if (pngOrTable === 'table') {
                    pdf.autoTable({
                        html: `#AdHocChartsTable-${title ? camelize(title) : ''} .ant-table-content table`,
                        useCss: true,
                        startY: 100
                    });
                } else {
                    pdf.addImage(
                        pngOrTable,
                        'PNG',
                        PAGE_X_AXIS_PADDING,
                        CHART_Y_AXIS_OFFSET,
                        pdf.internal.pageSize.getWidth() - PAGE_X_AXIS_AGGREGATE_PADDING,
                        0 // get height automatically from image
                    );
                }

                if (filterText) {
                    pdf.setFont('Lato-Italic', 'italic', FONT_WEIGHT);
                    pdf.setFontSize(14);
                    pdf.setTextColor('#788495');
                    pdf.text(
                        pdf.splitTextToSize(
                            filterText,
                            pdf.internal.pageSize.getWidth() - PAGE_X_AXIS_AGGREGATE_PADDING
                        ),
                        PAGE_X_AXIS_PADDING,
                        pdf.internal.pageSize.getHeight() - FILTER_TEXT_Y_AXIS_OFFSET
                    );
                }

                if (index + 1 < content.length) {
                    pdf.addPage();
                }
            });

            const tabName = title ? camelize(title) : '';

            const startDate = moment(timePeriod.date_id).format('MM_DD_YYYY');
            const endDate = moment(timePeriod.date_id)
                .add('1', timePeriod.date_unit)
                .subtract(1, 'day')
                .format('MM_DD_YYYY');

            pdf.save(`${fileNamePrefix}-${tabName}-${startDate}-${endDate}.pdf`);
        } else {
            // sometimes one of the png strings is undefined
            // so we have to keep trying until we have them all
            handleDownloadChart();
        }
    }, [
        getVBarPng,
        getHBarPng,
        getPiePng,
        getTrendsPng,
        title,
        timePeriod,
        fileNamePrefix,
        filterSelections,
        filterOptions,
        filters,
        selected,
        trends
    ]);

    useEffect(() => {
        if (sort && order) {
            dispatch({ type: AdHocActions.UPDATE_SORT, payload: { orderby: sort, order } });
        }
    }, [sort, order]);

    return (
        <>
            <div className="flex items-center justify-end" style={{ minHeight: 50 }}>
                <AdHocFiltersAppliedComponent selected={selected} filters={filters} />
                <Button icon={<DownloadOutlined/>} onClick={handleDownloadChart} type="primary" shape="round" size="medium">{hBarIsLoading || vBarIsLoading || pieIsLoading || trendsIsLoading ? 'Downloading PDF' : 'Download PDF'}</Button>
                {/* <Button
                    className="bg-link border-0 rounded text-white leading-none px-4 ml-2"
                    onClick={handleDownloadChart}>
                    <span className="text-white">
                        {hBarIsLoading || vBarIsLoading || pieIsLoading || trendsIsLoading ? 'Downloading' : 'Download'}
                    </span>
                </Button> */}
            </div>
            <div className="relative">
                <div className="flex p-4">
                    <strong className="mr-1 leading-loose">View as:</strong>
                    <Radio.Group value={chartType} onChange={handleChangeChart}>
                        <Radio.Button className="text-sm" value="hbar">
                            <BarChartOutlined className="transform rotate-90 flip-x" />
                            Horizontal Bar Chart
                        </Radio.Button>
                        <Radio.Button className="text-sm" value="vbar">
                            <BarChartOutlined /> Vertical Bar Chart
                        </Radio.Button>
                        <Radio.Button className="text-sm" value="pie">
                            <PieChartOutlined /> Pie Chart
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <div className="absolute flex p-4 top-0 right-0">
                    <strong className="mr-1 leading-loose">Sort by:</strong>
                    <Select
                        className="border rounded"
                        options={options}
                        onChange={handleSortChange}
                        value={sort}
                        bordered
                    />
                    <div className="ml-2 text-xl text-link" onClick={handleToggleOrderChange}>
                        <SortAscendingOutlined hidden={order === 'asc'} className="cursor-pointer" />
                        <SortDescendingOutlined hidden={order === 'desc'} className="cursor-pointer" />
                    </div>
                </div>
                <div className="flex justify-center flex-col">
                    <div className={displayAllCharts ? 'AvoidHoverEvents' : chartType === 'hbar' ? '' : 'hidden'}>
                        <AdHocChartTopNotice show={isValsEqualMaxCount} />
                        <AdHocBarChart
                            title={title}
                            data={filtered}
                            chartHeight={CHART_HEIGHT}
                            layout="horizontal"
                            vals={chartVals}
                            ref={hBarRef}
                        />
                    </div>
                    <div className={displayAllCharts ? 'AvoidHoverEvents' : chartType === 'vbar' ? '' : 'hidden'}>
                        <AdHocChartTopNotice show={isValsEqualMaxCount} />
                        <AdHocBarChart
                            title={title}
                            data={filtered}
                            chartHeight={CHART_HEIGHT}
                            layout="vertical"
                            vals={chartVals}
                            ref={vBarRef}
                        />
                    </div>
                    <div className={displayAllCharts ? 'AvoidHoverEvents' : chartType === 'pie' ? '' : 'hidden'}>
                        <AdHocChartTopNotice show={isValsEqualMaxCount} />
                        <AdHocPieChart
                            title={title}
                            data={filtered}
                            chartHeight={CHART_HEIGHT}
                            vals={chartVals}
                            ref={pieRef}
                        />
                    </div>
                    <div>
                        <AdHocChartsTable
                            title={title}
                            name={filtered.name}
                            vals={tableVals}
                            chartHeight={CHART_HEIGHT}
                        />
                    </div>
                    <div>
                        <hr />
                        {trends?.length ? (
                            <AdHocTrends
                                name={title}
                                trends={trends}
                                trend_dates={trend_dates}
                                timePeriod={timePeriod}
                                chartHeight={CHART_HEIGHT}
                                ref={trendsRef}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdHocCharts;
