import TrendChartComponentEditorSchema from './TrendChartComponentEditorSchema.json'
import { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { LineChart, BarChart, Bar, Tooltip, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Line, Legend, Cell, Label, LabelList } from 'recharts';
import { ALT_CHART_COLORS, MAIN_CHART_COLORS } from '../../constants';
import LoadingSpinner from '../states/LoadingSpinner';
import ErrorState from '../states/ErrorState';
import EmptyState from '../states/EmptyState';
import { FiltersContext } from '../../contexts/FiltersContext';
import { ComponentFiltersContext } from '../../contexts/ComponentFiltersContext';
import { useCurrentPng } from 'recharts-to-png';

const DEFAULT_HEIGHT = 600;
const AXIS_LINE = { stroke: '#EAF0F4' };
const LINE_STROKE = '#485465';
const TICK_STYLE = { fontSize: 10 };

const TrendChartComponent = ({ params, data, loading, error, endpoint, pdfDelegate }) => {
    const [{ filterSelections }] = useContext(FiltersContext);
    const [componentFilterSelections] = useContext(ComponentFiltersContext);
    const {
        caption,
        height,
        dataKey,
        includeBarLabel = true,
        includeLineLabel = false,
        isAnimationActive=true,
        type = 'line' // line or stacked-bar
    } = params; 

    // useCurrentPng usage (isLoading is optional)
    const [getPng, { ref, isLoading }] = useCurrentPng();
    const [png64, setPng64] = useState(null);
    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            // FileSaver.saveAs(png, 'myChart.png');
            const image = png.toString('base64');
            setPng64(image);
        } else {
            if (pdfDelegate) {
                console.error('no png');
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
    }, [getPng]);

    useEffect(() => {
        if (pdfDelegate && !loading && !!data) {
            if (png64) {
                pdfDelegate?.setPdfChildCompleteFlag([
                    {
                        image: png64,
                        fit: [400, 600]
                        // width: 300,
                        // height: 300
                    },
                    { text: '\n', style: 'paragraph' }
                ]);
                setPng64(null);
                return;
            }
            if (png64 === false) {
                console.error('png conversion failed');
                setPng64(null);
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
            if (data) {
                if (!data.data.length) {
                    pdfDelegate?.setPdfChildCompleteFlag([{ text: 'No chart data available\n', style: 'paragraph' }]);
                } else {
                    handleDownload();
                }
            } else {
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
        if (!pdfDelegate) {
            setPng64(null);
            return;
        }
        if (!loading && !data) {
            pdfDelegate?.setPdfChildErrorFlag(true);
        }
    }, [pdfDelegate, loading, data, png64]);


    if (!endpoint) {
        return <ErrorState title="Error" text="No endpoint specified." />;
    }

    const responsiveContainerProps = {
        height,
        width: '99%'
    };

    const stateHeight = responsiveContainerProps.height || 144;

    if (loading) {
        return (
            <div className="flex items-center justify-center" style={{ height: stateHeight }}>
                <LoadingSpinner size="large" />
            </div>
        );
    }

    if (error) {
        return <ErrorState title="Error" text="We encountered an error while attempting to load the chart data" />;
    }

    if (!data?.data || !data.data.length) {
        return <EmptyState height={stateHeight} />;
    }

    const cap = Array.isArray(caption) ? caption : [caption];

    // return <div>{JSON.stringify(data)}</div>
    const transformedData = data.data.map((row) => {
        const calcTotal = data.labels.reduce((a, label, index) => {
            return a + row[label] || 0
        }, 0)
        const total = row.subTotal || calcTotal
        return {
            ...row,
            [`*total`]: total,
            ...Object.fromEntries(data.labels.map((label) => {
                return [`*pct_${label}`, `${parseInt(row[label] / total * 100)}%`]
            })),
        }
    });


    const tickFormatter = (dateIndex) => {
        return data.tick_labels[dateIndex] || ''
    }

    const ticks = Object.keys(data.tick_labels)
    const xValues = transformedData.map(item => item.date_index)
    const minX = Object.keys(data.series).length >= 100 ? Math.min(...xValues) : 0
    const maxX = Object.keys(data.series).length >= 100 ? Math.max(...xValues) : Object.keys(data.series).length - 1
    let tickCount = maxX - minX + 1

    const colorPalette = [
        "#FF6633", "#FFB399", "#FF33FF", "#00B3E6",
        "#E6B333", "#3366E6", "#999966", "#B34D4D",
        "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A",
        "#FF99E6", "#FF1A66", "#E6331A", "#33FFCC",
        "#66994D", "#B366CC", "#4D8000", "#B33300", "#CC80CC",
        "#66664D", "#991AFF", "#E666FF", "#4DB3FF", "#1AB399"
    ];


    const CustomBarLabel = (props) => {
        //console.log(props);
        const { x, y, width, height, value, dataKey, payload, index, offset } = props;
        const dataValue = transformedData[index];
        const numValue = (value !== null) ? parseInt(value, 10) : 0
        const minBarSize = 20;
        const textHeight = 10;
        const size = height;
        const isSmallBar = (size < textHeight * 2)
        const isTinyBar = (size < textHeight)
        
        if (!isTinyBar && numValue > 0) {
            const pctValue = parseInt(value / transformedData[index]['*total'] * 100) + '%'
            
            const labelX =  x + width / 2;
            const fill = "#fff"
            const labelY =  y + height / 2;

            if (includeBarLabel) {
                return (
                    <g>
                        <text x={labelX} y={labelY} fill={fill} textAnchor="middle" dominantBaseline="middle" style={{ fontSize: 12 }}>
                            {numValue}
                        </text>
                        { ( isSmallBar ) ? (<></>) : (
                        <text x={labelX} y={labelY + 15} fill={fill} textAnchor="middle" dominantBaseline="middle" style={{fontSize: 10}}>
                        ({pctValue})
                        </text>
                        )}
                    </g>
                )
            }
            else { return null }
        } else { return null }
    }


    const CustomLineLabel = (props) => {
        //console.log(props);
        const { x, y, stroke, value } = props;
        const numValue = (value !== null) ? parseInt(value, 10) : 0
        
        if (numValue > 0) {
            // If the label is going to overlap the Y Axis, move it to the right
            const labelX = (x > 90) ? x : 90

            if (includeLineLabel) {
                return (
                    <g>
                        <text x={labelX} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                        {value}
                        </text>
                    </g>
                )
            }
            else { return null }
        } else { return null }
    }


    // const renderCustomizedLabel = (dataKey) => (props) => {<CustomBarLabel {...props} dataKey={dataKey} data={data}/>}

    // if (endpoint?.url.includes('force-subject-injured/range') && type==='stacked-bar') {
    //     console.log('endpoint is: ',endpoint.url, type)
    //     console.log('transformedData: ', transformedData)
    //     console.log('minX, maxX: ',minX, maxX)
    //     console.log('tickCount: ',tickCount)
    // }   

    return (
        <div>
            <div className="mx-2 pt-1 flex flex-column justify-between items-start">
                <h2 className="text-xxs">
                    {cap.map((c, ix) => (
                        <>
                            <span key={ix}>{c}</span>
                            <br />
                        </>
                    ))}
                </h2>
            </div>
            {(type === 'line') ?
                <div style={{ height: height || DEFAULT_HEIGHT }}>
                    <ResponsiveContainer {...{ ...responsiveContainerProps }}>
                        <LineChart data={transformedData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 50 }} ref={ref}>
                            <CartesianGrid stroke="rgba(0,0,0,0.1)" horizontal={true} vertical={false} strokeDasharray="3 0" />
                            {/* domain={[minX, maxX]}  */}
                            <XAxis dataKey="date_index" type="number" tickFormatter={tickFormatter} tickCount={tickCount} domain={[minX, maxX]} label={{ value: data.interval_label, position: "insideBottom", offset: 0, dy: 10 }} />
                            <YAxis />
                            <Tooltip labelFormatter={tickFormatter} />
                            <Legend wrapperStyle={{ bottom: 10 }} />
                            {
                                data.labels.map((label, index) => {
                                    return <Line type="linear" dataKey={label} stroke={MAIN_CHART_COLORS[index % colorPalette.length]} isAnimationActive={isAnimationActive}>
                                            <LabelList dataKey={label} content={CustomLineLabel} />
                                        </Line>
                                })
                            }
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                :
                <div style={{ height: height || DEFAULT_HEIGHT }}>
                    <ResponsiveContainer {...{ ...responsiveContainerProps }}>
                        <BarChart data={transformedData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 50 }} ref={ref}>
                            <CartesianGrid stroke="rgba(0,0,0,0.1)" horizontal={true} vertical={false} strokeDasharray="3 0" />
                            <XAxis dataKey="date_index" type="number" domain={[minX - 1, maxX + 1]} tickFormatter={tickFormatter} tickCount={tickCount + 2} label={{ value: data.interval_label, position: "insideBottom", offset: 0, dy: 10 }} />
                            <YAxis />
                            <Tooltip labelFormatter={tickFormatter} />
                            <Legend wrapperStyle={{ bottom: 10 }} />
                            {
                                data.labels.map((label, index) => {
                                    return <Bar stackId="a" barSize={transformedData.length === 1 && 400} dataKey={label}  isAnimationActive={isAnimationActive} /*label={CustomBarLabel} label2={{ position: "inside", fill: "white" }} */ fill={MAIN_CHART_COLORS[index % colorPalette.length]}>
                                        {/* <LabelList dataKey="label" position="top" style={{fill: 'black', fontSize: 12}}/> */}
                                        <LabelList dataKey={label} content={CustomBarLabel} />
                                    </Bar>
                                })
                            }
                        </BarChart>
                    </ResponsiveContainer>
                </div>}
        </div>
    );
};

export { TrendChartComponent, TrendChartComponentEditorSchema };
