import React, { useCallback, createRef, useEffect, useMemo, useState } from 'react';
import { FiltersProvider } from '../contexts/FiltersContext';
import { useStatsApi } from '../hooks/useApi';
import LoadingState from '../components/states/LoadingState';
import ErrorState from '../components/states/ErrorState';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { Button, Checkbox, Modal, Tree } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EditorContext from '../contexts/EditorContext';
import * as moment from 'moment';
import { ComponentsSupportingChildren, Visualizations } from '../utilities/visualization-lookup';
import ConfigurationRouteMapper from '../components/layout/main-layout/ConfigurationRouteMapper';
import AddNewComponentModal from './components/AddNewComponentModal';
import NewComponentDropdown from './components/NewComponentDropdown';
import { copy, insertChildAtKey, modifyChildAtKey, stripIrrelevantProperties } from './utilities';
import useDebounce from './hooks/useDebounce';
import { BLANK_PAGE, LocalStorageConstants } from './constants';
import DeleteButton from './components/DeleteButton';
import Draggable from 'react-draggable';
import ComponentValidator from './components/ComponentValidator';
import { Separator } from '../components/generic/Separator';

const { confirm } = Modal;

const EditableMainLayoutContainer = () => {
    const [pages, loading, error] = useStatsApi('dashboard/pages');
    const [modifiedPages, setModifiedPages] = useState([]);
    const [savedPageConfiguration, setSavedPageConfiguration] = useState();
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [editorJson, setEditorJson] = useState({});
    const [editorVisible, setEditorVisible] = useState(false);
    const [newComponentModalOpen, setNewComponentModalOpen] = useState(false);
    const [dragEnabled, setDragEnabled] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const [showCodeEditorIcon, setShowCodeEditorIcon] = useState(
        JSON.parse(localStorage.getItem(LocalStorageConstants.SHOW_CODE_EDITOR_ICON))
    );
    const [localStorageSync, setLocalStorageSync] = useState(
        JSON.parse(localStorage.getItem(LocalStorageConstants.SYNC_TO_LOCAL_STORAGE_OPTION))
    );
    const [components, setComponents] = useState([]);
    const [jsonEdits, setJsonEdits] = useState();
    const [editingKey, setEditingKey] = useState();
    const location = useLocation();
    const history = useHistory();

    const setLocalStorageOption = () => {
        const value = !localStorageSync;
        setLocalStorageSync(value);
        localStorage.setItem(LocalStorageConstants.SYNC_TO_LOCAL_STORAGE_OPTION, JSON.stringify(value));
    };

    const setShowCodeEditorIconAndStore = () => {
        const value = !showCodeEditorIcon;
        setShowCodeEditorIcon(value);
        localStorage.setItem(LocalStorageConstants.SHOW_CODE_EDITOR_ICON, JSON.stringify(value));
    };

    const deleteSavedPageConfiguration = () => {
        confirm({
            title: 'Are you sure you want to delete your saved page configuration from local storage? You will start with a fresh copy of the configuration.',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                localStorage.setItem(LocalStorageConstants.PAGE_CONFIGURATION_JSON, null);
                setSavedPageConfiguration(null);
                setModifiedPages(pages.map(mapPages));
            },
            onCancel() {}
        });
    };

    useEffect(() => {
        const expandedKeys = [];
        let selectedKey;
        modifiedPages.forEach((page) => {
            if (location.pathname === page.path) {
                expandedKeys.push(page.key);
                selectedKey = page.key;
                setSelectedKeys((currentSelectedKeys) => [...new Set([...currentSelectedKeys, selectedKey])]);
            }
        });
        setExpandedKeys((currentKeys) => {
            return [...new Set([...currentKeys, ...expandedKeys])];
        });
        return () => {
            if (selectedKey || selectedKey === 0) {
                setSelectedKeys((selectedKeys) => selectedKeys.filter((key) => key !== selectedKey));
            }
        };
    }, [location, modifiedPages]);

    const onExpand = (keys, data) => {
        if (data.expanded) {
            setExpandedKeys((expandedKeys) => {
                return [...new Set([...expandedKeys, ...keys])];
            });
        } else {
            setExpandedKeys(expandedKeys.filter((key) => key !== data.node.key));
        }
    };

    const onSelect = (keys) => {
        const firstKey = keys[0];
        if (typeof firstKey === 'number') {
            history.push(modifiedPages[firstKey].path);
        } else {
            setExpandedKeys((expandedKeys) => {
                return [...new Set([...expandedKeys, ...keys])];
            });
        }
    };

    const unExpandAll = () => {
        setExpandedKeys([]);
    };

    const modules = useMemo(() => modifiedPages && modifiedPages.filter((pages) => !!pages.module), [modifiedPages]);

    const onDrop = useCallback(
        (info) => {
            const dropKey = info.node.props.eventKey;
            const dragKey = info.dragNode.props.eventKey;
            const dropPos = info.node.props.pos.split('-');
            const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

            const loop = (data, key, callback) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].key === key) {
                        return callback(data[i], i, data);
                    }
                    if (data[i].children) {
                        loop(data[i].children, key, callback);
                    }
                }
            };
            const data = [...modifiedPages];

            let dragObj;
            loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item;
            });

            if (!info.dropToGap) {
                // Drop on the content
                loop(data, dropKey, (item) => {
                    item.children = item.children || [];
                    item.children.unshift(dragObj);
                });
            } else if (
                (info.node.props.children || []).length > 0 && // Has children
                info.node.props.expanded && // Is expanded
                dropPosition === 1 // On the bottom gap
            ) {
                loop(data, dropKey, (item) => {
                    item.children = item.children || [];
                    item.children.unshift(dragObj);
                    // in previous version, we use item.children.push(dragObj) to insert the
                    // item to the tail of the children
                });
            } else {
                let ar;
                let i;
                loop(data, dropKey, (item, index, arr) => {
                    ar = arr;
                    i = index;
                });
                if (dropPosition === -1) {
                    ar.splice(i, 0, dragObj);
                } else {
                    ar.splice(i + 1, 0, dragObj);
                }
            }

            setModifiedPages(data.map(mapPages));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [modifiedPages]
    );

    const onCodeSelected = (component) => {
        const componentCopy = stripIrrelevantProperties({ ...component });
        setEditingKey(component.key);
        setEditorJson(componentCopy);
        setEditorVisible(true);
    };

    const CodeButton = ({ component }) => {
        return (
            <svg
                onClick={() => onCodeSelected(component)}
                className="h-4 w-4 text-accent-text hover:text-accent justify-center"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                />
            </svg>
        );
    };

    const handleDelete = (key, modifiedPages) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                if (typeof key === 'number') {
                    const newPages = modifiedPages.filter((page) => page.key !== key);
                    setModifiedPages(newPages.map(mapPages));
                } else {
                    const keys = key.split('-');
                    const newPage = modifiedPages[keys.shift()];
                    let currentTree = null;
                    keys.forEach((key, index) => {
                        currentTree = (currentTree && currentTree.children[keys[index - 1]]) || newPage;
                        if (index + 1 === keys.length && currentTree) {
                            delete currentTree.children[key];
                        }
                    });
                    currentTree.children = currentTree.children.filter((child) => !!child);
                    const newPages = modifiedPages.map((page) => (page.key === newPage.key ? newPage : page));
                    setModifiedPages(newPages.map(mapPages));
                }
            },
            onCancel() {}
        });
    };

    const insertComponent = (item, component, modifiedPages) => {
        const newComponent = {
            component: item,
            params: {}
        };
        const pages = modifiedPages.map((e) => insertChildAtKey(e, component.key, newComponent)).map(mapPages);
        setModifiedPages(pages);
    };

    const componentTitle = (component) => (
        <div className="flex items-center space-x-2 my-1">
            <CodeButton component={component} />
            <span
                className={`text-xs ${
                    component.key && typeof component.key === 'string' && component.key.split('-').length % 2
                        ? 'text-gray-600'
                        : 'text-link'
                }`}>{`${component.component} ${
                component.params && component.params.title ? ` (${component.params.title})` : ''
            }`}</span>
            {!!ComponentsSupportingChildren[component.component] && (
                <NewComponentDropdown component={component} insertComponent={insertComponent} />
            )}
            <DeleteButton component={component} handleDelete={handleDelete} />
        </div>
    );

    const mapPages = (page, pageKey) => {
        const children =
            page.children &&
            page.children.map((layout, layoutKey) => {
                const children =
                    layout.children &&
                    layout.children.map((subcomponent, subcomponentKey) => {
                        return {
                            ...subcomponent,
                            key: `${pageKey}-${layoutKey}-${subcomponentKey}`,
                            title: componentTitle,
                            children:
                                subcomponent.children &&
                                subcomponent.children.map((child, key) => {
                                    return {
                                        ...child,
                                        key: `${pageKey}-${layoutKey}-${subcomponentKey}-${key}`,
                                        title: componentTitle
                                    };
                                })
                        };
                    });
                return {
                    ...layout,
                    key: `${pageKey}-${layoutKey}`,
                    title: componentTitle,
                    children
                };
            });
        return {
            ...page,
            children,
            order: page.order || pageKey,
            icon: (
                <div className="flex my-1">
                    <CodeButton component={{ ...page, key: pageKey }} />
                </div>
            ),
            key: pageKey
        };
    };

    useEffect(() => {
        if (!pages) {
            return [];
        }
        const savedPageConfiguration = JSON.parse(localStorage.getItem(LocalStorageConstants.PAGE_CONFIGURATION_JSON));
        if (pages && localStorageSync && savedPageConfiguration && savedPageConfiguration.pageConfigurations) {
            const config = savedPageConfiguration.pageConfigurations.map(mapPages);
            setModifiedPages(config);
            setSavedPageConfiguration(savedPageConfiguration);
        } else if (pages) {
            setModifiedPages(pages.map(mapPages));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages, localStorageSync]);

    useEffect(() => {
        if (modifiedPages) {
            if (localStorageSync && modifiedPages.length) {
                const jsonExportRaw = getJsonExport();
                localStorage.setItem(LocalStorageConstants.PAGE_CONFIGURATION_JSON, JSON.stringify(jsonExportRaw));
                setSavedPageConfiguration(jsonExportRaw);
            }
            const components = [];
            modifiedPages.forEach((page) => {
                page.children
                    .map((child) => ({ belongsToPage: page.title, ...child }))
                    .forEach((comp) => components.push(comp));
            });
            setComponents(components);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modifiedPages, localStorageSync]);

    const createNewPage = () => {
        setModifiedPages((modifiedPages) => [...modifiedPages, BLANK_PAGE].map(mapPages));
    };

    const createNewComponent = () => {
        setNewComponentModalOpen(true);
    };

    const getJsonExport = () => {
        const pages = modifiedPages.map(stripIrrelevantProperties).filter((page) => !!page);
        return {
            generatedBy: 'PAGE_EDITOR',
            pageConfigurations: pages
        };
    };

    const copyJson = () => {
        copy(JSON.stringify(getJsonExport(), null, 2));
    };

    const debouncedJsonEdits = useDebounce(jsonEdits, 500);

    useEffect(() => {
        if (debouncedJsonEdits) {
            if (typeof editingKey === 'number') {
                const newPages = modifiedPages.map((page) => (page.key === editingKey ? debouncedJsonEdits : page));
                setModifiedPages(newPages.map(mapPages));
            } else {
                const pages = modifiedPages.map((page) => modifyChildAtKey(page, editingKey, debouncedJsonEdits));
                setModifiedPages(pages.map(mapPages));
            }
            setEditorJson(debouncedJsonEdits);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedJsonEdits]);

    const pageOptions = useMemo(
        () =>
            modifiedPages
                ? modifiedPages.map(({ key, title }) => ({
                      key,
                      title
                  }))
                : [],
        [modifiedPages]
    );
    const componentOptions = useMemo(
        () =>
            Object.keys(Visualizations)
                .sort()
                .map((key) => ({ key, title: key })),
        []
    );
    const componentLibraryOptions = useMemo(
        () =>
            components &&
            components.map((component, index) => ({
                key: index,
                title: `${component.belongsToPage} -> ${component.component} ${
                    component.params && component.params.title ? ` -> "${component.params.title}"` : ''
                }`
            })),
        [components]
    );

    if (loading) {
        return <LoadingState />;
    }

    if (error) {
        return (
            <ErrorState
                encompassPage={true}
                title="Failed"
                text="We had an issue communicating with one of our services, please try again."
            />
        );
    }

    const handleEditorChange = (json) => {
        setJsonEdits(json);
    };

    const draggleRef = createRef();

    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        setBounds({
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y)
        });
    };

    return (
        <EditorContext.Provider value={[modifiedPages, [showCodeEditorIcon, onCodeSelected]]}>
            <div className="min-w-full flex flex-row">
                <Modal
                    title="Edit JSON"
                    footer={false}
                    width="50%"
                    height="80%"
                    mask={true}
                    bodyStyle={{ padding: 0, minHeight: 600, overflow: 'auto' }}
                    destroyOnClose={true}
                    onCancel={() => setEditorVisible(false)}
                    visible={editorVisible}
                    modalRender={(modal) => (
                        <Draggable
                            disabled={!dragEnabled}
                            bounds={bounds}
                            onStart={(event, uiData) => onStart(event, uiData)}>
                            <div ref={draggleRef}>{modal}</div>
                        </Draggable>
                    )}>
                    <div className="flex">
                        <Editor
                            htmlElementProps={{ style: { minHeight: 600, flex: 1 } }}
                            mode="code"
                            value={editorJson}
                            onChange={handleEditorChange}
                        />
                        <ComponentValidator componentObject={editorJson} />
                    </div>
                </Modal>
                {!!newComponentModalOpen && (
                    <AddNewComponentModal
                        modifiedPages={modifiedPages}
                        setModifiedPages={setModifiedPages}
                        mapPages={mapPages}
                        componentLibraryOptions={componentLibraryOptions}
                        componentOptions={componentOptions}
                        newComponentModalOpen={newComponentModalOpen}
                        pageOptions={pageOptions}
                        setNewComponentModalOpen={setNewComponentModalOpen}
                        components={components}
                    />
                )}
                <div className="overflow-auto p-2 w-1/5" style={{ minWidth: 500 }}>
                    <div className="rounded-xs-sm border border-inactive-tab mb-2 p-2 ">
                        <div className="flex space-x-2 items-center">
                            <Button onClick={unExpandAll}>Unexpand All</Button>
                            <Button type="primary" onClick={createNewPage}>
                                New Page
                            </Button>
                            <Button type="primary" onClick={createNewComponent}>
                                New Component
                            </Button>
                            <Button type="primary" onClick={copyJson}>
                                Copy JSON
                            </Button>
                        </div>
                        <div className="mt-3 flex space-x-2 items-center">
                            <Checkbox checked={dragEnabled} onChange={() => setDragEnabled(!dragEnabled)}>
                                Enable Drag
                            </Checkbox>
                            <Checkbox
                                checked={showCodeEditorIcon}
                                onChange={() => setShowCodeEditorIconAndStore(!showCodeEditorIcon)}>
                                Enable Code Icon
                            </Checkbox>
                            <Checkbox checked={localStorageSync} onChange={setLocalStorageOption}>
                                <span>Sync to Local Storage</span>
                            </Checkbox>
                        </div>
                        {!!localStorageSync && (
                            <>
                                <div className="my-3">
                                    <Separator params={{ spaceAbove: false, spaceBelow: false }} />
                                </div>
                                <div className="flex space-x-2 items-center">
                                    <div className="flex-1 text-sm font-medium">
                                        <span>Last Modified:</span>
                                    </div>
                                    <code className="text-sm flex space-x-2 items-center">
                                        <div className="text-right">
                                            {(savedPageConfiguration && savedPageConfiguration.timestamp) || 'N/A'}
                                        </div>
                                        <svg
                                            onClick={deleteSavedPageConfiguration}
                                            className="text-red-400 hover:text-red-600 w-4 h-4 cursor-pointer"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </code>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="rounded-xs-sm border border-inactive-tab p-2">
                        <Tree
                            showIcon={true}
                            expandedKeys={expandedKeys}
                            selectedKeys={selectedKeys}
                            onSelect={onSelect}
                            onExpand={onExpand}
                            className="draggable-tree"
                            draggable={dragEnabled}
                            blockNode
                            onDrop={onDrop}
                            treeData={modifiedPages}
                        />
                    </div>
                </div>
                <div className="flex-1 flex flex-col">
                    <FiltersProvider>
                        <ConfigurationRouteMapper modules={modules} pages={modifiedPages} />
                    </FiltersProvider>
                </div>
            </div>
        </EditorContext.Provider>
    );
};

export default EditableMainLayoutContainer;
