import { COLOR_FUNCTIONS } from '../../constants/index';
import { replaceTemplateStrings } from '../../utilities/index';

const TextComponent = ({ params, data }) => {
    const {
        template,
        useNumberFormat,
        numberFormatOptions = {},
        text,
        size = 'xs',
        className = '',
        position = 'left',
        colorFunction,
        colorInput
    } = params;
    const combinedData = {
        ...params.data,
        ...data
    };
    const colorScheme = (
        COLOR_FUNCTIONS[colorFunction] ||
        (() => {
            return undefined;
        })
    )(colorInput);
    const output = template
        ? replaceTemplateStrings(template, combinedData, useNumberFormat, numberFormatOptions)
        : text;
    const style = {
        ...(colorScheme
            ? {
                  color: colorScheme['text']
              }
            : {})
    };
    const textPos = `text-${position}`;
    return (
        <div style={style} className={`text-${size} ${className} ${textPos}`}>
            {output}
        </div>
    );
};

export default TextComponent;
