import { useMemo } from 'react';
import { CartesianGrid, ReferenceLine, ResponsiveContainer, Scatter, Tooltip, YAxis } from 'recharts';
import LoadingSpinner from '../states/LoadingSpinner';
import ErrorState from '../states/ErrorState';
import EmptyState from '../states/EmptyState';
import { replaceTemplateStrings } from '../../utilities';
import ScatterChart from 'recharts/lib/chart/ScatterChart';

const DEFAULT_MAX_HEIGHT = 500;
const LINE_STROKE = '#485465';
const TICK_STYLE = { fontSize: 10 };

const ScatterPlotComponent = ({ params, data, loading, error, endpoint }) => {
    const {
        height = DEFAULT_MAX_HEIGHT,
        tickCount,
        dataIndex,
        uclLabel = 'UCL%',
        lclLabel = 'LCL%',
        meanLabel = 'M%',
        stdLabel = 'StdDev%',
        upperBoundaryField,
        lowerBoundaryField,
        showStats,
        dataIndexLabel = 'Value',
        dataIndexLabelFormat,
        tooltipTitle,
        tooltipSubtitle,
        unit = '%',
        unitType = 'number'
    } = params;

    const responsiveContainerProps = {
        height,
        width: '99%'
    };

    const calculateStandardDeviation = (avg, dt) => {
        let sum = 0;
        dt.forEach((item) => {
            const diff = item[dataIndex] - avg;
            const square = diff * diff;
            sum += square;
        });

        const avgSqrDiffs = sum / dt.length;
        return Math.sqrt(avgSqrDiffs);
    };

    const stats = useMemo(() => {
        try {
            if (data && data.length) {
                const firstElement = data[0];
                let lower = firstElement[dataIndex];
                let upper = firstElement[dataIndex];
                let sum = 0;
                data.forEach((item) => {
                    if (item[dataIndex] < lower) lower = item[dataIndex];
                    if (item[dataIndex] > upper) upper = item[dataIndex];
                    sum += item[dataIndex];
                });

                const avg = sum / data.length;
                const sd = calculateStandardDeviation(avg, data);
                return {
                    ucl: firstElement[upperBoundaryField].toFixed(2),
                    lcl: firstElement[lowerBoundaryField].toFixed(2),
                    m: avg.toFixed(2),
                    stdDev: sd.toFixed(2)
                };
            } else {
                return {};
            }
        } catch (e) {
            console.error('An error occurred attempting to calculate statistics');
            return {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (!endpoint) {
        return <ErrorState title="Error" text="No endpoint specified." />;
    }

    const tooltipRender = (options) => {
        const { active, payload } = options;
        if (active && payload && payload[0]) {
            const p = payload[0].payload;
            const title = replaceTemplateStrings(tooltipTitle || '', p);
            const subtitle = replaceTemplateStrings(tooltipSubtitle || '', p);
            const labelFormat = replaceTemplateStrings(dataIndexLabelFormat || '', p);
            return (
                <div className="bg-white p-2 border border-inactive-tab text-xs">
                    {title && (
                        <div>
                            <h3>{title}</h3>
                        </div>
                    )}
                    {subtitle && (
                        <div className="mb-2">
                            <i>{subtitle}</i>
                        </div>
                    )}
                    <div className="flex flex-row items-center">
                        <div className="mr-4 flex-1">{dataIndexLabel}:</div>
                        <div className="text-gray-600">{labelFormat}</div>
                    </div>
                </div>
            );
        }
        return null;
    };

    if (loading) {
        return (
            <div className="h-64 flex items-center justify-center">
                <LoadingSpinner size="large" />
            </div>
        );
    }

    if (error) {
        return <ErrorState title="Error" text="We encountered an error while attempting to load the chart data" />;
    }

    if (!data.length) {
        return <EmptyState />;
    }

    return (
        <>
            {showStats && (
                <div className="mx-8">
                    <div className="text-xs flex items-center justify-center" style={{ color: LINE_STROKE }}>
                        <div className="mx-3">
                            {uclLabel}: {stats.ucl}
                        </div>
                        <div className="mx-3">
                            {lclLabel}: {stats.lcl}
                        </div>
                        <div className="mx-3">
                            {meanLabel}: {stats.m}
                        </div>
                        <div className="mx-3">
                            {stdLabel}: {stats.stdDev}
                        </div>
                    </div>
                </div>
            )}
            <ResponsiveContainer {...{ ...responsiveContainerProps }}>
                <ScatterChart data={data} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
                    <Scatter data={data} fill="#3772ff" stroke="rgba(0,0,0,0)" />
                    <Tooltip content={tooltipRender} />
                    <CartesianGrid stroke="rgba(0,0,0,0.1)" vertical={false} strokeDasharray="3 0" />
                    <YAxis
                        style={{
                            fontSize: 12,
                            color: 'rgba(0,0,0,0.65)'
                        }}
                        axisLine={false}
                        padding={{ top: 20 }}
                        interval="preserveStartEnd"
                        dataKey={dataIndex}
                        unit={unit}
                        type={unitType}
                        stroke={LINE_STROKE}
                        tick={TICK_STYLE}
                        tickCount={tickCount}
                        dx={-8}
                        tickSize={0}
                    />
                    <ReferenceLine y={stats.ucl} stroke="#587095" />
                    <ReferenceLine y={stats.lcl} stroke="#587095" />
                    <ReferenceLine y={stats.m} stroke="#5AD8A6" />
                </ScatterChart>
            </ResponsiveContainer>
        </>
    );
};
export default ScatterPlotComponent;
