import { useEffect } from 'react';
import CardHeaderComponentParams from './CardHeaderComponentParams.json';
import { Separator } from '../generic/Separator';

const CardHeaderComponent = ({ params, pdfDelegate }) => {
    const { title, border, spaceBelow, spaceAbove } = params;

    const pdfHeader = [
        { text: title, style: 'heading_level_1' },
        { text: '\n', style: 'paragraph' }
    ];

    useEffect(() => {
        if (pdfDelegate) {
            pdfDelegate?.setPdfChildCompleteFlag(pdfHeader);
        }
    }, [pdfDelegate]);

    return (
        <div>
            <div className="mx-2 pt-1 pb-5 flex flex-row justify-between items-start">
                <div className="flex-1">
                    <h2 className="text-md">{title}</h2>
                </div>
            </div>
            {!!border && (
                <div className="w-full">
                    <Separator params={{ spaceAbove, spaceBelow }} />
                </div>
            )}
        </div>
    );
};

export { CardHeaderComponentParams, CardHeaderComponent };
