import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { AdHocContext, AdHocActions } from '../../contexts/AdHocContext';
import { FiltersContext } from '../../contexts/FiltersContext';
import { ArrowUpOutlined } from '@ant-design/icons';

import EmptyState from '../states/EmptyState';
import LoadingSpinner from '../states/LoadingSpinner';
import { Filters, ADHOC_MAX_CHARS_FILENAME_PREFIX, ADHOC_SAFE_CHAR_REPLACEMENT_FILENAME_PREFIX } from '../../constants';

import AdHocTable from './AdHocTable';
import AdHocCharts from './AdHocCharts';

const { TabPane } = Tabs;

const AdHocComponent = () => {
    const [currentTimePeriod, setCurrentTimePeriod] = useState(null);
    const [adHocState, dispatchAdHoc] = useContext(AdHocContext);
    const [filtersState] = useContext(FiltersContext);
    const { loading, data, filters, unfiltered, trends, selected, trend_dates, errorMessage } = adHocState;
    const { filterSelections, filterOptions } = filtersState;
    const filtersByParam = filters.reduce((a, v) => {
        a[v.param] = v;
        return a;
    }, {});

    const timePeriod = filterSelections[Filters.TIME_PERIOD];
    const url = filterSelections[Filters.ADHOC];
    const label = filterOptions[Filters.ADHOC]?.find((option) => option.url === url)?.label;
    const fileNamePrefix = label
        ? label
              .trim()
              .replace(/[^a-z0-9]/gi, ADHOC_SAFE_CHAR_REPLACEMENT_FILENAME_PREFIX)
              .toLowerCase()
              .slice(0, ADHOC_MAX_CHARS_FILENAME_PREFIX)
        : '';

    useEffect(() => {
        if (errorMessage) {
            alert(errorMessage);
        }
    }, errorMessage);

    if (currentTimePeriod === null) {
        setCurrentTimePeriod(timePeriod);
    } else if (
        timePeriod.date_id !== currentTimePeriod.date_id ||
        timePeriod.date_unit !== currentTimePeriod.date_unit
    ) {
        if (!Array.isArray(url) && url) {
            dispatchAdHoc({ type: AdHocActions.SET_LOADING });
        }

        setCurrentTimePeriod(timePeriod);
    }

    return (
        <>
            {loading ? (
                <div className="flex justify-center bg-white p-3 h-96 pt-44">
                    <LoadingSpinner size="large" />
                </div>
            ) : unfiltered && unfiltered.length > 0 ? (
                <div className="flex-grow bg-white p-3">
                    <Tabs defaultActiveKey="1">
                        {data.length > 0 ? (
                            <TabPane tab="Table" key="1" className="relative">
                                <AdHocTable />
                            </TabPane>
                        ) : null}
                        {unfiltered.map((f) => {
                            return (
                                <TabPane tab={f.name} key={f.param} className="relative">
                                    {f.values.length > 0 ? (
                                        <AdHocCharts
                                            dispatch={dispatchAdHoc}
                                            title={f.name}
                                            trends={trends}
                                            trend_dates={trend_dates}
                                            unfiltered={f}
                                            filtered={filtersByParam[f.param]}
                                            selected={selected}
                                            filters={filters}
                                            fileNamePrefix={fileNamePrefix}
                                            timePeriod={timePeriod}
                                            filterSelections={filterSelections}
                                            filterOptions={filterOptions}
                                        />
                                    ) : null}
                                </TabPane>
                            );
                        })}
                    </Tabs>
                </div>
            ) : (
                <div className="relative flex-grow bg-white p-3 h-96">
                    <div className="text-gray-400">
                        <ArrowUpOutlined className="ml-24" />
                        <p className="m-0">Select data type to get started</p>
                    </div>
                    <EmptyState height={300} />
                </div>
            )}
        </>
    );
};

export default AdHocComponent;
