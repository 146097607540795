import { useEffect, useContext, useState, useMemo } from 'react';
import cfg from '../config.json';
import { TokenContext } from '../contexts/TokenContext';
import { constructRelevantQueryParamsFromFilters } from '../utilities';
import { AUTHENTICATION_KEY } from '../constants';

const { statsApiUrl } = cfg;
// const mock = cfg.mock || !!process.env.REACT_APP_CONFIGURATION_EDITOR_MODE;
const mock = cfg.mock;

const useStatsPostApi = (url, filterObject = {}, body = null) => {
    const [results, loading, error] = useStatsApi(url, filterObject, body ? 'post' : 'get', body);
    if (!body || !url) {
        return [null, false, null];
    } else {
        return [results, loading, error];
    }
};

const useStatsPutApi = (url, filterObject = {}, body = null) => {
    const [results, loading, error] = useStatsApi(url, filterObject, body ? 'put' : 'get', body);
    if (!body || !url) {
        return [null, false, null];
    } else {
        return [results, loading, error];
    }
};

const useStatsApi = (url, filterObject = {}, method = 'GET', body = null, queryParams = {}) => {
    const urlToUse = getUrl(url);
    const additionalQueryParams = { ...(urlToUse && url.queryParams ? url.queryParams : {}), ...queryParams };
    const endpointUrl = urlToUse && urlToUse !== '' ? `${statsApiUrl}/${urlToUse}` : null;
    const [results, loading, error] = useApi(endpointUrl, true, filterObject, additionalQueryParams, method, body);
    if (!urlToUse) {
        return [null, false, null];
    }
    return [results, loading, error];
};

const useStatsApiAbusively = (url, queryParams = {}) => {
    const urlToUse = getUrl(url);
    const additionalQueryParams = { ...(urlToUse && url.queryParams ? url.queryParams : {}), ...queryParams };
    const endpointUrl = urlToUse && urlToUse !== '' ? `${statsApiUrl}/${urlToUse}` : null;
    const [results, loading, error] = useApi(endpointUrl, true, {}, additionalQueryParams);
    if (!urlToUse) {
        return [null, false, null];
    }
    return [results, loading, error];
};

function getUrl(url) {
    if (!url) {
        return null;
    }

    if (typeof url === 'string') {
        return url;
    }

    if (url.url && typeof url.url === 'string') {
        return url.url;
    }

    return null;
}

const useApi = (url, useToken = false, filterObject = {}, additionalQueryParams = {}, method = 'GET', body = null) => {
    const [results, setResults] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const tokenState = useContext(TokenContext);
    const queryParamsString = useMemo(
        () =>
            new URLSearchParams(Object.fromEntries(Object.entries({
                ...(mock && { mock: 'mock' }),
                ...additionalQueryParams,
                ...constructRelevantQueryParamsFromFilters(filterObject)
            }).filter(([key, value]) => { return !!value }))).toString(),
        [filterObject, additionalQueryParams]
    );

    useEffect(() => {
        if (url) {
            const controller = new AbortController();
            const { signal } = controller;
            setLoading(true);
            setError();
            let headers = useToken ? { Authorization: `Bearer ${tokenState.token}` } : {};
            if (body) {
                headers['Content-Type'] = 'application/json';
            }
            (async () => {
                try {
                    const results = await fetch(`${url}${queryParamsString ? `?${queryParamsString}` : ''}`, {
                        method,
                        body: body ? JSON.stringify(body) : null,
                        headers,
                        signal
                    });
                    if (results.ok) {
                        setResults(await results.json());
                        setLoading(false);
                    } else {
                        setResults();
                        if (results.status === 401 || results.status === 403) {
                            // expire the token
                            localStorage.removeItem(AUTHENTICATION_KEY);
                        }
                        throw new Error(results.status);
                    }
                } catch (e) {
                    setResults();
                    if (e.name !== 'AbortError') {
                        console.error(`Error attempting to communicate with API: ${e}`);
                        setError(e);
                        setLoading(false);
                    }
                }
            })();
            return () => {
                setResults();
                controller.abort();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, queryParamsString, body, method, filterObject.reloadCount]);

    return [results, loading, error];
};

export { useApi, useStatsApi, useStatsApiAbusively, useStatsPostApi, useStatsPutApi };
