import React, { createContext, useState } from 'react';
import { Filters, PAGINATION_CONFIGURATION } from '../constants';

const ComponentFiltersContext = createContext(null);

const getInitialState = (filters, params = {}) => {
    const newFiltersState = {};
    if (filters.includes(Filters.PAGINATION)) {
        const pageConfig = {
            ...PAGINATION_CONFIGURATION,
            defaultPageSize: params.defaultPageSize || PAGINATION_CONFIGURATION.defaultPageSize
        };
        newFiltersState[Filters.PAGINATION] = {
            page: 0,
            page_size: pageConfig.defaultPageSize,
            configuration: pageConfig
        };
    }
    return newFiltersState;
};

const ComponentFiltersProvider = ({ filters = [], params, children }) => {
    const [filterState, setComponentFilters] = useState(getInitialState(filters, params));
    return (
        <ComponentFiltersContext.Provider value={[filterState, setComponentFilters, filters]}>
            {children}
        </ComponentFiltersContext.Provider>
    );
};

export { ComponentFiltersContext, ComponentFiltersProvider };
