export const linksPermissions = {
    home: ['everybody'],
    people: ['configureDataImport', 'viewAllUserProfiles'],
    'officer profile': ['viewOwnOfficerProfile', 'viewAllUserProfiles'],
    'my profile': ['everybody'],
    analytics: [
        'viewForms',
        'reviewForm',
        'submitUOFReport',
        'submitVPReport',
        'submitDORReport',
        'submitIACReport',
        'submitIAIReport',
        'reviewUOFReport',
        'viewAllUOFReports',
        'reviewVPReport',
        'viewAllVPReports',
        'reviewDORReport',
        'viewAllDailyObservationReports',
        'viewOwnDailyObservationReports',
        'reviewIACReport',
        'reviewIAIReport',
        'viewAllInvestigations',
        'viewAllIntakes',
        'submitReport',
        'reviewReport',
        'viewReports'
    ],
    firstSign: ['accessFirstSign'],
    reports: [
        'viewForms',
        'reviewForm',
        'submitUOFReport',
        'submitVPReport',
        'submitDORReport',
        'submitIACReport',
        'submitIAIReport',
        'reviewUOFReport',
        'viewAllUOFReports',
        'reviewVPReport',
        'viewAllVPReports',
        'reviewDORReport',
        'viewAllDailyObservationReports',
        'viewOwnDailyObservationReports',
        'reviewIACReport',
        'reviewIAIReport',
        'viewAllInvestigations',
        'viewAllIntakes',
        'submitReport',
        'reviewReport',
        'viewReports'
    ],
    training: ['accessTraining'],
    'internal affairs': ['viewIACaseLoad', 'manageIACaseLoad'],
    peopleOu: ['listUsersByHK'],
    admin: [
        'manageAgencies',
        'createAnyUser',
        'createAgencyUser',
        'updateAnyUser',
        'updateAgencyUser',
        'manageWorkflows',
        'accessAnalyticsAdmin',
        'accessAnalyticsReports',
        'viewAllReports',
        'viewAllAuditLogs',
        'viewAgencyAuditLogs',
        'searchDocumentMetadata',
        'accessTrainingAdminPanel',
        'createRole'
    ],
    settings: ['everybody'],
    logout: ['everybody']
};

export const linkOrdering = [
    'home',
    'people',
    'peopleOu',
    'my profile',
    'analytics',
    'firstSign',
    'reports',
    'training',
    'internal affairs',
    'admin',
    'settings',
    'logout'
];

export const linkPermissionExclusions = {
    people: ['peopleOu'] // don't show people if peopleOu is present
};

export const statsLinksPermissions = {
    accessStatsDesigner: ['accessStatsDesigner'],
    accessStatsAdmin: ['accessStatsAdmin'],
    accessStatsReports: ['accessStatsReports']
};
