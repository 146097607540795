import { useMemo } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const ExternalLinkDropdown = ({ data, params }) => {
    const { title = 'Select', minWidth = 50 } = params;
    const menu = useMemo(
        () => (
            <Menu>
                {(data || []).map(({ label, url }, ix) => {
                    return (
                        <Menu.Item key={ix} disabled={!url}>
                            <a target="_blank" rel="noopener noreferrer" href={url}>
                                {label}
                            </a>
                        </Menu.Item>
                    );
                })}
            </Menu>
        ),
        [data]
    );
    if (!menu.props.children.length) {
        return null;
    }
    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a
                style={{ minWidth }}
                className="ant-dropdown-link w-100 flex border border-accent rounded-xs-sm text-accent"
                href="/#"
                onClick={(e) => e.preventDefault()}>
                <div className="py-2 px-3 text-sm flex-1">{title}</div>
                <div className="px-2 border-l border-accent">
                    <div className="flex items-center h-full text-accent">
                        <DownOutlined />
                    </div>
                </div>
            </a>
        </Dropdown>
    );
};

export default ExternalLinkDropdown;
