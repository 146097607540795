import { useContext, useEffect } from 'react';
import { Filters } from '../../../constants';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { useQueryParam, StringParam } from 'use-query-params';
import { Select } from 'antd';
const { Option } = Select;

const FeatureFilter = ({ title }) => {
    const [querySlug] = useQueryParam('feature_slug', StringParam);
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);

    const selection = filterSelections[Filters.FEATURE];
    const options = filterOptions[Filters.FEATURE] || [];

    useEffect(() => {
        dispatchNewSelection(querySlug || undefined);
    }, []);

    const dispatchNewSelection = (feature_slug) => {
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: {
                filterId: Filters.FEATURE,
                value: { feature_slug }
            }
        });
    };

    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">Risk Factors</h3>
            <Select
                id="featurefilter"
                placeholder="Select Risk Factor"
                style={{ width: 225 }}
                onSelect={dispatchNewSelection}
                dropdownMatchSelectWidth={500}
                value={selection?.feature_slug}>
                {options.map(({ feature, feature_slug }) => {
                    return (
                        <Option key={feature_slug} value={feature_slug}>
                            {feature}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

export default FeatureFilter;
