import IconButtonComponent from './IconButtonComponent';

const IconButtonRowComponent = ({ params }) => {
    const { colorFunction, colorInput, tags } = params;
    return (
        <div className="flex flex-row">
            {tags.map((text) => {
                return (
                    <IconButtonComponent
                        params={{
                            text,
                            colorFunction,
                            colorInput
                        }}
                    />
                );
            })}
        </div>
    );
};

export default IconButtonRowComponent;
