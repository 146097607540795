import React, { useState, createContext, useEffect, createRef } from 'react';
import jwtDecode from 'jwt-decode';
import { getTokenFromURL } from '../utilities';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingState from '../components/states/LoadingState';
import ErrorState from '../components/states/ErrorState';
import { AUTHENTICATION_KEY, STATS_MODULE_PERMISSION } from '../constants';
import { Modal } from 'antd';
import config from '../config.json';
import * as moment from 'moment';
import createActivityDetector from 'activity-detector';

export const TokenStatus = {
    RESOLVING: 'RESOLVING',
    UNAUTHORIZED: 'UNAUTHORIZED',
    AUTHORIZED: 'AUTHORIZED',
    INSUFFICIENT_PERMISSION: 'INSUFFICIENT_PERMISSION'
};

const BMS_LOGIN_URL = `${config.bmsUrl}/login`;
const BMS_EXPIRE_URL = `${config.bmsUrl}/reports`;

const defaultValue = { status: TokenStatus.RESOLVING };
const TokenContext = createContext(defaultValue);

const hasAuthority = (authorities = [], authority) => authorities.includes(authority);

const activityDetector = createActivityDetector({
    timeToIdle: config.tokenExpirationTimeout || 20 * 60 * 1000, // 20 minutes
    inactivityEvents: []
});

activityDetector.on('idle', () => {
    // localStorage.removeItem(AUTHENTICATION_KEY);
});

activityDetector.on('active', () => {});

const TokenProvider = ({ children }) => {
    const [tokenState, setTokenState] = useState(defaultValue);
    const [logoutNoticeActive, setLogoutNoticeActive] = useState(false);
    const [reloadCounter, setReloadCounter] = useState(0);
    const history = useHistory();
    const location = useLocation();
    const expirationRef = createRef();

    useEffect(() => {
        expirationRef.current = setInterval(() => {
            setReloadCounter(reloadCounter + 1);
        }, 5000);
        return () => {
            clearInterval(expirationRef.current);
        };
    });

    useEffect(() => {
        const token = getTokenFromURL(location.hash) || localStorage.getItem(AUTHENTICATION_KEY);
        if (token && token !== '') {
            let decodedToken = jwtDecode(token);
            // testing code:
            // decodedToken.exp = moment().unix() + 30 - reloadCounter * 5;
            if (!config.mock && !config.skipExpirationTest) {
                const tokenExpirationInSeconds = decodedToken.exp - moment().unix();
                if (tokenExpirationInSeconds < 0) {
                    clearInterval(expirationRef.current);
                    if (!logoutNoticeActive) {
                        setLogoutNoticeActive(true);
                        Modal.warning({
                            title: 'Session Expired',
                            content: `Your current viewing session has expired. You will now be redirected to the login page.`,
                            onOk: () => {
                                localStorage.removeItem(AUTHENTICATION_KEY);
                                window.location.href = BMS_EXPIRE_URL;
                                setLogoutNoticeActive(false);
                            }
                        });
                    }
                    setTokenState({ status: TokenStatus.UNAUTHORIZED });
                    return;
                }
            }
            if (!hasAuthority(decodedToken.authorities, STATS_MODULE_PERMISSION)) {
                setTokenState({ status: TokenStatus.INSUFFICIENT_PERMISSION });
                return;
            }
            setTokenState({
                token,
                decodedToken,
                status: TokenStatus.AUTHORIZED
            });
            if (location.hash) {
                history.replace(location.pathname + location.search);
            }
        } else {
            setTokenState({ status: TokenStatus.UNAUTHORIZED });
        }
        // eslint-disable-next-line
    }, [reloadCounter]);

    useEffect(() => {
        if (tokenState.token) {
            localStorage.setItem(AUTHENTICATION_KEY, tokenState.token);
        }
    }, [tokenState]);

    useEffect(() => {
        if (tokenState.status === TokenStatus.UNAUTHORIZED) {
            if (!logoutNoticeActive) {
                setLogoutNoticeActive(true);
                Modal.warning({
                    title: 'Session Expired',
                    content: `Your current viewing session has expired. You will now be redirected to the login page.`,
                    onOk: () => {
                        localStorage.removeItem(AUTHENTICATION_KEY);
                        window.location.href = BMS_EXPIRE_URL;
                    }
                });
            }
        }
    }, [tokenState.status, logoutNoticeActive]);

    if (tokenState.status === TokenStatus.RESOLVING) {
        return <LoadingState text="Loading..." />;
    }

    if (tokenState.status === TokenStatus.INSUFFICIENT_PERMISSION) {
        return (
            <ErrorState
                encompassPage={true}
                title="Unauthorized"
                text="You do not have any analytics pages configured for viewing. Please contact your administrator."
            />
        );
    }

    if (tokenState.status === TokenStatus.UNAUTHORIZED) {
        return <ErrorState encompassPage={true} title="Unauthorized" text="A valid session could be found." />;
    }

    return <TokenContext.Provider value={tokenState}>{children}</TokenContext.Provider>;
};

export { TokenContext, TokenProvider };
