import { Select, Input } from 'antd';
import { FiltersActions, FiltersContext } from '../../../contexts/FiltersContext';
import { useContext, useEffect, useState } from 'react';
import { Filters } from '../../../constants';
import { constructOrganizationDetailString } from '../../../utilities';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
const { Option } = Select;

const UserDataSectionFilter = ({ title }) => {
    const [{ filterSelections, filterOptions }, dispatch] = useContext(FiltersContext);
    const [sectionOptions, setSectionOptions] = useState([]);
    const { user_data_section_name, user_data_section_id } = filterSelections[Filters.USER_DATA_SECTION];
    const sectionOptionsRaw = filterOptions[Filters.USER_DATA_SECTION];
    const nullValue = useEffect(() => {
        return {};
    }, []); // we need this so that option key is recognized as a valid value, as in 'standard' attributes

    const dispatchNewSelection = (user_data_section_id, user_data_section_name) => {
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: {
                filterId: Filters.USER_DATA_SECTION,
                value: { user_data_section_id, user_data_section_name }
            }
        });
    };

    useEffect(() => {
        const newOptions = sectionOptionsRaw.map((option) => ({
            label: option.user_data_section_name,
            value: option.user_data_section_id || nullValue,
            key: option.user_data_section_id || nullValue
        }));
        setSectionOptions(newOptions);
    }, [sectionOptionsRaw]);

    const onSectionChange = (sectionName, sectionObject) => {
        dispatchNewSelection(sectionObject.key, sectionObject.label);
    };

    const filterFunction = (inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
    return (
        <div>
            <h3 className="text-xs font-extralight pb-1 text-black text-opacity-80">{!title ? 'Section' : title}</h3>
            <Select
                id="user_data_section"
                value={user_data_section_id}
                showSearch
                optionLabelProp="label"
                onSelect={onSectionChange}
                filterOption={filterFunction}
                style={{ width: 225 }}
                dropdownMatchSelectWidth={500}>
                {sectionOptions.map((option, index) => {
                    return (
                        <Option key={option.key} value={option.value} label={option.label}>
                            {option.label}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

export default UserDataSectionFilter;
