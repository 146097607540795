import StyledLink from './StyledLink';

const LinkList = ({ params }) => {
    const { title, links = [] } = params;
    return (
        <div>
            <h3 className="text-xs">{title}</h3>
            <div>
                {links.map(({ title, routeTo }, key) => (
                    <div key={key}>
                        <StyledLink to={routeTo}>{title}</StyledLink>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LinkList;
