import { Transition } from '@tailwindui/react';
import { useState, useEffect } from 'react';
import GridWrapper from '../generic/GridWrapper';
import { ChildrenWrapper } from '../generic/ComponentInterpreter';
import { replaceTemplateStrings } from '../../utilities';
import CollapsableCardComponentParams from './CollapsableCardComponentParams.json';
import CollapsableCardComponentEditorSchema from './CollapsableCardEditorSchema.json';
import { usePdfDelegate } from '../../hooks/usePdfDelegate';

function CollapsableCardComponent({ params, children, data, loading, error, endpoint, pdfDelegate }) {
    const {
        title,
        dynamicTitle,
        dynamicSubTitle,
        noDataTitle,
        className,
        passDataDirectly = false,
        childDefaultDataField = 'data',
        open = true,
        hideChildrenIfLoading = false,
        hideChildrenIfNoData = false,
        pdfPageBreak = false
    } = params;
    const [isOpen, setIsOpen] = useState(open);
    const childDefaultData = data && ((passDataDirectly && data) || data[childDefaultDataField]);
    children =
        (childDefaultData &&
            children.map((child) => {
                return {
                    ...child,
                    defaultData: childDefaultData
                };
            })) ||
        children;

    const noData = !data || (Array.isArray(data) && data.length == 0);
    const hideChildren = (hideChildrenIfLoading && loading) || (hideChildrenIfNoData && noData);

    const renderedTitle =
        noDataTitle && noData
            ? noDataTitle
            : !!data && !!dynamicTitle
            ? Array.isArray(data) && data.length > 0
                ? replaceTemplateStrings(dynamicTitle, data[0])
                : replaceTemplateStrings(dynamicTitle, data)
            : title;
    const renderedSubTitle = dynamicSubTitle && replaceTemplateStrings(dynamicSubTitle, data && data[0])

    const Button = ({ children }) => (
        <button onClick={() => setIsOpen(!isOpen)} className="w-full text-left focus:outline-none">
            {children}
        </button>
    );

    const pdfHeader = [
        { text: renderedTitle, style: 'heading_level_1' },
        { text: renderedSubTitle ? `${renderedSubTitle}\n` : '', style: 'heading_level_2'},
        { text: '\n', style: 'paragraph' }
    ];

    const subtreeDelegate = usePdfDelegate(
        pdfDelegate,
        (subtreeResult) => {
            if (subtreeResult && subtreeResult.length > 0 && pdfPageBreak) {
                const result =
                    pdfPageBreak == 'after'
                        ? pdfHeader.concat(subtreeResult).concat([{ text: ' ', pageBreak: 'after' }])
                        : [{ text: ' ', pageBreak: 'after' }].concat(pdfHeader.concat(subtreeResult));
                pdfDelegate?.setPdfChildCompleteFlag(result);
            } else {
                pdfDelegate?.setPdfChildCompleteFlag(
                    pdfHeader.concat(subtreeResult).concat([{ text: '\n', style: 'heading_level_1' }])
                );
            }
        },
        (subtreeError) => {
            pdfDelegate?.setPdfChildErrorFlag(subtreeError);
        }
    );

    useEffect(() => {
        if (subtreeDelegate && !loading && hideChildren) {
            subtreeDelegate.setPdfSubtreeComplete({});
        }
    }, [subtreeDelegate, loading, hideChildren]);

    return (
        <div className={`bg-white p-4 ${className ? className : ''}`}>
            {hideChildren && (
                <CollapsableCardHeaderComponent
                    params={{
                        title: renderedTitle
                    }}
                />
            )}
            {!hideChildren && (
                <Button>
                    <CollapsableCardHeaderComponent
                        params={{
                            title: renderedTitle,
                            subtitle: renderedSubTitle,
                            itemRight: <DropdownSvg isOpen={isOpen} />
                        }}
                    />
                </Button>
            )}
            {!hideChildren && (
                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 -translate-y-1 transform"
                    enterTo="opacity-100 translate-y-0 transform"
                    leave="transition ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 transform"
                    leaveTo="opacity-0 -translate-y-1 transform">
                    <GridWrapper isSubcomponent={true}>
                        <ChildrenWrapper childComponents={children} pdfDelegate={subtreeDelegate} />
                    </GridWrapper>
                </Transition>
            )}
        </div>
    );
}

const CollapsableCardHeaderComponent = ({ params }) => {
    const { title, subtitle, itemRight } = params;
    return (
        <div>
            <div className="mx-2 py-1 flex justify-between items-center">
                <div className="flex-1">
                    <h2>{title}</h2>
                    {subtitle && <h3>{subtitle}</h3>}
                </div>
                <div>{itemRight}</div>
            </div>
        </div>
    );
};
const DropdownSvg = ({ isOpen }) => (
    <div className="w-6 relative">
        <svg
            className={`absolute -top-3 h-6 w-6 text-inactive-tab ${!isOpen ? 'transform rotate-180' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
    </div>
);

export { CollapsableCardComponent, CollapsableCardComponentParams, CollapsableCardComponentEditorSchema };
